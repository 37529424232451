/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface SaveResponse {
  hasErrors?: boolean;
  errors?: {
    items?: {
      code?: string | null;
      message?: string;
      path?: string | null;
    }[];
  }[];
}

/** Brinks Error Object */
export interface BrinksError {
  /** Link to the error code documentation */
  type: string;
  /** Error Title */
  title: string;
  /** HTTP status code */
  status: number;
  /** Error detail */
  detail: string;
  /** The api endpoint that was called */
  instance: string;
  /** ODS specific error code */
  code: string;
}

/** Brinks Multi Status Response */
export interface BrinksMultiStatusResponse {
  /** Link to the error code documentation */
  type: string;
  /** Error Title */
  title: string;
  /** HTTP status code */
  status: number;
  /** Error detail */
  detail: string;
  /** The api endpoint that was called */
  instance: string;
  /** ODS specific error code */
  code: string;
  /** Array of objects or object Ids that were successfully created. */
  errors: BrinksMultiStatusError[];
}

/** Brinks Error Object */
export interface BrinksMultiStatusError {
  /** Link to the error code documentation */
  type: string;
  /** Error Title */
  title: string;
  /** HTTP status code */
  status: number;
  /** Brinks Error Detail Object */
  detail: BrinksMultiStatusErrorDetail;
  /** The api endpoint that was called */
  instance: string;
  /** ODS specific error code */
  code: string;
}

/** Brinks Error Detail Object */
export interface BrinksMultiStatusErrorDetail {
  /** Error message */
  error: string;
  /** The input object that caused the error */
  record: object;
}

/**
 * The request parameters or body supplied by the client are invalid. This means that a parameter is missing , or one of the passed parameters is invalid.
 * @example {"type":"https://brinksac.atlassian.net/wiki/spaces/ODS/pages/470974497/ODS+error+codes","title":"Bad Request","status":400,"detail":"Invalid request parameters or request body.","instance":"/apiUrl","code":"BRINKS-ODS-001"}
 */
export type BrinksOds001 = BrinksError;

/**
 * For GET, it means the API did not find any results. For other methods (PUT, POST, DELETE, PATCH) , it means that the request data contains an ID that is referring to an object that does not exist.
 * @example {"type":"https://brinksac.atlassian.net/wiki/spaces/ODS/pages/470974497/ODS+error+codes","title":"Not found","status":404,"detail":"Entity not found.","instance":"/apiUrl","code":"BRINKS-ODS-004"}
 */
export type BrinksOds004 = BrinksError;

/**
 * An error occurred on the server side. The reason for the error should be mentioned in the details.
 * @example {"type":"https://brinksac.atlassian.net/wiki/spaces/ODS/pages/470974497/ODS+error+codes","title":"Internal Server Error","status":500,"detail":"An error occurred.","instance":"/apiUrl","code":"BRINKS-ODS-007"}
 */
export type BrinksOds007 = BrinksError;

/** Holds list of POS transactions to return */
export interface GetPosTransactionsResponse {
  /** List of POS transactions */
  transactions: PosTransaction[];
  /** Total of Pages */
  totalOfPages?: number | null;
}

/** Holds list of POS transactions to add */
export interface SavePosTransactionsRequest {
  /** List of POS transactions */
  transactions: PosTransaction[];
}

/** Holds list of saved Stores */
export type SavePosTransactionsResponse = BrinksMultiStatusResponse & {
  /** List of saved Stores */
  success: PosTransaction[];
};

/** POS (Point of Sale) payment transactions. In context of a digital payment on a card terminal this is the act of paying, including the communication to the PSP */
export interface PosTransaction {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * Non cash device terminal id
   * @maxLength 36
   */
  terminalId?: string | null;
  /**
   * This is the unique transaction reference as set by the PSP (e.g. CM)
   * @maxLength 36
   */
  externalReference: string;
  /**
   * Merchant Id that pos transaction belongs to
   * @format uuid
   */
  merchantId?: string | null;
  /**
   * Store Location Id that transaction pos belongs to
   * @format uuid
   */
  storeLocationId?: string | null;
  /**
   * Non cash device Id that transaction pos belongs to
   * @format uuid
   */
  nonCashDeviceId?: string | null;
  /**
   * Original transaction Id that pos transaction has
   * @format uuid
   */
  originalTransactionId?: string | null;
  /**
   * Merchant's reference for the transaction, which relates to the order
   * @maxLength 36
   */
  orderId?: string | null;
  /**
   * e.g. CM.com
   * @minLength 1
   * @maxLength 40
   */
  paymentsServiceProvider: string;
  paymentMethod:
    | "VISA"
    | "MASTERCARD"
    | "AMERICAN_EXPRESS"
    | "VPAY"
    | "MAESTRO"
    | "IDEAL"
    | "BANCONTACT"
    | "GOOGLE_PAY"
    | "APPLE_PAY"
    | "PAYPAL"
    | "RETURN";
  /**
   * Actual date & time of the payment
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  transactionTime: string;
  /**
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  paymentDateTime?: string | null;
  /**
   * @min 0
   * @max 999999999.999
   */
  paymentNet?: number | null;
  /**
   * @min 0
   * @max 999999999.999
   */
  paymentGross?: number | null;
  /**
   * Transaction cost of the transaction
   * @min 0
   * @max 999999999.999
   */
  transactionCost?: number | null;
  /** ISO 4217 currency code (3 characters) */
  currency?: CurrencyCodes | null;
  /** Credit Debit Indicator of the pos transaction */
  creditDebitIndicator: "CREDIT" | "DEBIT";
  /** Brink’s internal status. PSP status should be translated to Brinks status. */
  status:
    | "INVALID"
    | "INCOMPLETE"
    | "CANCELLED"
    | "PENDING"
    | "REJECTED"
    | "PROCESSED"
    | "PAID"
    | "EXPIRED"
    | "OPEN"
    | "FAILED"
    | "PAIDOUT";
  paymentType: "PAYMENT" | "REFUND" | "CHARGEBACK";
  /**
   * For successful transactions, the unique authorization number is used to track the transaction with a credit card
   * @maxLength 15
   */
  traceNumber?: string | null;
  /**
   * The last 4 digits of the bank account used for the transaction
   * @min 0
   * @max 9999
   */
  bankAccountNumber?: number | null;
  /**
   * The SWIFT (BIC) code is 8 or 11 characters
   * @minLength 8
   * @maxLength 11
   */
  bankIdentificationCode?: string | null;
  /**
   * The last 4 digits of the credit card used for the transaction. This could be Debit, Credit or Gift card
   * @min 0
   * @max 9999
   */
  cardNumber?: number | null;
  /**
   * The date the transaction was/is scheduled to settle (payout). For multiple pay-outs using SEPA Inst Date/Time is relevant
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  estimatedDateTime?: string | null;
  /**
   * The date the transaction actually settled to your bank account (payout)
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  actualDateTime?: string | null;
  /**
   * The description of the payment
   * @maxLength 255
   */
  remittanceInformation?: string | null;
  /**
   * Description of the payout
   * @maxLength 255
   */
  payoutDescription?: string | null;
  /** Merchant object */
  merchant?: Merchant | null;
  /** ISO 3166 country code (2 characters) */
  country?: CountryCodes;
  /**
   * The date the transaction actually created at ODS
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  createdAt?: string | null;
}

/** Holds list of e-com transactions to return */
export interface GetEcomTransactionsResponse {
  /** List of e-com transactions */
  transactions: EcomTransaction[];
  /** Total of Pages */
  totalOfPages?: number | null;
}

/** Holds list of e-com transactions to save */
export interface SaveEcomTransactionsRequest {
  /** List of e-com transactions */
  transactions: EcomTransaction[];
}

/** Holds list of saved Stores */
export type SaveEcomTransactionsResponse = BrinksMultiStatusResponse & {
  /** List of saved Stores */
  success: EcomTransaction[];
};

/** ECOM payment transactions. In context of digital payments on a website this is also a single payment with an individual payment method */
export interface EcomTransaction {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * This is the unique transaction reference as set by the PSP (e.g. CM)
   * @maxLength 36
   */
  externalReferenceCm?: string;
  /**
   * This is the unique transaction reference as set by the PSP (e.g. CM)
   * @maxLength 36
   */
  externalReference: string;
  /**
   * ID of the merchant object
   * @format uuid
   */
  merchantId?: string;
  /**
   * Internal Brink's reference for the store location. Webhops as well as we decided to store a webshop as a store location
   * @format uuid
   */
  storeLocationId?: string;
  /**
   * To relate a Refund or Chargeback to the original transaction
   * @format uuid
   */
  originalTransactionId?: string | null;
  /**
   * Order reference received from PSP
   * @maxLength 36
   */
  orderId?: string | null;
  /**
   * Customer’s name received from PSP ecom transaction details
   * @minLength 1
   * @maxLength 255
   */
  customerName: string;
  /**
   * Customer’s Reference received from PSP ecom transaction details
   * @minLength 1
   * @maxLength 36
   */
  customerId: string;
  /**
   * Customer’s email received from PSP ecom transaction details
   * @format email
   * @maxLength 80
   */
  email?: string | null;
  /**
   * e.g. CM.com
   * @minLength 1
   * @maxLength 40
   */
  paymentsServiceProvider: string;
  paymentMethod:
    | "VISA"
    | "MASTERCARD"
    | "AMERICAN_EXPRESS"
    | "VPAY"
    | "MAESTRO"
    | "IDEAL"
    | "BANCONTACT"
    | "GOOGLE_PAY"
    | "APPLE_PAY"
    | "PAYPAL";
  /**
   * Actual date & time of the payment
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  transactionTime: string;
  /**
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  paymentDateTime: string;
  /**
   * Number with 3 digits after the decimal point
   * @min 0
   * @max 999999999.999
   */
  paymentNet?: number | null;
  /**
   * Number with 3 digits after the decimal point
   * @min 0
   * @max 999999999.999
   */
  paymentGross?: number | null;
  /**
   * Number with 3 digits after the decimal point
   * @min 0
   * @max 999999999.999
   */
  transactionCost?: number | null;
  /** ISO 4217 currency code (3 characters) */
  currency?: CurrencyCodes | null;
  /** Credit Debit Indicator of the ecom transaction */
  creditDebitIndicator: "CREDIT" | "DEBIT";
  /** Brink’s internal status. PSP status should be translated to Brinks status. */
  status:
    | "INVALID"
    | "INCOMPLETE"
    | "CANCELLED"
    | "PENDING"
    | "REJECTED"
    | "PROCESSED"
    | "PAID"
    | "EXPIRED"
    | "OPEN"
    | "FAILED"
    | "PAIDOUT";
  paymentType: "PAYMENT" | "REFUND" | "CHARGEBACK";
  /**
   * For successful transactions, the unique authorization number is used to track the transaction with a credit card
   * @maxLength 255
   */
  traceNumber?: string | null;
  /**
   * The last 4 digits of the bank account used for the transaction
   * @min 0
   * @max 9999
   */
  bankAccountNumber?: number | null;
  /**
   * The SWIFT (BIC) code is 8 or 11 characters
   * @minLength 8
   * @maxLength 11
   */
  bankIdentificationCode?: string | null;
  /**
   * The last 4 digits of the credit card used for the transaction. This could be Debit, Credit or Gift card
   * @min 0
   * @max 9999
   */
  cardNumber?: number | null;
  /**
   * The date the transaction was/is scheduled to settle (payout). For multiple pay-outs using SEPA Inst Date/Time is relevant
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  estimatedDateTime?: string | null;
  /**
   * The date the transaction actually settled to your bank account (payout)
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  actualDateTime?: string | null;
  /**
   * The description of the payment
   * @maxLength 255
   */
  remittanceInformation?: string | null;
  /**
   * Description of the payout
   * @maxLength 255
   */
  payoutDescription?: string | null;
  /** Merchant object */
  merchant?: Merchant | null;
  /** ISO 3166 country code (2 characters) */
  country?: CountryCodes;
  /**
   * The date the transaction actually created at ODS
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  createdAt?: string | null;
}

/** Holds list of transactions to return */
export interface GetTransactionsResponse {
  /** List of transactions */
  transactions: Transaction[];
}

/** Holds list of transactions to return */
export interface GetTransactionDetailsResponse {
  /** List of transaction details */
  transactions?: TransactionDetails[];
}

/** Generic transaction object, which could be a Deposit (CASH), a POS transaction (CARDS) or e-com transaction (CLICKS) */
export interface Transaction {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /** Transaction category/type */
  type: TransactionType;
  /** Store name where transaction happened */
  name: string;
  /** Store trade name where transaction happened */
  tradeName?: string;
  /**
   * The date when this transaction occurred
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  dateTime: string;
  /**
   * Indicates the net total amount of the transaction.
   * @min 0
   * @max 999999999.999
   */
  amount: number;
  /**
   * Indicates the gross total amount of the transaction.
   * @min 0
   * @max 999999999.999
   */
  grossAmount: number;
  /** ISO 4217 currency code (3 characters) */
  currency: CurrencyCodes;
  /** Store Address */
  address: string;
  /** Payment method */
  paymentMethod: PaymentMethod;
  /** Merchant where the transaction occured */
  merchant?: Merchant | null;
  /** ISO 3166 country code (2 characters) */
  country?: CountryCodes;
  /**
   * The date the transaction actually created at ODS
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  createdAt?: string | null;
}

/** Generic transaction object, which could be a Deposit (CASH), a POS transaction (CARDS) or e-com transaction (CLICKS) */
export interface TransactionDetails {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /** Transaction category/type */
  type: TransactionType;
  /** Store name where transaction happened */
  name: string;
  /** Store trade name where transaction happened */
  tradeName?: string;
  /**
   * The date when this transaction occurred
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  dateTime: string;
  /**
   * Indicates the net total amount of the transaction.
   * @min 0
   * @max 999999999.999
   */
  amount: number;
  /**
   * Indicates the gross total amount of the transaction.
   * @min 0
   * @max 999999999.999
   */
  grossAmount: number;
  /** ISO 4217 currency code (3 characters) */
  currency: CurrencyCodes;
  address: string;
  street?: string;
  houseNumber?: string;
  postalCode?: string;
  city?: string;
  locationName: string;
  locationCode: string;
  /**
   * Last 4 digits of IBAN
   * @min 0
   * @max 9999
   */
  bankAccount: number;
  bankName: string;
  /** Payment method */
  paymentMethod: PaymentMethod;
  /** Cash - deposit.status */
  paymentStatus: string;
  /** The description of the payment, deposit.username for cash */
  remittanceInformation: string;
  /**
   * Only applicable for clicks
   * @maxLength 36
   */
  orderId?: string | null;
  /** Only applicable for clicks */
  customerName?: string;
  /** Only applicable for clicks */
  customerId?: string;
  /**
   * Customer e-mail. Only applicable for clicks
   * @format email
   */
  email?: string;
  /**
   * Not applicable for Cash
   * @min 0
   * @max 9999
   */
  cardNumber?: number;
  /** Not applicable for Cash. Can be retireved from IBAN or BIC */
  customerBankName?: string;
  /** Only applicable for cards */
  terminalId?: string;
  /** Merchant where the transaction occured */
  merchant?: Merchant | null;
  /** ISO 3166 country code (2 characters) */
  country?: CountryCodes;
}

/** Transaction category/type */
export enum TransactionType {
  CASH = "CASH",
  CARDS = "CARDS",
  CLICKS = "CLICKS",
}

/** Payment method */
export enum PaymentMethod {
  CASH = "CASH",
  VISA = "VISA",
  MASTERCARD = "MASTERCARD",
  AMERICAN_EXPRESS = "AMERICAN_EXPRESS",
  VPAY = "VPAY",
  MAESTRO = "MAESTRO",
  IDEAL = "IDEAL",
  BANCONTACT = "BANCONTACT",
  GOOGLE_PAY = "GOOGLE_PAY",
  APPLE_PAY = "APPLE_PAY",
  PAYPAL = "PAYPAL",
}

/** Holds list of store locations to return */
export interface GetLocationsResponse {
  /** List of stores */
  locations: StoreLocation[];
}

/** Holds list of store locations to return */
export interface GetLocationWithContactsResponse {
  /** List of stores */
  locations: StoreLocationWithContact[];
}

/** Holds list of store locations to return */
export interface GetLocationWithContactsAndMerchantResponse {
  /** List of stores */
  locations: StoreLocationWithContact[];
}

/** Store location of a Merchant. A store location has a physical address (physical location). A web-shop will be managed as a store location even it might not have a physical address. Multiple Merchants can operate at the same physical address, e.g. in case of a shop-in-shop. */
export interface StoreLocationWithContactAndMerchant {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * ID of the location in SalesForce
   * @maxLength 36
   */
  externalReferenceSf?: string | null;
  /**
   * ID of the location in ServiceNow
   * @maxLength 36
   */
  externalReferenceSnow?: string | null;
  /**
   * ID of the merchant
   * @format uuid
   */
  merchantId: string;
  /** Physical address of the store location */
  physicalLocation?: PhysicalLocation;
  /**
   * Trade name of the store
   * @maxLength 255
   */
  tradeName?: string | null;
  /**
   * Store location name of the store
   * @minLength 1
   * @maxLength 80
   */
  name: string;
  type: "RETAIL" | "COMMERCIAL" | "BANK";
  /**
   * Location code of the store
   * @maxLength 20
   */
  locationCode?: string | null;
  status: "ACTIVE" | "PENDING_APPROVAL" | "INACTIVE";
  /**
   * Phone number of the store
   * @maxLength 40
   */
  phoneNumber?: string | null;
  /** List of time spans when the store is open */
  openingHours?: OpeningHour[] | null;
  /** List of contacts */
  contacts?: Contact[];
  /** Brink’s customer, often the owner of a shop. The term Merchant is preferred over Customer. */
  merchant?: MerchantWithDependentsAndLocations;
}

/** Holds list of store locations to save */
export interface SaveLocationsRequest {
  /** List of stores */
  locations: StoreLocation[];
}

/** Holds list of saved Stores */
export type SaveLocationsResponse = BrinksMultiStatusResponse & {
  /** List of saved Stores */
  success: StoreLocation[];
};

/** Store location of a Merchant. A store location has a physical address (physical location). A web-shop will be managed as a store location even it might not have a physical address. Multiple Merchants can operate at the same physical address, e.g. in case of a shop-in-shop. */
export interface StoreLocation {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string;
  /**
   * ID of the location in SalesForce
   * @maxLength 36
   */
  externalReferenceSf?: string | null;
  /**
   * ID of the location in ServiceNow
   * @maxLength 36
   */
  externalReferenceSnow?: string | null;
  /**
   * ID of the location in cm.com
   * @maxLength 36
   */
  externalReferenceCm?: string | null;
  /**
   * ID of the merchant
   * @format uuid
   */
  merchantId: string;
  /** Physical address of the store location */
  physicalLocation?: PhysicalLocation;
  /**
   * Trade name of the store
   * @maxLength 255
   */
  tradeName?: string | null;
  /**
   * Store location name of the store
   * @minLength 1
   * @maxLength 80
   */
  name: string;
  type: "RETAIL" | "COMMERCIAL" | "BANK";
  /**
   * Location code of the store
   * @maxLength 20
   */
  locationCode?: string | null;
  status: "ACTIVE" | "PENDING_APPROVAL" | "INACTIVE";
  /**
   * Phone number of the store
   * @maxLength 40
   */
  phoneNumber?: string | null;
  /** List of time spans when the store is open */
  openingHours?: OpeningHour[] | null;
  /** Merchant related to the order */
  merchant?: Merchant | null;
}

/** Store location Id. Helper to reference storelocations from other entities */
export interface StoreLocationId {
  /**
   * ID of the location
   * @format uuid
   */
  storeLocationId: string;
}

/** Store location of a Merchant. A store location has a physical address (physical location). A web-shop will be managed as a store location even it might not have a physical address. Multiple Merchants can operate at the same physical address, e.g. in case of a shop-in-shop. */
export interface StoreLocationRequest {
  /**
   * ID of the location in SalesForce
   * @maxLength 36
   */
  externalReferenceSf?: string | null;
  /**
   * ID of the location in ServiceNow
   * @maxLength 36
   */
  externalReferenceSnow?: string | null;
  /** Physical address of the store location */
  physicalLocation?: PhysicalLocation;
  /**
   * Trade name of the store
   * @maxLength 255
   */
  tradeName?: string | null;
  /**
   * Store location name of the store
   * @minLength 1
   * @maxLength 80
   */
  name: string;
  type: "RETAIL" | "COMMERCIAL" | "BANK";
  /**
   * Location code of the store
   * @maxLength 20
   */
  locationCode?: string | null;
  status: "ACTIVE" | "PENDING_APPROVAL" | "INACTIVE";
  /**
   * Phone number of the store
   * @maxLength 40
   */
  phoneNumber?: string | null;
  /** List of time spans when the store is open */
  openingHours?: OpeningHourRequest[] | null;
}

/** Store location of a Merchant. A store location has a physical address (physical location). A web-shop will be managed as a store location even it might not have a physical address. Multiple Merchants can operate at the same physical address, e.g. in case of a shop-in-shop. */
export interface StoreLocationUpdateRequest {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * ID of the location in SalesForce
   * @maxLength 36
   */
  externalReferenceSf?: string | null;
  /**
   * ID of the location in ServiceNow
   * @maxLength 36
   */
  externalReferenceSnow?: string | null;
  /**
   * ID of the merchant
   * @format uuid
   */
  merchantId: string;
  /** Physical address of the store location */
  physicalLocation?: PhysicalLocationUpdateRequest;
  /**
   * Trade name of the store
   * @maxLength 255
   */
  tradeName?: string | null;
  /**
   * Store location name of the store
   * @minLength 1
   * @maxLength 80
   */
  name: string;
  type: "RETAIL" | "COMMERCIAL" | "BANK";
  /**
   * Location code of the store
   * @maxLength 20
   */
  locationCode?: string | null;
  status: "ACTIVE" | "PENDING_APPROVAL" | "INACTIVE";
  /**
   * Phone number of the store
   * @maxLength 40
   */
  phoneNumber?: string | null;
  /** List of time spans when the store is open */
  openingHours?: OpeningHour[] | null;
}

/** Store location of a Merchant. A store location has a physical address (physical location). A web-shop will be managed as a store location even it might not have a physical address. Multiple Merchants can operate at the same physical address, e.g. in case of a shop-in-shop. */
export interface StoreLocationWithContact {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * ID of the location in SalesForce
   * @maxLength 36
   */
  externalReferenceSf?: string | null;
  /**
   * ID of the location in ServiceNow
   * @maxLength 36
   */
  externalReferenceSnow?: string | null;
  /**
   * ID of the merchant
   * @format uuid
   */
  merchantId: string;
  /** Physical address of the store location */
  physicalLocation?: PhysicalLocation;
  /**
   * Trade name of the store
   * @maxLength 255
   */
  tradeName?: string | null;
  /**
   * Store location name of the store
   * @minLength 1
   * @maxLength 80
   */
  name: string;
  type: "RETAIL" | "COMMERCIAL" | "BANK";
  /**
   * Location code of the store
   * @maxLength 20
   */
  locationCode?: string | null;
  status: "ACTIVE" | "PENDING_APPROVAL" | "INACTIVE";
  /**
   * Phone number of the store
   * @maxLength 40
   */
  phoneNumber?: string | null;
  /** List of time spans when the store is open */
  openingHours?: OpeningHour[] | null;
  /** List of contacts */
  contacts?: Contact[];
}

/** A time span when the store is open */
export interface OpeningHour {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * ID of the store
   * @format uuid
   */
  storeId?: string | null;
  dayOfWeek: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
  /**
   * Store opening time in HH:MM:SS format
   * @pattern ^\d{2}\:\d{2}(\:\d{2})?$
   * @example "10:30"
   */
  openingTime: string;
  /**
   * Store opening time in HH:MM:SS format
   * @pattern ^\d{2}\:\d{2}(\:\d{2})?$
   * @example "17:30"
   */
  closingTime: string;
}

/** A time span when the store is open */
export interface OpeningHourRequest {
  dayOfWeek: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
  /**
   * Store opening time in HH:MM:SS format
   * @pattern ^\d{2}\:\d{2}(\:\d{2})?$
   * @example "10:30"
   */
  openingTime: string;
  /**
   * Store opening time in HH:MM:SS format
   * @pattern ^\d{2}\:\d{2}(\:\d{2})?$
   * @example "17:30"
   */
  closingTime: string;
}

/** Physical address of the store location */
export interface PhysicalLocation {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * Street
   * @minLength 1
   * @maxLength 255
   */
  street: string;
  /**
   * City
   * @minLength 1
   * @maxLength 80
   */
  city: string;
  /** ISO 3166 country code (2 characters) */
  country: CountryCodes;
  /**
   * Mailing Zip/Postal Code
   * @maxLength 20
   */
  postalCode?: string | null;
  /**
   * House number
   * @minLength 1
   * @maxLength 40
   */
  houseNumber: string;
  /**
   * This field can also be used for state/province/region
   * @maxLength 80
   */
  state?: string | null;
  /**
   * GPS coordinates, Latitude
   * @maxLength 80
   */
  geoLocationLatitude?: string | null;
  /**
   * GPS coordinates, Longitude
   * @maxLength 80
   */
  geoLocationLongitude?: string | null;
  /** Is the store open for servicing on Monday (true) or not (false). This applies to any of the services. */
  servicingOnMonday?: boolean | null;
  /** Is the store open for servicing on Tuesday (true) or not (false). This applies to any of the services. */
  servicingOnTuesday?: boolean | null;
  /** Is the store open for servicing on Wednesday (true) or not (false). This applies to any of the services. */
  servicingOnWednesday?: boolean | null;
  /** Is the store open for servicing on Thursday (true) or not (false). This applies to any of the services. */
  servicingOnThursday?: boolean | null;
  /** Is the store open for servicing on Friday (true) or not (false). This applies to any of the services. */
  servicingOnFriday?: boolean | null;
  /** Is the store open for servicing on Saturday (true) or not (false). This applies to any of the services. */
  servicingOnSaturday?: boolean | null;
  /** Is the store open for servicing on Sunday (true) or not (false). This applies to any of the services. */
  servicingOnSunday?: boolean | null;
}

/** Physical address of the store location */
export interface PhysicalLocationUpdateRequest {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * Street
   * @minLength 1
   * @maxLength 255
   */
  street: string;
  /**
   * City
   * @minLength 1
   * @maxLength 20
   */
  city: string;
  /** ISO 3166 country code (2 characters) */
  country: CountryCodes;
  /**
   * Mailing Zip/Postal Code
   * @maxLength 20
   */
  postalCode?: string | null;
  /**
   * House number
   * @minLength 1
   * @maxLength 40
   */
  houseNumber: string;
  /**
   * This field can also be used for state/province/region
   * @maxLength 80
   */
  state?: string | null;
  /**
   * GPS coordinates, Latitude
   * @maxLength 80
   */
  geoLocationLatitude?: string | null;
  /**
   * GPS coordinates, Longitude
   * @maxLength 80
   */
  geoLocationLongitude?: string | null;
}

/** Holds list of business rules to return */
export interface GetBusinessRulesResponse {
  /** List of business rules */
  businessRules: BusinessRule[];
}

/** Holds list of business rules to save */
export interface SaveBusinessRulesRequest {
  /** List of business rules */
  businessRules: BusinessRule[];
}

/** Holds list of saved business rules */
export type SaveBusinessRulesResponse = BrinksMultiStatusResponse & {
  /** List of saved business rules */
  success: BusinessRule[];
};

/** Business logic criteria per product */
export interface BusinessRule {
  /**
   * ODS merchant id
   * @format uuid
   */
  merchantId: string;
  /**
   * External ID of the Salesforce Location
   * @minLength 1
   * @maxLength 36
   */
  externalLocationReferenceSf: string;
  /** Indicator whether order change is allowed for the location */
  orderChange: boolean;
  /** Indicator to toggle the data-picker in the order process */
  allowDateSelectorOrderChange?: boolean;
  /** Indicator whether transport orders are allowed for the location */
  orderTransport: boolean;
  /** Indicates on which days a user is allowed to make transport orders (change and pick up) */
  allowTransportMon?: boolean;
  /** Indicates on which days a user is allowed to make transport orders (change and pick up) */
  allowTransportTue?: boolean;
  /** Indicates on which days a user is allowed to make transport orders (change and pick up) */
  allowTransportWed?: boolean;
  /** Indicates on which days a user is allowed to make transport orders (change and pick up) */
  allowTransportThu?: boolean;
  /** Indicates on which days a user is allowed to make transport orders (change and pick up) */
  allowTransportFri?: boolean;
  /** Indicates on which days a user is allowed to make transport orders (change and pick up) */
  allowTransportSat?: boolean;
  /** Indicates on which days a user is allowed to make transport orders (change and pick up) */
  allowTransportSun?: boolean;
  allowOrderCancel?: boolean;
  allowTransportCancel?: boolean;
  /**
   * Cut-off-time for orders, in UTC
   * @example "15:00"
   */
  orderCot?: string;
  /**
   * Number of days between the change order and the actual transport
   * @min 0
   */
  orderCod?: number;
  /** @example "15:00" */
  orderCancelCot?: string;
  /**
   * Number of days between the order cancellation and the planned order delivery day
   * @min 0
   */
  orderCancelCod?: number;
  /**
   * Cut-off-time for transport, in UTC
   * @example "15:00"
   */
  transportCot?: string;
  /**
   * Number of days between the transport order and the actual transport
   * @min 0
   */
  transportCod?: number;
  /** @example "15:00" */
  transportCancelCot?: string;
  /** @min 0 */
  transportCancelCod?: number;
  /** Indicator whether notes can be ordered for the location */
  allowNotes?: boolean;
  /**
   * Incremental unit. 1 means user can order individual notes
   * @min 0
   */
  noteIncrement?: number;
  /**
   * Maximum amount (i.e. total value of the order) allowed for one order
   * @min 0
   */
  orderLimit: number;
}

/** Store location of a Merchant. A store location has a physical address (physical location). A web-shop will be managed as a store location even it might not have a physical address. Multiple Merchants can operate at the same physical address, e.g. in case of a shop-in-shop. */
export interface StoreLocationWithActuals {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * ID of the location in SalesForce
   * @maxLength 36
   */
  externalReferenceSf?: string | null;
  /**
   * ID of the location in ServiceNow
   * @maxLength 36
   */
  externalReferenceSnow?: string | null;
  /**
   * ID of the merchant
   * @format uuid
   */
  merchantId: string;
  /** Physical address of the store location */
  physicalLocation?: PhysicalLocationWithActuals;
  /**
   * Trade name of the store
   * @maxLength 255
   */
  tradeName?: string | null;
  /**
   * Store location name of the store
   * @minLength 1
   * @maxLength 80
   */
  name: string;
  type: "RETAIL" | "COMMERCIAL" | "BANK";
  /**
   * Location code of the store
   * @maxLength 20
   */
  locationCode?: string | null;
  status: "ACTIVE" | "PENDING_APPROVAL" | "INACTIVE";
  /**
   * Phone number of the store
   * @maxLength 40
   */
  phoneNumber?: string | null;
}

/** Physical address of the store location */
export interface PhysicalLocationWithActuals {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * Street
   * @minLength 1
   * @maxLength 255
   */
  street: string;
  /**
   * City
   * @minLength 1
   * @maxLength 20
   */
  city: string;
  /** ISO 3166 country code (2 characters) */
  country: CountryCodes;
  /**
   * Mailing Zip/Postal Code
   * @maxLength 20
   */
  postalCode?: string | null;
  /**
   * House number
   * @minLength 1
   * @maxLength 40
   */
  houseNumber: string;
  /**
   * This field can also be used for state/province/region
   * @maxLength 80
   */
  state?: string | null;
  /**
   * GPS coordinates, Latitude
   * @maxLength 80
   */
  geoLocationLatitude?: string | null;
  /**
   * GPS coordinates, Longitude
   * @maxLength 80
   */
  geoLocationLongitude?: string | null;
  /** List of cash devices belonging to the store */
  cashDevices?: CashDeviceWithActuals[];
}

/** Holds list of merchants to return */
export interface GetMerchantsAndLocationsResponse {
  /** List of merchants and its locations */
  merchants: MerchantsAndLocations[];
}

/** Brink's customer, often the owner of a shop. The term Merchant is preferred over Customer. */
export interface MerchantsAndLocations {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * Id of the parent merchant
   * @format uuid
   */
  parentId?: string | null;
  /**
   * External reference of the Salesforce Account object
   * @maxLength 36
   */
  externalReferenceSf?: string | null;
  /**
   * External reference of the ServiceNow Account object
   * @maxLength 36
   */
  externalReferenceSnow?: string | null;
  /**
   * Channel from where Account was created
   * @maxLength 255
   */
  merchantSource?: string | null;
  /**
   * Legal name of the merchant
   * @minLength 1
   * @maxLength 255
   */
  name: string;
  /**
   * Trade name of the merchant
   * @maxLength 255
   */
  tradeName?: string | null;
  /**
   * Street, Housenumber and housenumber extention are merged
   * @minLength 1
   * @maxLength 255
   */
  street: string;
  /**
   * City
   * @minLength 1
   * @maxLength 40
   */
  city: string;
  /** ISO 3166 country code (2 characters) */
  country: CountryCodes;
  /**
   * Mailing Zip/Postal Code
   * @maxLength 20
   */
  postalCode?: string | null;
  /**
   * Branche name (in line with Dynamics)
   * @minLength 1
   * @maxLength 50
   */
  sector: string;
  /**
   * A subdivision of a larger branch. This field is dependent on sector field.
   * @maxLength 50
   */
  subSector?: string | null;
  /**
   * This field can also be used for state/province/region
   * @maxLength 80
   */
  state?: string | null;
  /**
   * Merchant (Salesforce Account) phone
   * @maxLength 40
   */
  phoneNumber?: string | null;
  active: boolean;
  /** @maxLength 18 */
  registrationNumber?: string | null;
  /**
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  companyRegistrationDate?: string | null;
  /** @maxLength 15 */
  vatNumber?: string | null;
  /**
   * An  SBI Code describes the main activity of a company. five-digit number.
   * @min 0
   * @max 99999
   */
  companySbi?: number | null;
  /**
   * A Merchant Category Code (MCC) is a four-digit number listed in ISO 18245 for retail financial services
   * @min 0
   * @max 9999
   */
  mccCode?: number | null;
  /** ISO 4217 currency code (3 characters) */
  currency?: CurrencyCodes | null;
  /**
   * Brinks debtor number
   * @maxLength 255
   */
  debtorNumber?: string | null;
  /** Locations where this merchant exist */
  storeLocations?: any[] | null;
}

/** Holds list of skus to return */
export interface GetSkusResponse {
  /** List of skus */
  skus: Sku[];
}

/** SKU is an unique identifier for products and/or services that merchant may buy/order from Brink’s. */
export interface GetSkuResponse {
  /**
   * The unique ID of the Sku provided by Salesforce.
   * @minLength 1
   * @maxLength 36
   * @example "CabzfOAWxzRYCNQ"
   */
  externalReferenceSf: string | null;
  /**
   * @minLength 1
   * @maxLength 12
   */
  skuNumber: string;
  /**
   * Name of the Product
   * @minLength 1
   * @maxLength 255
   */
  name: string;
  /**
   * @minLength 1
   * @maxLength 255
   */
  description: string;
  /**
   * Type of the Product
   * @minLength 1
   * @maxLength 40
   */
  type: string;
  /**
   * Quantity of the Product
   * @min 0
   */
  quantity: number;
  /** Status of the product */
  status: "DRAFT" | "ACTIVE" | "RETIRED" | "UNDER_REVISION";
  /** Product Price */
  price: number;
  /** ISO 4217 currency code (3 characters) */
  currency: CurrencyCodes;
  /** @maxLength 255 */
  imageUrl?: string | null;
}

/** SKU is an unique identifier for products and/or services that merchant may buy/order from Brink’s. */
export interface Sku {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * System generated ID
   * @format uuid
   */
  priceBookId?: string | null;
  /**
   * @minLength 1
   * @maxLength 12
   */
  skuNumber?: string;
  /**
   * Name of the Product
   * @minLength 1
   * @maxLength 255
   */
  name: string;
  /**
   * @minLength 1
   * @maxLength 255
   */
  description: string;
  /**
   * Type of the Product
   * @minLength 1
   * @maxLength 40
   */
  type: string;
  /**
   * Quantity of the Product
   * @min 0
   * @maxLength 4
   */
  quantity: number;
  /** Status of the product */
  status: "DRAFT" | "ACTIVE" | "RETIRED" | "UNDER_REVISION";
  /** Product Price */
  price: number;
  /** ISO 4217 currency code (3 characters) */
  currency: CurrencyCodes;
  /** @maxLength 255 */
  imageUrl?: string | null;
  /**
   * The denomination used for this SKU.
   * @format uuid
   */
  denominationId?: string;
  /** Denomination is a proper description of a currency amount, usually for coins or banknotes. */
  denomination?: Denomination;
}

/** SKU is an unique identifier for products and/or services that merchant may buy/order from Brink’s. */
export interface SaveSkuRequest {
  /**
   * The unique ID of the Sku provided by Salesforce.
   * @minLength 1
   * @maxLength 36
   * @example "CabzfOAWxzRYCNQ"
   */
  externalReferenceSf?: string | null;
  /**
   * @minLength 1
   * @maxLength 12
   */
  skuNumber?: string;
  /**
   * Name of the Product
   * @minLength 1
   * @maxLength 255
   */
  name: string;
  /**
   * @minLength 1
   * @maxLength 255
   */
  description: string;
  /**
   * Type of the Product
   * @minLength 1
   * @maxLength 40
   */
  type: string;
  /**
   * Quantity of the Product
   * @min 0
   * @max 9999
   */
  quantity: number;
  /** Status of the product */
  status: "DRAFT" | "ACTIVE" | "RETIRED" | "UNDER_REVISION";
  /** Product Price */
  price: number;
  /** @maxLength 255 */
  imageUrl?: string | null;
  /** Denomination is a proper description of a currency amount, usually for coins or banknotes. */
  denomination: Denomination;
}

/** SKU is an unique identifier for products and/or services that merchant may buy/order from Brink’s. */
export interface SkuUpdateRequest {
  /**
   * The unique ID of the Sku provided by Salesforce.
   * @minLength 1
   * @maxLength 36
   * @example "CabzfOAWxzRYCNQ"
   */
  externalReferenceSf: string | null;
  /**
   * @minLength 1
   * @maxLength 12
   */
  skuNumber?: string;
  /**
   * Name of the Product
   * @minLength 1
   * @maxLength 255
   */
  name?: string;
  /**
   * @minLength 1
   * @maxLength 255
   */
  description?: string;
  /**
   * Type of the Product
   * @minLength 1
   * @maxLength 40
   */
  type?: string;
  /**
   * Quantity of the Product
   * @min 0
   * @max 9999
   */
  quantity?: number;
  /** Status of the product */
  status?: "DRAFT" | "ACTIVE" | "RETIRED" | "UNDER_REVISION";
  /** Product Price */
  price?: number;
  /** @maxLength 255 */
  imageUrl?: string | null;
  /** Denomination is a proper description of a currency amount, usually for coins or banknotes. */
  denomination?: Denomination;
}

/** Holds list of location services to return */
export interface GetLocationServicesResponse {
  /** List of location services */
  locationServices: LocationService[];
}

/** Basic data of a Location Service based on the internal ODS IDs. */
export interface GetLocationServiceResponse {
  /**
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  validFrom: string;
  /**
   * The unique internal ODS ID of the Store Location.
   * @format uuid
   */
  storeLocationSfId: string;
  /**
   * The unique internal ODS ID of the Service.
   * @format uuid
   */
  service: string;
  /**
   * The salesforce ID of the Partner.
   * @format uuid
   */
  partnerSfId: string;
  /** Does the Partner provide this Service for this Store Location on Monday? This is based on the contract with this Store Location. */
  servicingOnMonday: boolean;
  /** Does the Partner provide this Service for this Store Location on Tuesday? This is based on the contract with this Store Location. */
  servicingOnTuesday: boolean;
  /** Does the Partner provide this Service for this Store Location on Wednesday? This is based on the contract with this Store Location. */
  servicingOnWednesday: boolean;
  /** Does the Partner provide this Service for this Store Location on Thursday? This is based on the contract with this Store Location. */
  servicingOnThursday: boolean;
  /** Does the Partner provide this Service for this Store Location on Friday? This is based on the contract with this Store Location. */
  servicingOnFriday: boolean;
  /** Does the Partner provide this Service for this Store Location on Saturday? This is based on the contract with this Store Location. */
  servicingOnSaturday: boolean;
  /** Does the Partner provide this Service for this Store Location on Sunday? This is based on the contract with this Store Location. */
  servicingOnSunday: boolean;
  /** Does the Partner process request for this Service for this Store Location on Sunday? This is based on the contract with this Store Location. */
  processRequestOnSaturday: boolean;
  /** Does the Partner process request for this Service for this Store Location on Sunday? This is based on the contract with this Store Location. */
  processRequestOnSunday: boolean;
  /** Location service salesforce id. */
  locationServiceSfId?: string | null;
}

/** Basic data of a Location Service based on the internal ODS IDs. */
export interface LocationService {
  /**
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  validFrom: string;
  /**
   * The unique internal ODS ID of the Store Location.
   * @format uuid
   */
  storeLocationId: string;
  /**
   * The unique internal ODS ID of the Service.
   * @format uuid
   */
  serviceId: string;
  /**
   * The unique internal ODS ID of the Partner.
   * @format uuid
   */
  partnerId: string;
  /** Does the Partner provide this Service for this Store Location on Monday? This is based on the contract with this Store Location. */
  servicingOnMonday: boolean;
  /** Does the Partner provide this Service for this Store Location on Tuesday? This is based on the contract with this Store Location. */
  servicingOnTuesday: boolean;
  /** Does the Partner provide this Service for this Store Location on Wednesday? This is based on the contract with this Store Location. */
  servicingOnWednesday: boolean;
  /** Does the Partner provide this Service for this Store Location on Thursday? This is based on the contract with this Store Location. */
  servicingOnThursday: boolean;
  /** Does the Partner provide this Service for this Store Location on Friday? This is based on the contract with this Store Location. */
  servicingOnFriday: boolean;
  /** Does the Partner provide this Service for this Store Location on Saturday? This is based on the contract with this Store Location. */
  servicingOnSaturday: boolean;
  /** Does the Partner provide this Service for this Store Location on Sunday? This is based on the contract with this Store Location. */
  servicingOnSunday: boolean;
  /** Location service salesforce id. */
  locationServiceSfId?: string | null;
  /**
   * System generated ID
   * @format uuid
   */
  locationServiceId?: string | null;
}

/** Basic data of a Location Service and Money Order Business Rules. */
export interface UpdateLocationService {
  /**
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  validFrom?: string | null;
  /**
   * The unique ID of the storeLocation provided by Salesforce.
   * @minLength 1
   * @maxLength 36
   * @example "CabzfOAWxzRYCNQ"
   */
  storeLocationSfId?: string | null;
  /** Service type. */
  service?: "CASH_ORDER" | "TRANSPORT_ORDER" | null;
  /**
   * The unique service ID of the Partner provided by Salesforce.
   * @minLength 1
   * @maxLength 36
   */
  partnerServiceReferenceSf?: string;
  /** Does the Partner provide this Service for this Store Location on Monday? This is based on the contract with this Store Location. */
  servicingOnMonday?: boolean | null;
  /** Does the Partner provide this Service for this Store Location on Tuesday? This is based on the contract with this Store Location. */
  servicingOnTuesday?: boolean | null;
  /** Does the Partner provide this Service for this Store Location on Wednesday? This is based on the contract with this Store Location. */
  servicingOnWednesday?: boolean | null;
  /** Does the Partner provide this Service for this Store Location on Thursday? This is based on the contract with this Store Location. */
  servicingOnThursday?: boolean | null;
  /** Does the Partner provide this Service for this Store Location on Friday? This is based on the contract with this Store Location. */
  servicingOnFriday?: boolean | null;
  /** Does the Partner provide this Service for this Store Location on Saturday? This is based on the contract with this Store Location. */
  servicingOnSaturday?: boolean | null;
  /** Does the Partner provide this Service for this Store Location on Sunday? This is based on the contract with this Store Location. */
  servicingOnSunday?: boolean | null;
  /** Location service salesforce id. */
  locationServiceSfId?: string | null;
  /** Is this Store Location allowed to order change money or not. */
  orderAllow?: boolean | null;
  /** It this Store Location allowed to order change money by selected a date manually? */
  orderAllowCustomDates?: boolean | null;
  /**
   * The number of days ahead the Store Location must make the order for change money.
   * @min 0
   * @max 365
   */
  orderLeadDays?: number | null;
  /**
   * The time when a new Lead Day starts for Money Orders.
   * @format time
   * @pattern ^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$
   */
  orderCutOffTime?: string | null;
  /**
   * The number of notes this Store Location order in one order.
   * @min 1
   */
  orderMaximumNotes?: number | null;
  /**
   * The number of coin roles this Store Location order in one order.
   * @min 1
   */
  orderMaximumCoinRoles?: number | null;
  /**
   * The maximum amount this Store Location car order in one order within the currency.
   * @min 1
   */
  orderMaximumAmount?: number | null;
  /** Is this Store Location allowed to cancel a change money order or not. */
  cancelAllow?: boolean | null;
  /**
   * The amount of days ahead the Store Location must cancel the order.
   * @min 0
   * @max 365
   */
  cancelLeadDays?: number | null;
  /**
   * The time when a new Lead Day starts for Money Order cancellations.
   * @format time
   * @pattern ^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$
   */
  cancelCutOffTime?: string | null;
}

/** Basic data of a Location Service and Money Order Business Rules. */
export interface SaveLocationServiceRequest {
  /**
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  validFrom: string;
  /**
   * The unique ID of the storeLocation provided by Salesforce.
   * @minLength 1
   * @maxLength 36
   * @example "CabzfOAWxzRYCNQ"
   */
  storeLocationSfId: string | null;
  /** Service type. */
  service: "CASH_ORDER" | "TRANSPORT_ORDER";
  /**
   * The unique Service ID of the Partner provided by Salesforce.
   * @minLength 1
   * @maxLength 36
   */
  partnerServiceReferenceSf: string | null;
  /** Does the Partner provide this Service for this Store Location on Monday? This is based on the contract with this Store Location. */
  servicingOnMonday?: boolean;
  /** Does the Partner provide this Service for this Store Location on Tuesday? This is based on the contract with this Store Location. */
  servicingOnTuesday?: boolean;
  /** Does the Partner provide this Service for this Store Location on Wednesday? This is based on the contract with this Store Location. */
  servicingOnWednesday?: boolean;
  /** Does the Partner provide this Service for this Store Location on Thursday? This is based on the contract with this Store Location. */
  servicingOnThursday?: boolean;
  /** Does the Partner provide this Service for this Store Location on Friday? This is based on the contract with this Store Location. */
  servicingOnFriday?: boolean;
  /** Does the Partner provide this Service for this Store Location on Saturday? This is based on the contract with this Store Location. */
  servicingOnSaturday?: boolean;
  /** Does the Partner provide this Service for this Store Location on Sunday? This is based on the contract with this Store Location. */
  servicingOnSunday?: boolean;
  /** Location service salesforce id. */
  locationServiceSfId: string | null;
  /** Is this Store Location allowed to order change money or not. */
  orderAllow: boolean;
  /** It this Store Location allowed to order change money by selected a date manually? */
  orderAllowCustomDates: boolean;
  /**
   * The number of days ahead the Store Location must make the order for change money.
   * @min 0
   * @max 365
   */
  orderLeadDays?: number | null;
  /**
   * The time when a new Lead Day starts for Money Orders.
   * @format time
   * @pattern ^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$
   */
  orderCutOffTime?: string | null;
  /**
   * The number of notes this Store Location order in one order.
   * @min 1
   */
  orderMaximumNotes?: number | null;
  /**
   * The number of coin roles this Store Location order in one order.
   * @min 1
   */
  orderMaximumCoinRoles?: number | null;
  /**
   * The maximum amount this Store Location car order in one order within the currency.
   * @min 1
   */
  orderMaximumAmount?: number | null;
  /** Is this Store Location allowed to cancel a change money order or not. */
  cancelAllow: boolean;
  /**
   * The amount of days ahead the Store Location must cancel the order.
   * @min 0
   * @max 365
   */
  cancelLeadDays?: number | null;
  /**
   * The time when a new Lead Day starts for Money Order cancellations.
   * @format time
   * @pattern ^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$
   */
  cancelCutOffTime?: string | null;
}

/** Generic term for products and services that a Merchant may request from Brink’s. Can be an order for Cash, Collection and Transport, Consumables, Digital Payment Order, consisting of multiple transactions. */
export interface OrderWithMerchant {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * External Salesforce reference of the (change) order
   * @maxLength 36
   */
  externalReferenceSf?: string | null;
  /**
   * The ID of store_location object
   * @format uuid
   */
  storeId: string;
  /**
   * ID of the contract object
   * @format uuid
   */
  contractId?: string | null;
  /**
   * The date and time the order was placed
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  orderDateTime: string;
  /**
   * Order number assigned to this order (not the unique, system-generated ID assigned during creation).
   * @maxLength 40
   */
  orderNumber?: string | null;
  /**
   * The original order that a change order was created from.
   * @maxLength 36
   */
  originalOrderId?: string | null;
  /**
   * Order description.
   * @maxLength 255
   */
  orderName?: string | null;
  status:
    | "NEW"
    | "PICKED"
    | "SHIPPED"
    | "RECEIVED"
    | "DELIVERED"
    | "CANCELLED"
    | "IN_PROGRESS"
    | "SENT_TO_FULFILLMENT"
    | "REJECTED";
  /**
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  deliveryDateTime?: string | null;
  /** Customer can choose between Flexible and Fixed Delivery. */
  isFixedDelivery?: boolean | null;
  /** @maxLength 40 */
  paymentMethod?: string | null;
  /** Only required for Change Order */
  totalAmount?: number | null;
  /** ISO 4217 currency code (3 characters) */
  currency?: CurrencyCodes | null;
  frequency?: "ONCE" | "EVERY_1_WEEK" | "EVERY_2_WEEKS" | "EVERY_4_WEEKS" | null;
  /**
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  endDate?: string | null;
  /** List of order items belonging to the order */
  items?: OrderItem[];
  /**
   * Sequence number will auto increment and will give every order a unique number
   * @min 1
   */
  sequenceNumber?: number;
  /**
   * The ODS ID of the merchant
   * @format uuid
   */
  merchantId: string;
  /**
   * Full name of the user who prepared the order
   * @maxLength 255
   */
  preparedBy?: string | null;
  /**
   * Store location name
   * @maxLength 80
   */
  storeName?: string | null;
  /**
   * Store location code
   * @maxLength 20
   */
  storeLocationCode?: string | null;
  /**
   * customer order reference
   * @maxLength 50
   */
  customerReference?: string | null;
  /** Brink’s customer, often the owner of a shop. The term Merchant is preferred over Customer. */
  merchant?: MerchantWithDependentsAndLocations;
  /**
   * The ID of the service object.
   * @format uuid
   */
  serviceId?: string;
  /** Basic customer data including bank account (IBAN and BIC) data and direct debit information related to the merchant store */
  service?: Service;
}

/** Holds list of orders to return */
export interface GetOrdersResponseWithMerchant {
  /** List of orders */
  orders: OrderWithMerchant[];
}

/** Brink’s customer, often the owner of a shop. The term Merchant is preferred over Customer. */
export interface MerchantWithDependentsAndLocations {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * External reference of the Salesforce Account object
   * @maxLength 36
   */
  externalReferenceSf?: string | null;
  /**
   * External reference of the ServiceNow Account object
   * @maxLength 36
   */
  externalReferenceSnow?: string | null;
  /**
   * Channel from where Account was created
   * @maxLength 255
   */
  merchantSource?: string | null;
  /**
   * Legal name of the merchant
   * @minLength 1
   * @maxLength 255
   */
  name?: string;
  /**
   * Trade name of the merchant
   * @maxLength 255
   */
  tradeName?: string | null;
  /**
   * Street, Housenumber and housenumber extention are merged
   * @minLength 1
   * @maxLength 255
   */
  street?: string;
  /**
   * City
   * @minLength 1
   * @maxLength 40
   */
  city?: string;
  /** ISO 3166 country code (2 characters) */
  country?: CountryCodes;
  /**
   * Mailing Zip/Postal Code
   * @maxLength 20
   */
  postalCode?: string | null;
  /**
   * Branche name (in line with Dynamics)
   * @minLength 1
   * @maxLength 50
   */
  sector?: string;
  /**
   * A subdivision of a larger branch. This field is dependent on sector field.
   * @maxLength 50
   */
  subSector?: string | null;
  /**
   * This field can also be used for state/province/region
   * @maxLength 80
   */
  state?: string | null;
  /**
   * Merchant (Salesforce Account) phone
   * @maxLength 40
   */
  phoneNumber?: string | null;
  active?: boolean;
  /** @maxLength 18 */
  registrationNumber?: string | null;
  /**
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  companyRegistrationDate?: string | null;
  /** @maxLength 15 */
  vatNumber?: string | null;
  /**
   * An  SBI Code describes the main activity of a company. five-digit number.
   * @min 0
   * @max 99999
   */
  companySbi?: number | null;
  /**
   * A Merchant Category Code (MCC) is a four-digit number listed in ISO 18245 for retail financial services
   * @min 0
   * @max 9999
   */
  mccCode?: number | null;
  /** ISO 4217 currency code (3 characters) */
  currency?: CurrencyCodes | null;
  /**
   * Brinks debtor number
   * @maxLength 255
   */
  debtorNumber?: string | null;
  locations?: StoreLocation[] | null;
  dependents?: MerchantWithDependentsAndLocations[] | null;
}

/** Holds list of store locations with merchant information to return */
export interface GetStoreLocationsWithMerchantResponse {
  /** List of stores with merchants information */
  locations: StoreLocationWithMerchant[];
}

/** Store location with Merchant information. A store location has a physical address (physical location). A web-shop will be managed as a store location even it might not have a physical address. Multiple Merchants can operate at the same physical address, e.g. in case of a shop-in-shop. */
export interface StoreLocationWithMerchant {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string;
  /**
   * ID of the location in SalesForce
   * @maxLength 36
   */
  externalReferenceSf?: string | null;
  /**
   * ID of the location in ServiceNow
   * @maxLength 36
   */
  externalReferenceSnow?: string | null;
  /**
   * ID of the merchant
   * @format uuid
   */
  merchantId: string;
  /** Physical address of the store location */
  physicalLocation?: PhysicalLocation;
  /**
   * Trade name of the store
   * @maxLength 255
   */
  tradeName?: string | null;
  /**
   * Store location name of the store
   * @maxLength 80
   */
  name: string;
  type: "BANK" | "RETAIL" | "COMMERCIAL";
  /**
   * Location code of the store
   * @maxLength 20
   */
  locationCode?: string | null;
  status: "ACTIVE" | "INACTIVE" | "PENDING_APPROVAL";
  /**
   * Phone number of the store
   * @maxLength 40
   */
  phoneNumber?: string | null;
  /** List of time spans when the store is open */
  openingHours?: OpeningHour[] | null;
  /** Brink’s customer, often the owner of a shop. The term Merchant is preferred over Customer. */
  merchant?: MerchantWithDependentsAndLocations;
}

/** Holds list of orders to return with merchant information */
export interface GetOrderDetailsResponseWithMerchant {
  /** List of orders with merchant information */
  orders: OrderDetailsWithMerchant[];
}

/** Generic term for products and services that a Merchant may request from Brink’s. Can be an order for Cash, Collection and Transport, Consumables, Digital Payment Order, consisting of multiple transactions. */
export interface OrderDetailsWithMerchant {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * External Salesforce reference of the (change) order
   * @maxLength 36
   */
  externalReferenceSf?: string | null;
  /**
   * The ID of store_location object
   * @format uuid
   */
  storeId: string;
  /**
   * ID of the contract object
   * @format uuid
   */
  contractId?: string | null;
  /**
   * The date and time the order was placed
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  orderDateTime: string;
  /**
   * Order number assigned to this order (not the unique, system-generated ID assigned during creation).
   * @maxLength 40
   */
  orderNumber?: string | null;
  /**
   * The original order that a change order was created from.
   * @maxLength 36
   */
  originalOrderId?: string | null;
  /**
   * Order description.
   * @maxLength 255
   */
  orderName?: string | null;
  status:
    | "NEW"
    | "DELIVERED"
    | "CANCELLED"
    | "PICKED"
    | "SENT_TO_FULFILLMENT"
    | "SHIPPED"
    | "RECEIVED"
    | "IN_PROGRESS"
    | "REJECTED";
  /**
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  deliveryDateTime?: string | null;
  /** Customer can choose between Flexible and Fixed Delivery. */
  isFixedDelivery?: boolean | null;
  /** @maxLength 40 */
  paymentMethod?: string | null;
  /** Only required for Change Order */
  totalAmount?: number | null;
  /**
   * The ID of the service object.
   * @format uuid
   */
  serviceId?: string;
  /** Service configuration related to the order */
  service?: Service | null;
  /** ISO 4217 currency code (3 characters) */
  currency?: CurrencyCodes | null;
  frequency?: "ONCE" | "EVERY_1_WEEK" | "EVERY_2_WEEKS" | "EVERY_4_WEEKS" | null;
  /**
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  endDate?: string | null;
  /** List of order items belonging to the order */
  items?: OrderItemDetails[];
  /**
   * Sequence number will auto increment and will give every order a unique number
   * @min 1
   */
  sequenceNumber?: number;
  /**
   * The ODS ID of the merchant
   * @format uuid
   */
  merchantId: string;
  /**
   * Full name of the user who prepared the order
   * @maxLength 255
   */
  preparedBy?: string | null;
  /**
   * Store location name
   * @maxLength 80
   */
  storeName?: string | null;
  /**
   * Store location code
   * @maxLength 20
   */
  storeLocationCode?: string | null;
  /**
   * customer order reference
   * @maxLength 50
   */
  customerReference?: string | null;
  /** Brink’s customer, often the owner of a shop. The term Merchant is preferred over Customer. */
  merchant?: MerchantWithDependentsAndLocations;
}

/** Collective term for hardware that is used for cash service offerings */
export interface CashDeviceWithActuals {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * Physical Location Id that cash device belongs to
   * @format uuid
   */
  physicalLocationId: string;
  /**
   * External Reference for a (cash) device object
   * @maxLength 36
   */
  externalReference?: string | null;
  /**
   * Store Location Id that cash device belongs to
   * @format uuid
   */
  storeLocationId: string;
  /**
   * POS terminal, SmartSafe, etc …
   * @minLength 1
   * @maxLength 40
   */
  type: string;
  /**
   * Online POS, Offline POS, Cloud-based POS, Mobile POS, Tablet POS, etc …
   * @maxLength 40
   */
  subType?: string | null;
  /**
   * The identifier of the device and also known as locationId/locationCode generated in Local Brinks (NL). The format of this property is a letter followed by 5 digits (e.g. C10230)
   * @maxLength 40
   */
  countryDeviceId?: string | null;
  /**
   * @minLength 1
   * @maxLength 40
   */
  deviceName: string;
  /** @maxLength 40 */
  serialNumber?: string | null;
  ecrSystem: boolean;
  /**
   * @minLength 1
   * @maxLength 40
   */
  status: "OK" | "WARNING" | "ERROR";
  /**
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  statusChanged?: string | null;
  /** List of sealbags belonging to the cash device */
  sealbags?: SealbagWithActualsAndDenominations[];
}

/** Holds list of cash devices to return */
export interface GetCashDevicesResponse {
  /** List of devices */
  devices: CashDevice[];
}

/** Holds list of cash devices to add */
export interface SaveCashDevicesRequest {
  /** List of devices */
  devices: CashDevice[];
}

/** Holds list of saved Cash Devices */
export type SaveCashDevicesResponse = BrinksMultiStatusResponse & {
  /** List of saved Cash Devices */
  success: CashDevice[];
};

/** Collective term for hardware that is used for cash service offerings */
export interface CashDevice {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * Physical Location Id that cash device belongs to
   * @format uuid
   */
  physicalLocationId: string;
  /**
   * External Reference for a (cash) device object
   * @maxLength 36
   */
  externalReference?: string | null;
  /**
   * Store Location Id that cash device belongs to
   * @format uuid
   */
  storeLocationId: string;
  /**
   * POS terminal, SmartSafe, etc …
   * @minLength 1
   * @maxLength 40
   */
  type: string;
  /**
   * Online POS, Offline POS, Cloud-based POS, Mobile POS, Tablet POS, etc …
   * @maxLength 40
   */
  subType?: string | null;
  /**
   * The identifier of the device and also known as locationId/locationCode generated in Local Brinks (NL). The format of this property is a letter followed by 5 digits (e.g. C10230)
   * @maxLength 40
   */
  countryDeviceId?: string | null;
  /**
   * @minLength 1
   * @maxLength 80
   */
  deviceName: string;
  /** @maxLength 40 */
  serialNumber?: string | null;
  ecrSystem: boolean;
  /**
   * @minLength 1
   * @maxLength 40
   */
  status: "OK" | "WARNING" | "ERROR";
  /**
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  statusChanged?: string | null;
}

/** Holds list of non-cash devices to return */
export interface GetNonCashDevicesResponse {
  /** List of devices */
  devices: NonCashDevice[];
}

/** Holds list of non-cash devices to add */
export interface SaveNonCashDevicesRequest {
  /** List of devices */
  devices: NonCashDevice[];
}

/** Holds list of saved Cash Devices */
export type SaveNonCashDevicesResponse = BrinksMultiStatusResponse & {
  /** List of saved Cash Devices */
  success: NonCashDevice[];
};

/** Collective term for hardware that is used for cash service offerings */
export interface NonCashDevice {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * External Reference for a (noncash) device object
   * @maxLength 36
   */
  external_reference?: string | null;
  /**
   * Physical Location Id that non cash device belongs to
   * @format uuid
   */
  physicalLocationId?: string | null;
  /**
   * Store Location Id that non cash device belongs to
   * @format uuid
   */
  storeLocationId?: string | null;
  /**
   * POS terminal, SmartSafe, etc …
   * @maxLength 40
   */
  type?: string | null;
  /**
   * Online POS, Offline POS, Cloud-based POS, Mobile POS, Tablet POS, etc …
   * @maxLength 40
   */
  subType?: string | null;
  /**
   * The identifier of the device and also known as locationId/locationCode generated in Local Brinks (NL). The format of this property is a letter followed by 5 digits (e.g. C10230)
   * @maxLength 40
   */
  countryDeviceId?: string | null;
  /**
   * @minLength 1
   * @maxLength 40
   */
  terminalId: string;
  /**
   * @minLength 1
   * @maxLength 40
   */
  status: string;
  /**
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  statusChanged?: string | null;
}

/** Contains device details */
export interface CashDeviceExtract {
  /**
   * System generated cash device Id
   * @format uuid
   */
  cashDeviceId?: string;
  /** @maxLength 40 */
  serialNumber?: string | null;
  /**
   * System generated ID
   * @format uuid
   */
  sealbagId?: string | null;
  /**
   * Sealbag identifier for external parties
   * @format uuid
   */
  partnerSealbagId?: string;
  /**
   * Sealbag barcode
   * @maxLength 255
   */
  barcode?: string | null;
  /**
   * System generated Id for merchant
   * @format uuid
   */
  merchantId?: string;
  /**
   * ISO Currency code e.g. EUR
   * @maxLength 3
   */
  currencyCode?: string;
  /**
   * System generated Id for store location
   * @format uuid
   */
  storeLocationId?: string;
  /**
   * Store location code
   * @maxLength 20
   */
  locationCode?: string;
  /**
   * Total Device Amount after deposit.
   * @min 0
   */
  totalDeviceAmount?: number;
  /** List of denominnations */
  denominations?: Denomination[];
  /** Sequence number of deposit */
  sequenceNumber?: number;
  /**
   * System generated service cycle Id Id
   * @format uuid
   */
  serviceCycleId?: string;
  /**
   * Type of the device
   * @maxLength 40
   */
  deviceType?: string;
  /**
   * Subtype/product name of the device
   * @maxLength 40
   */
  deviceSubType?: string;
  /**
   * The country code where device is located.
   * @maxLength 2
   */
  deviceCountryCode?: string;
  /**
   * Device NAme
   * @maxLength 40
   */
  deviceName?: string;
}

/** Holds list of merchants to return */
export interface GetMerchantsResponse {
  /** List of merchants */
  merchants: Merchant[];
}

/** Holds list of merchants to return */
export interface GetMerchantBasesResponse {
  /** List of merchants */
  merchants: MerchantBase[];
  /** Total of Pages */
  totalOfPages?: number | null;
}

/** Brink’s customer, often the owner of a shop. The term Merchant is preferred over Customer. */
export interface MerchantBase {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * Id of the parent merchant
   * @format uuid
   */
  parentId?: string | null;
  /**
   * External reference of the Salesforce Account object
   * @maxLength 36
   */
  externalReferenceSf?: string | null;
  /**
   * External reference of the ServiceNow Account object
   * @maxLength 36
   */
  externalReferenceSnow?: string | null;
  /**
   * Channel from where Account was created
   * @maxLength 255
   */
  merchantSource?: string | null;
  /**
   * Legal name of the merchant
   * @minLength 1
   * @maxLength 255
   */
  name: string;
  /**
   * Trade name of the merchant
   * @maxLength 255
   */
  tradeName?: string | null;
  /**
   * Street, Housenumber and housenumber extention are merged
   * @minLength 1
   * @maxLength 255
   */
  street: string;
  /**
   * City
   * @minLength 1
   * @maxLength 40
   */
  city: string;
  /** ISO 3166 country code (2 characters) */
  country: CountryCodes;
  /**
   * Mailing Zip/Postal Code
   * @maxLength 20
   */
  postalCode?: string | null;
  /**
   * Branche name (in line with Dynamics)
   * @minLength 1
   * @maxLength 50
   */
  sector: string;
  /**
   * A subdivision of a larger branch. This field is dependent on sector field.
   * @maxLength 50
   */
  subSector?: string | null;
  /**
   * This field can also be used for state/province/region
   * @maxLength 80
   */
  state?: string | null;
  /**
   * Merchant (Salesforce Account) phone
   * @maxLength 40
   */
  phoneNumber?: string | null;
  active: boolean;
  /** @maxLength 18 */
  registrationNumber?: string | null;
  /**
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  companyRegistrationDate?: string | null;
  /** @maxLength 15 */
  vatNumber?: string | null;
  /**
   * An  SBI Code describes the main activity of a company. five-digit number.
   * @min 0
   * @max 99999
   */
  companySbi?: number | null;
  /**
   * A Merchant Category Code (MCC) is a four-digit number listed in ISO 18245 for retail financial services
   * @min 0
   * @max 9999
   */
  mccCode?: number | null;
  /** ISO 4217 currency code (3 characters) */
  currency?: CurrencyCodes | null;
  /**
   * Brinks debtor number
   * @maxLength 255
   */
  debtorNumber?: string | null;
}

/** Brink’s customer, often the owner of a shop. The term Merchant is preferred over Customer. */
export interface Merchant {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * Id of the parent merchant
   * @format uuid
   */
  parentId?: string | null;
  /**
   * External reference of the Salesforce Account object
   * @maxLength 36
   */
  externalReferenceSf?: string | null;
  /**
   * External reference of the ServiceNow Account object
   * @maxLength 36
   */
  externalReferenceSnow?: string | null;
  /**
   * Channel from where Account was created
   * @maxLength 255
   */
  merchantSource?: string | null;
  /**
   * Legal name of the merchant
   * @minLength 1
   * @maxLength 255
   */
  name: string;
  /**
   * Trade name of the merchant
   * @maxLength 255
   */
  tradeName?: string | null;
  /**
   * Street, Housenumber and housenumber extention are merged
   * @minLength 1
   * @maxLength 255
   */
  street: string;
  /**
   * City
   * @minLength 1
   * @maxLength 40
   */
  city: string;
  /** ISO 3166 country code (2 characters) */
  country: CountryCodes;
  /**
   * Mailing Zip/Postal Code
   * @maxLength 20
   */
  postalCode?: string | null;
  /**
   * Branche name (in line with Dynamics)
   * @minLength 1
   * @maxLength 50
   */
  sector: string;
  /**
   * A subdivision of a larger branch. This field is dependent on sector field.
   * @maxLength 50
   */
  subSector?: string | null;
  /**
   * This field can also be used for state/province/region
   * @maxLength 80
   */
  state?: string | null;
  /**
   * Merchant (Salesforce Account) phone
   * @maxLength 40
   */
  phoneNumber?: string | null;
  active: boolean;
  /** @maxLength 18 */
  registrationNumber?: string | null;
  /**
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  companyRegistrationDate?: string | null;
  /** @maxLength 15 */
  vatNumber?: string | null;
  /**
   * An  SBI Code describes the main activity of a company. five-digit number.
   * @min 0
   * @max 99999
   */
  companySbi?: number | null;
  /**
   * A Merchant Category Code (MCC) is a four-digit number listed in ISO 18245 for retail financial services
   * @min 0
   * @max 9999
   */
  mccCode?: number | null;
  /** ISO 4217 currency code (3 characters) */
  currency?: CurrencyCodes | null;
  /**
   * Brinks debtor number
   * @maxLength 255
   */
  debtorNumber?: string | null;
  /** List of stores belonging to the merchant */
  locations?: StoreLocation[];
  /** List of contacts of the merchant */
  contacts?: ContactWithLocations[];
}

/** Brink’s customer, often the owner of a shop. The term Merchant is preferred over Customer. */
export interface UpdateMerchantRequest {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * Id of the parent merchant
   * @format uuid
   */
  parentId?: string | null;
  /**
   * External reference of the Salesforce Account object
   * @maxLength 36
   */
  externalReferenceSf?: string | null;
  /**
   * External reference of the ServiceNow Account object
   * @maxLength 36
   */
  externalReferenceSnow?: string | null;
  /**
   * Channel from where Account was created
   * @maxLength 255
   */
  merchantSource?: string | null;
  /**
   * Legal name of the merchant
   * @minLength 1
   * @maxLength 255
   */
  name: string;
  /**
   * Trade name of the merchant
   * @maxLength 255
   */
  tradeName?: string | null;
  /**
   * Street, Housenumber and housenumber extention are merged
   * @minLength 1
   * @maxLength 255
   */
  street: string;
  /**
   * City
   * @minLength 1
   * @maxLength 40
   */
  city: string;
  /** ISO 3166 country code (2 characters) */
  country: CountryCodes;
  /**
   * Mailing Zip/Postal Code
   * @maxLength 20
   */
  postalCode?: string | null;
  /**
   * Branche name (in line with Dynamics)
   * @minLength 1
   * @maxLength 50
   */
  sector: string;
  /**
   * A subdivision of a larger branch. This field is dependent on sector field.
   * @maxLength 50
   */
  subSector?: string | null;
  /**
   * This field can also be used for state/province/region
   * @maxLength 80
   */
  state?: string | null;
  /**
   * Merchant (Salesforce Account) phone
   * @maxLength 40
   */
  phoneNumber?: string | null;
  active: boolean;
  /** @maxLength 18 */
  registrationNumber?: string | null;
  /**
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  companyRegistrationDate?: string | null;
  /** @maxLength 15 */
  vatNumber?: string | null;
  /**
   * An  SBI Code describes the main activity of a company. five-digit number.
   * @min 0
   * @max 99999
   */
  companySbi?: number | null;
  /**
   * A Merchant Category Code (MCC) is a four-digit number listed in ISO 18245 for retail financial services
   * @min 0
   * @max 9999
   */
  mccCode?: number | null;
  /** ISO 4217 currency code (3 characters) */
  currency?: CurrencyCodes | null;
  /**
   * Brinks debtor number
   * @maxLength 255
   */
  debtorNumber?: string | null;
  /** List of stores belonging to the merchant */
  locations?: StoreLocationUpdateRequest[];
  /** List of contacts of the merchant */
  contacts?: ContactUpdateRequest[];
}

/** Holds list of merchants to save */
export interface SaveMerchantsRequest {
  /** List of merchants */
  merchants: SaveMerchantsRequestBody[];
}

/** Brink’s customer, often the owner of a shop. The term Merchant is preferred over Customer. */
export interface SaveMerchantsRequestBody {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * Id of the parent merchant
   * @format uuid
   */
  parentId?: string | null;
  /**
   * External reference of the Salesforce Account object
   * @maxLength 36
   */
  externalReferenceSf?: string | null;
  /**
   * External reference of the ServiceNow Account object
   * @maxLength 36
   */
  externalReferenceSnow?: string | null;
  /**
   * Channel from where Account was created
   * @maxLength 255
   */
  merchantSource?: string | null;
  /**
   * Legal name of the merchant
   * @minLength 1
   * @maxLength 255
   */
  name: string;
  /**
   * Trade name of the merchant
   * @maxLength 255
   */
  tradeName?: string | null;
  /**
   * Street, Housenumber and housenumber extention are merged
   * @minLength 1
   * @maxLength 255
   */
  street: string;
  /**
   * City
   * @minLength 1
   * @maxLength 40
   */
  city: string;
  /** ISO 3166 country code (2 characters) */
  country: CountryCodes;
  /**
   * Mailing Zip/Postal Code
   * @maxLength 20
   */
  postalCode?: string | null;
  /**
   * Branche name (in line with Dynamics)
   * @minLength 1
   * @maxLength 50
   */
  sector: string;
  /**
   * A subdivision of a larger branch. This field is dependent on sector field.
   * @maxLength 50
   */
  subSector?: string | null;
  /**
   * This field can also be used for state/province/region
   * @maxLength 80
   */
  state?: string | null;
  /**
   * Merchant (Salesforce Account) phone
   * @maxLength 40
   */
  phoneNumber?: string | null;
  active: boolean;
  /** @maxLength 18 */
  registrationNumber?: string | null;
  /**
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  companyRegistrationDate?: string | null;
  /** @maxLength 15 */
  vatNumber?: string | null;
  /**
   * An  SBI Code describes the main activity of a company. five-digit number.
   * @min 0
   * @max 99999
   */
  companySbi?: number | null;
  /**
   * A Merchant Category Code (MCC) is a four-digit number listed in ISO 18245 for retail financial services
   * @min 0
   * @max 9999
   */
  mccCode?: number | null;
  /** ISO 4217 currency code (3 characters) */
  currency?: CurrencyCodes | null;
  /**
   * Brinks debtor number
   * @maxLength 255
   */
  debtorNumber?: string | null;
  /** List of stores belonging to the merchant */
  locations?: StoreLocationRequest[];
  /** List of contacts of the merchant */
  contacts?: ContactRequest[];
}

/** Holds list of saved Merchants */
export type SaveMerchantsResponse = BrinksMultiStatusResponse & {
  /** List of saved Merchants */
  success: Merchant[];
};

/** Holds list of merchants with actuals to return */
export interface GetMerchantsWithActualsResponse {
  /** List of merchants with actuals */
  merchants: MerchantWithActuals[];
}

/** Brink’s customer, often the owner of a shop. The term Merchant is preferred over Customer. */
export interface MerchantWithActuals {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * Id of the parent merchant
   * @format uuid
   */
  parentId?: string | null;
  /**
   * External reference of the Salesforce Account object
   * @maxLength 36
   */
  externalReferenceSf?: string | null;
  /**
   * External reference of the ServiceNow Account object
   * @maxLength 36
   */
  externalReferenceSnow?: string | null;
  /**
   * Channel from where Account was created
   * @maxLength 255
   */
  merchantSource?: string | null;
  /**
   * Legal name of the merchant
   * @minLength 1
   * @maxLength 255
   */
  name: string;
  /**
   * Trade name of the merchant
   * @maxLength 255
   */
  tradeName?: string | null;
  /**
   * Street, Housenumber and housenumber extention are merged
   * @minLength 1
   * @maxLength 255
   */
  street: string;
  /**
   * City
   * @minLength 1
   * @maxLength 40
   */
  city: string;
  /** ISO 3166 country code (2 characters) */
  country: CountryCodes;
  /**
   * Mailing Zip/Postal Code
   * @maxLength 20
   */
  postalCode?: string | null;
  /**
   * Branche name (in line with Dynamics)
   * @minLength 1
   * @maxLength 50
   */
  sector: string;
  /**
   * A subdivision of a larger branch. This field is dependent on sector field.
   * @maxLength 50
   */
  subSector?: string | null;
  /**
   * This field can also be used for state/province/region
   * @maxLength 80
   */
  state?: string | null;
  /**
   * Merchant (Salesforce Account) phone
   * @maxLength 40
   */
  phoneNumber?: string | null;
  active: boolean;
  /** @maxLength 18 */
  registrationNumber?: string | null;
  /**
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  companyRegistrationDate?: string | null;
  /** @maxLength 15 */
  vatNumber?: string | null;
  /**
   * An  SBI Code describes the main activity of a company. five-digit number.
   * @min 0
   * @max 99999
   */
  companySbi?: number | null;
  /**
   * A Merchant Category Code (MCC) is a four-digit number listed in ISO 18245 for retail financial services
   * @min 0
   * @max 9999
   */
  mccCode?: number | null;
  /** ISO 4217 currency code (3 characters) */
  currency?: CurrencyCodes | null;
  /**
   * Brinks debtor number
   * @maxLength 255
   */
  debtorNumber?: string | null;
  /** List of stores belonging to the merchant */
  locations?: StoreLocationWithActuals[];
}

/** Holds list of contacts to return */
export interface GetContactsResponse {
  /** List of contacts */
  contacts: ContactWithLocations[];
}

/** Holds list of contacts to add */
export interface SaveContactsRequest {
  /** List of contacts */
  contacts: ContactWithLocations[];
}

/** People Brink's do business with. Contacts are usually associated with a merchant or store location. */
export interface ContactRequest {
  /** @maxLength 36 */
  externalReferenceSf?: string | null;
  /** Contact Type */
  type: "PrimaryContact" | "LocationContact" | "BillingContact";
  /**
   * e.g. Mr., Mrs.
   * @maxLength 40
   */
  salutation?: string | null;
  /**
   * Contact first name
   * @minLength 1
   * @maxLength 40
   */
  firstName: string;
  /**
   * Contact last name
   * @minLength 1
   * @maxLength 80
   */
  lastName: string;
  /**
   * Contact Email Address
   * @format email
   * @minLength 1
   * @maxLength 80
   */
  email: string;
  /**
   * Contact Home Phonenumber
   * @maxLength 40
   */
  phone?: string | null;
  /**
   * Contact Mobile Phonenumber
   * @minLength 1
   * @maxLength 40
   */
  mobilePhone: string;
}

/** People Brink's do business with. Contacts are usually associated with a merchant or store location. */
export interface ContactUpdateRequest {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * Merchant Id that contact belongs to
   * @format uuid
   */
  merchantId: string;
  /** @maxLength 36 */
  externalReferenceSf?: string | null;
  /** Contact Type */
  type: "PrimaryContact" | "LocationContact" | "BillingContact";
  /**
   * e.g. Mr., Mrs.
   * @maxLength 40
   */
  salutation?: string | null;
  /**
   * Contact first name
   * @minLength 1
   * @maxLength 40
   */
  firstName: string;
  /**
   * Contact last name
   * @minLength 1
   * @maxLength 80
   */
  lastName: string;
  /**
   * Contact Email Address
   * @format email
   * @minLength 1
   * @maxLength 80
   */
  email: string;
  /**
   * Contact Home Phonenumber
   * @maxLength 40
   */
  phone?: string | null;
  /**
   * Contact Mobile Phonenumber
   * @minLength 1
   * @maxLength 40
   */
  mobilePhone: string;
  /** List of locations that contact has access to */
  locations?: StoreLocationId[];
}

/** People Brink's do business with. Contacts are usually associated with a merchant or store location. */
export interface Contact {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * Merchant Id that contact belongs to
   * @format uuid
   */
  merchantId: string;
  /** @maxLength 36 */
  externalReferenceSf?: string | null;
  /** Contact Type */
  type: "PrimaryContact" | "LocationContact" | "BillingContact";
  /**
   * e.g. Mr., Mrs.
   * @maxLength 40
   */
  salutation?: string | null;
  /**
   * Contact first name
   * @minLength 1
   * @maxLength 40
   */
  firstName: string;
  /**
   * Contact last name
   * @minLength 1
   * @maxLength 80
   */
  lastName: string;
  /**
   * Contact Email Address
   * @format email
   * @minLength 1
   * @maxLength 80
   */
  email: string;
  /**
   * Contact Home Phonenumber
   * @maxLength 40
   */
  phone?: string | null;
  /**
   * Contact Mobile Phonenumber
   * @minLength 1
   * @maxLength 40
   */
  mobilePhone: string;
}

/** People Brink's do business with. Contacts are usually associated with a merchant or store location. */
export interface ContactWithLocations {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * Merchant Id that contact belongs to
   * @format uuid
   */
  merchantId: string;
  /** @maxLength 36 */
  externalReferenceSf?: string | null;
  /** Contact Type */
  type: "PrimaryContact" | "LocationContact" | "BillingContact";
  /**
   * e.g. Mr., Mrs.
   * @maxLength 40
   */
  salutation?: string | null;
  /**
   * Contact first name
   * @minLength 1
   * @maxLength 40
   */
  firstName: string;
  /**
   * Contact last name
   * @minLength 1
   * @maxLength 80
   */
  lastName: string;
  /**
   * Contact Email Address
   * @format email
   * @minLength 1
   * @maxLength 80
   */
  email: string;
  /**
   * Contact Home Phonenumber
   * @maxLength 40
   */
  phone?: string | null;
  /**
   * Contact Mobile Phonenumber
   * @minLength 1
   * @maxLength 40
   */
  mobilePhone: string;
  /** List of locations that contact has access to */
  locations?: StoreLocationId[];
}

/** Holds list of saved Contacts */
export type SaveContactsResponse = BrinksMultiStatusResponse & {
  /** List of saved Contacts */
  success: ContactWithLocations[];
};

/** Holds list of services to return */
export interface GetServicesResponse {
  /** List of services */
  services: Service[];
}

/** Holds list of services to save */
export interface SaveServicesRequest {
  /** List of services */
  services: Service[];
}

/** Holds list of saved Services */
export type SaveServicesResponse = BrinksMultiStatusResponse & {
  /** List of saved Services */
  success: Service[];
};

/** Basic customer data including bank account (IBAN and BIC) data and direct debit information related to the merchant store */
export interface Service {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * External reference of the Salesforce Account object
   * @maxLength 36
   */
  externalReferenceSf?: string | null;
  /**
   * Possible services (payment methods)
   * @minLength 1
   * @maxLength 500
   */
  description: string;
  /**
   * The name of the Service.
   * @example "CASH_ORDER"
   */
  name: "CASH_ORDER" | "TRANSPORT_ORDER";
}

/** Holds list of services to return */
export interface GetTalendServicesResponse {
  /** List of services */
  services: TalendService[];
}

/** Holds list of services to save */
export interface SaveTalendServicesRequest {
  /** List of services */
  services: TalendService[];
}

/** Holds list of saved Services */
export type SaveTalendServicesResponse = BrinksMultiStatusResponse & {
  /** List of saved Services */
  success: TalendService[];
};

/** Basic customer data including bank account (IBAN and BIC) data and direct debit information related to the merchant store */
export interface TalendService {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * External reference of the Salesforce Account object
   * @maxLength 36
   */
  externalReferenceSf?: string | null;
  /**
   * The ID of store_location object
   * @format uuid
   */
  storeId: string;
  /**
   * Possible services (payment methods)
   * @minLength 1
   * @maxLength 500
   */
  description: string;
  /** Contact Type */
  type: "Cash" | "Cards" | "Clicks";
  status: "Active" | "Inactive";
  /**
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  activationDate?: string | null;
}

/** Holds list of denominations to return */
export interface GetDenominationsResponse {
  /** List of denominations */
  denominations: Denomination[];
}

/** Holds list of denominations to save */
export interface SaveDenominationsRequest {
  /** List of denominations */
  denominations: Denomination[];
}

/** Denomination is a proper description of a currency amount, usually for coins or banknotes. */
export interface Denomination {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * Value of the denomination (note or coin)
   * @min 0
   */
  faceValue: number;
  /** ISO 4217 currency code (3 characters) */
  currency: CurrencyCodes;
  /** Denomination Type */
  type: "Note" | "Coin";
}

/** Holds list of denominations to return */
export interface GetDenominationsResponseForTalend {
  /** List of denominations */
  denominations: DenominationForTalend[];
}

/** Denomination is a proper description of a currency amount, usually for coins or banknotes. */
export interface DenominationForTalend {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /** SKU is an unique identifier for products and/or services that merchant may buy/order from Brink’s. */
  sku?: Sku | null;
  /**
   * Value of the denomination (note or coin)
   * @min 0
   */
  faceValue: number;
  /** ISO 4217 currency code (3 characters) */
  currency: CurrencyCodes;
  /** Denomination Type */
  type: "Note" | "Coin";
}

/** Holds list of sealbags to return */
export interface GetSealbagsResponse {
  /** List of sealbags */
  sealbags: Sealbag[];
}

/** Holds list of pre-announcements to add */
export interface SavePreAnnouncementsRequest {
  /** List of pre-announcements */
  preAnnouncements: PreAnnouncement[];
}

/** Holds list of preAnnouncements to return */
export type SavePreAnnouncementsResponse = SaveResponse & {
  /** List of preAnnouncements */
  preAnnouncements: PreAnnouncement[];
  errors?: {
    /** Deposit pre-announcement */
    record?: PreAnnouncement;
  }[];
};

/** Holds list of pre-announcements to return */
export interface GetPreAnnouncementsResponse {
  /** List of pre-announcements */
  preAnnouncements: PreAnnouncement[];
}

/** Deposit pre-announcement */
export interface PreAnnouncement {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * This is the unique deposit (in this case to pre-announce a deposit) reference as set by the Third party source deposit system
   * @maxLength 36
   */
  externalId?: string | null;
  /**
   * Store Location Id that deposit belongs to
   * @format uuid
   */
  storeLocationId: string;
  /** A bag where coins, bills and checks can be inserted. The closed bag can be deposited in a BBox. The BBox reads the QR code on the seal bag. A seal bag is also inserted into a BSafe, in which case all the deposits that are made through the automated teller drop into an open seal bag. The seal bag is then closed upon Collection. This type of seal bag is called a Liner Bag. A sealbag entity may also represent a Cassette. A (Smart) safe can contain closed sealbags with change, a liner bag, or one or multiple cassettes. */
  sealBag?: Sealbag;
  /** Type of pre-announcement (deposit) */
  type: "COMMERCIAL" | "BANK_BRANCH" | "ATM" | "COMPU_SAFE" | "RETAIL";
  /** Brink’s internal status. PSP status should be translated to Brinks status. */
  status: "ADVISE_REQUESTED" | "CREATED" | "ADJUSTED" | "RECEIVED";
  /**
   * User who prepared the pre-announcement
   * @maxLength 80
   */
  preparedBy?: string | null;
  /**
   * Customer notes related to the pre-announcement
   * @maxLength 255
   */
  customerNotes?: string | null;
  /**
   * To specify originating source of a transaction
   * @maxLength 255
   */
  source?: string | null;
  /** ISO 3166 country code (2 characters) */
  senderCountry: CountryCodes;
  /**
   * Expected deposit pickup date
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  expectedPickupDate?: string | null;
  /**
   * Sequence number will auto increment
   * @min 1
   */
  sequenceNumber?: number;
  /**
   * DateTime the entity was entered/stored in ODS
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  createdAt?: string | null;
  /**
   * DateTime the entity was modified in ODS
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  updatedAt?: string | null;
  /** List of declared value */
  declaredValues?: Declared[];
}

/** Holds list of deposits to add */
export interface SaveDepositsRequest {
  /** List of deposits */
  deposits: DepositRequest[];
}

/** Holds list of deposits to add */
export interface SaveTalendDepositsRequest {
  /** List of deposits */
  deposits: Deposit[];
}

/** Holds list of saved Deposits */
export type SaveDepositsResponse = BrinksMultiStatusResponse & {
  /** List of saved Deposits */
  success: Deposit[];
};

/** Holds list of deposits to return */
export interface GetDepositsResponse {
  /** List of deposits */
  deposits: Deposit[];
}

/** A bag where coins, bills and checks can be inserted. The closed bag can be deposited in a BBox. The BBox reads the QR code on the seal bag. A seal bag is also inserted into a BSafe, in which case all the deposits that are made through the automated teller drop into an open seal bag. The seal bag is then closed upon Collection. This type of seal bag is called a Liner Bag. A sealbag entity may also represent a Cassette. A (Smart) safe can contain closed sealbags with change, a liner bag, or one or multiple cassettes. */
export interface Sealbag {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * External Reference of sealbag object
   * @maxLength 36
   */
  externalReference?: string | null;
  /**
   * a sealbag hierarchy to allow sealbags to be related in a hierarchy by using a 'parent sealbag' field to link them together.
   * @format uuid
   */
  parentSealbagId?: string | null;
  /**
   * ID of the cash device
   * @format uuid
   */
  cashDeviceId?: string | null;
  /**
   * ID of the Collection (the process of picking up money from the Merchant’s store location and transporting it to the Cash Centre)
   * @format uuid
   */
  collectionId?: string | null;
  /**
   * barcode of the sealbag
   * @minLength 1
   * @maxLength 255
   */
  barcode: string;
  /**
   * Additional description of the type
   * @maxLength 255
   */
  description?: string | null;
  /**
   * The verified total amount of the contents of a vehicle such as a seal bag or a cassette, as counted in the Cash Centre.
   * @min 0
   */
  actualTotal?: number | null;
  /**
   * The declared total amount of the contents of a vehicle as a sealbag or a cassette
   * @min 0
   */
  declaredTotal?: number | null;
  /**
   * partner sealbag id receieved from partner systems.
   * @format uuid
   */
  partnerSealbagId?: string | null;
  /** Sealbag Status */
  status?: SealbagStatus;
}

/** The action of inserting money into a smart safe. This may be money that is combined in a seal bag, in case of a BBox, or an individual bill or coin in case of a BSafe or cash recycler. A deposit object is a transaction that records one or more deposits. */
export interface Deposit {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * ID of the merchant
   * @format uuid
   */
  merchantId?: string | null;
  /**
   * This is the unique deposit reference as set by the Third party source deposit system
   * @format uuid
   */
  externalReference?: string | null;
  /**
   * This is the reference for all deposits within one service event
   * @maxLength 36
   */
  billfeedbagExternalReference?: string | null;
  /**
   * Store Location Id that deposit belongs to
   * @format uuid
   */
  storeLocationId?: string | null;
  /**
   * Service Cycle Id that deposit belongs to
   * @format uuid
   */
  serviceCycleId?: string | null;
  /**
   * Linked Pre Announcement entity
   * @format uuid
   */
  preAnnouncementId?: string | null;
  /**
   * ID of the cash device
   * @format uuid
   */
  cashDeviceId?: string | null;
  /**
   * ID of the sealbag (cassettes) object
   * @format uuid
   */
  sealbagId?: string | null;
  /** Transaction type (e.g. Payment) of the linked object. */
  type:
    | "Retail"
    | "Commercial"
    | "Bank Branch"
    | "ATM"
    | "ATM-Residual"
    | "ATM-DPU"
    | "CompuSafe"
    | "BillFeedDeposit"
    | "Other";
  /** Deposit sub type */
  subType?: "BagDeposit" | "BillFeedBagDeposit" | null;
  /**
   * The date entered by the user when this transaction occurred. For posting transactions, this is the posting date that affects the financial statements. If the date is not supplied, the current date on the server is used.
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  transactionDateTime: string;
  /**
   * To specify originating source of a transaction
   * @maxLength 255
   */
  transactionSource?: string | null;
  /**
   * Indicates the total amount of the transaction.
   * @min 0
   * @max 999999999.999
   */
  transactionAmount: number;
  /** Brink’s internal status. PSP status should be translated to Brinks status. */
  status:
    | "Incomplete"
    | "Adjusted"
    | "Cancelled"
    | "Rejected"
    | "CreditDeliveryConf"
    | "DebitTransmitted"
    | "VOIDED"
    | "Verified"
    | "SentToCredit"
    | "CreditDeliveryAck"
    | "Created"
    | "Received"
    | "CreditDeliveryErrorConf";
  /** ISO 4217 currency code (3 characters) */
  currency: CurrencyCodes;
  /**
   * In case of a smart safe, the username of logged in user to register the deposit
   * @maxLength 40
   */
  userName?: string | null;
  /**
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  expectedPickupDate?: string | null;
  /** List of declared value */
  declaredValues?: Declared[];
  /**
   * The description of the payment
   * @maxLength 255
   */
  remittanceInformation?: string | null;
  /**
   * Unique Id of the transaction.
   * @format uuid
   */
  transactionId?: string | null;
  /**
   * Sequence number will auto increment and will give every posted deposit a unique number
   * @min 1
   */
  sequenceNumber?: number;
  /**
   * The date the transaction actually created at ODS
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  createdAt?: string | null;
}

/** The action of inserting money into a smart safe. This may be money that is combined in a seal bag, in case of a BBox, or an individual bill or coin in case of a BSafe or cash recycler. A deposit object is a transaction that records one or more deposits. */
export interface DepositRequest {
  /**
   * This is the unique deposit reference as set by the Third party source deposit system
   * @format uuid
   */
  externalReference?: string | null;
  /**
   * This is the reference for all deposits within one service event
   * @maxLength 36
   */
  billfeedbagExternalReference?: string | null;
  /**
   * Store Location Id that deposit belongs to
   * @format uuid
   */
  storeLocationId?: string | null;
  /**
   * Service Cycle Id that deposit belongs to
   * @format uuid
   */
  serviceCycleId?: string | null;
  /**
   * Linked Pre Announcement entity
   * @format uuid
   */
  preAnnouncementId?: string | null;
  /**
   * ID of the cash device
   * @format uuid
   */
  cashDeviceId?: string | null;
  /**
   * ID of the sealbag (cassettes) object
   * @format uuid
   */
  sealbagId?: string | null;
  /** Transaction type (e.g. Payment) of the linked object. */
  type:
    | "Retail"
    | "Commercial"
    | "Bank Branch"
    | "ATM"
    | "ATM-Residual"
    | "ATM-DPU"
    | "CompuSafe"
    | "BillFeedDeposit"
    | "Other";
  /** Deposit sub type */
  subType?: "BagDeposit" | "BillFeedBagDeposit" | null;
  /**
   * The date entered by the user when this transaction occurred. For posting transactions, this is the posting date that affects the financial statements. If the date is not supplied, the current date on the server is used.
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  transactionDateTime: string;
  /**
   * To specify originating source of a transaction
   * @maxLength 255
   */
  transactionSource?: string | null;
  /**
   * Indicates the total amount of the transaction.
   * @min 0
   * @max 999999999.999
   */
  transactionAmount: number;
  /** Brink’s internal status. PSP status should be translated to Brinks status. */
  status:
    | "Incomplete"
    | "Adjusted"
    | "Cancelled"
    | "Rejected"
    | "CreditDeliveryConf"
    | "DebitTransmitted"
    | "VOIDED"
    | "Verified"
    | "SentToCredit"
    | "CreditDeliveryAck"
    | "Created"
    | "Received"
    | "CreditDeliveryErrorConf";
  /** ISO 4217 currency code (3 characters) */
  currency: CurrencyCodes;
  /**
   * In case of a smart safe, the username of logged in user to register the deposit
   * @maxLength 40
   */
  userName?: string | null;
  /**
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  expectedPickupDate?: string | null;
  /** List of declared value */
  declaredValues?: DeclaredRequest[];
}

/** The (unverified) amount of a seal bag as announced by the Merchant or by the device. In the Brink’s Complete offering the declared value is registered by the Merchant using the current 24Seven portal. The declared value table has one record per pre-announcement per denomination. */
export interface Declared {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * ID of the denomination (value of the individual currency) object
   * @format uuid
   */
  denominationId: string;
  /**
   * ID of the pre-announcement object
   * @format uuid
   */
  preAnnouncementId?: string | null;
  /**
   * ID of the deposit object
   * @format uuid
   */
  depositId?: string | null;
  /**
   * Delcared (pre-credited) amount
   * @min 0
   * @exclusiveMin true
   */
  declaredValue: number;
  /** ISO 4217 currency code (3 characters) */
  currency: CurrencyCodes;
  /**
   * Qty of a denomination
   * @min 1
   */
  denominationQuantity: number;
  /**
   * Datetime of declared value
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  declaredDateTime: string;
}

/** The (unverified) amount of a seal bag as announced by the Merchant or by the device. In the Brink’s Complete offering the declared value is registered by the Merchant using the current 24Seven portal. The declared value table has one record per pre-announcement per denomination. */
export interface DeclaredRequest {
  /**
   * ID of the denomination (value of the individual currency) object
   * @format uuid
   */
  denominationId: string;
  /**
   * ID of the pre-announcement object
   * @format uuid
   */
  preAnnouncementId?: string | null;
  /**
   * Delcared (pre-credited) amount
   * @min 0
   * @exclusiveMin true
   */
  declaredValue: number;
  /** ISO 4217 currency code (3 characters) */
  currency: CurrencyCodes;
  /**
   * Qty of a denomination
   * @min 1
   */
  denominationQuantity: number;
  /**
   * Datetime of declared value
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  declaredDateTime: string;
}

/** The verified contents of a vehicle such as a seal bag or a cassette, as counted in the Cash Centre. */
export interface Actual {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * ID of the sealbag object
   * @format uuid
   */
  sealbagId: string;
  /**
   * ID of the denomination (value of the individual currency) object
   * @format uuid
   */
  denominationId: string;
  /**
   * Quantity of a denomination
   * @min 1
   */
  denominationQty: number;
  /**
   * Datetime the actual is counted in the Cash Centre
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  countingDatetime: string;
  /**
   * Transaction registration number of the counting in the cash center
   * @min 0
   */
  registrationNumber: number;
  /**
   * (Deposit) Actual amount
   * @min 0
   * @max 9999999999999.99
   */
  actualValue: number;
  /** ISO 4217 currency code (3 characters) */
  currency: CurrencyCodes;
  /**
   * Number of fit counted bank notes
   * @min 0
   */
  fit: number;
  /**
   * Number of unfit counted bank notes
   * @min 0
   */
  unfit: number;
  /**
   * Number of rejected counted bank notes
   * @min 0
   */
  rejected: number;
  /**
   * Number of suspected counted bank notes
   * @min 0
   */
  suspected: number;
  /**
   * Owner of physical stock in the CashCenter after counting
   * @maxLength 40
   */
  stream?: string | null;
  /**
   * Money Processing Branch Id/Number
   * @maxLength 200
   */
  mpsBranchNumber?: string | null;
  /**
   * Money Processing Branch Name
   * @maxLength 500
   */
  mpsBranchName?: string | null;
  /**
   * The Local Brinks contract/relation number
   * @maxLength 40
   */
  cashRelation?: string | null;
  /** ISO 3166 country code (2 characters) */
  countryCode: CountryCodes;
  /**
   * Sequence number will auto increment and will give every posted actual a unique number
   * @min 1
   */
  sequenceNumber?: number;
}

/** The verified contents of a vehicle such as a seal bag or a cassette, as counted in the Cash Centre. */
export interface ActualRequestNoSealbagId {
  /**
   * ID of the denomination (value of the individual currency) object
   * @format uuid
   */
  denominationId: string;
  /**
   * Quantity of a denomination
   * @min 1
   */
  denominationQty: number;
  /**
   * Datetime the actual is counted in the Cash Centre
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  countingDatetime: string;
  /**
   * Transaction registration number of the counting in the cash center
   * @min 0
   */
  registrationNumber: number;
  /**
   * (Deposit) Actual amount
   * @min 0
   * @max 9999999999999.99
   */
  actualValue: number;
  /** ISO 4217 currency code (3 characters) */
  currency: CurrencyCodes;
  /**
   * Number of fit counted bank notes
   * @min 0
   */
  fit: number;
  /**
   * Number of unfit counted bank notes
   * @min 0
   */
  unfit: number;
  /**
   * Number of rejected counted bank notes
   * @min 0
   */
  rejected: number;
  /**
   * Number of suspected counted bank notes
   * @min 0
   */
  suspected: number;
  /**
   * Owner of physical stock in the CashCenter after counting
   * @maxLength 40
   */
  stream?: string | null;
  /**
   * Money Processing Branch Id/Number
   * @maxLength 200
   */
  mpsBranchNumber?: string | null;
  /**
   * Money Processing Branch Name
   * @maxLength 500
   */
  mpsBranchName?: string | null;
  /**
   * The Local Brinks contract/relation number
   * @maxLength 40
   */
  cashRelation?: string | null;
  /** ISO 3166 country code (2 characters) */
  countryCode: CountryCodes;
}

/** Holds list of actuals to add */
export interface SaveActualsRequest {
  /** List of actuals */
  actuals: ActualRequestNoSealbagId[];
}

/** Holds list of saved Actuals */
export type SaveActualsResponse = BrinksMultiStatusResponse & {
  /** List of saved Actuals */
  success: Actual[];
};

/** A bag where coins, bills and checks can be inserted. The closed bag can be deposited in a BBox. The BBox reads the QR code on the seal bag. A seal bag is also inserted into a BSafe, in which case all the deposits that are made through the automated teller drop into an open seal bag. The seal bag is then closed upon Collection. This type of seal bag is called a Liner Bag. A sealbag entity may also represent a Cassette. A (Smart) safe can contain closed sealbags with change, a liner bag, or one or multiple cassettes. */
export interface SealbagWithActuals {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * External Reference of sealbag object
   * @maxLength 36
   */
  externalReference?: string | null;
  /**
   * a sealbag hierarchy to allow sealbags to be related in a hierarchy by using a 'parent sealbag' field to link them together.
   * @format uuid
   */
  parentSealbagId?: string | null;
  /**
   * ID of the cash device
   * @format uuid
   */
  cashDeviceId?: string | null;
  /**
   * ID of the Collection (the process of picking up money from the Merchant’s store location and transporting it to the Cash Centre)
   * @format uuid
   */
  collectionId?: string | null;
  /**
   * barcode of the sealbag
   * @minLength 1
   * @maxLength 255
   */
  barcode: string;
  /**
   * Additional description of the type
   * @maxLength 255
   */
  description?: string | null;
  /**
   * The verified total amount of the contents of a vehicle such as a seal bag or a cassette, as counted in the Cash Centre.
   * @min 0
   */
  actualTotal?: number | null;
  /**
   * The declared total amount of the contents of a vehicle as a sealbag or a cassette
   * @min 0
   * @exclusiveMin true
   */
  declaredTotal?: number | null;
  /** List of actuals */
  actuals?: Actual[];
}

/** A bag where coins, bills and checks can be inserted. The closed bag can be deposited in a BBox. The BBox reads the QR code on the seal bag. A seal bag is also inserted into a BSafe, in which case all the deposits that are made through the automated teller drop into an open seal bag. The seal bag is then closed upon Collection. This type of seal bag is called a Liner Bag. A sealbag entity may also represent a Cassette. A (Smart) safe can contain closed sealbags with change, a liner bag, or one or multiple cassettes. */
export interface SealbagWithActualsAndDenominations {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * External Reference of sealbag object
   * @maxLength 36
   */
  externalReference?: string | null;
  /**
   * a sealbag hierarchy to allow sealbags to be related in a hierarchy by using a 'parent sealbag' field to link them together.
   * @format uuid
   */
  parentSealbagId?: string | null;
  /**
   * ID of the cash device
   * @format uuid
   */
  cashDeviceId?: string | null;
  /**
   * ID of the Collection (the process of picking up money from the Merchant’s store location and transporting it to the Cash Centre)
   * @format uuid
   */
  collectionId?: string | null;
  /**
   * barcode of the sealbag
   * @minLength 1
   * @maxLength 255
   */
  barcode: string;
  /**
   * Additional description of the type
   * @maxLength 255
   */
  description?: string | null;
  /**
   * The verified total amount of the contents of a vehicle such as a seal bag or a cassette, as counted in the Cash Centre.
   * @min 0
   */
  actualTotal?: number | null;
  /**
   * The declared total amount of the contents of a vehicle as a sealbag or a cassette
   * @min 0
   * @exclusiveMin true
   */
  declaredTotal?: number | null;
  /** List of actuals */
  actuals?: ActualWithDenomination[];
}

/** The verified contents of a vehicle such as a seal bag or a cassette, as counted in the Cash Centre. */
export interface ActualWithDenomination {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * ID of the sealbag object
   * @format uuid
   */
  sealbagId: string;
  /**
   * ID of the denomination (value of the individual currency) object
   * @format uuid
   */
  denominationId: string;
  /**
   * Quantity of a denomination
   * @min 1
   */
  denominationQty: number;
  /**
   * Datetime the actual is counted in the Cash Centre
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  countingDatetime: string;
  /**
   * Transaction registration number of the counting in the cash center
   * @min 0
   */
  registrationNumber: number;
  /**
   * (Deposit) Actual amount
   * @min 0
   * @max 9999999999999.99
   */
  actualValue: number;
  /** ISO 4217 currency code (3 characters) */
  currency: CurrencyCodes;
  /**
   * Number of fit counted bank notes
   * @min 0
   */
  fit: number;
  /**
   * Number of unfit counted bank notes
   * @min 0
   */
  unfit: number;
  /**
   * Number of rejected counted bank notes
   * @min 0
   */
  rejected: number;
  /**
   * Number of suspected counted bank notes
   * @min 0
   */
  suspected: number;
  /**
   * Owner of physical stock in the CashCenter after counting
   * @maxLength 40
   */
  stream?: string | null;
  /**
   * Money Processing Branch Id/Number
   * @maxLength 200
   */
  mpsBranchNumber?: string | null;
  /**
   * Money Processing Branch Name
   * @maxLength 500
   */
  mpsBranchName?: string | null;
  /**
   * The Local Brinks contract/relation number
   * @maxLength 40
   */
  cashRelation?: string | null;
  /** ISO 3166 country code (2 characters) */
  countryCode: CountryCodes;
  /**
   * Sequence number will auto increment and will give every posted actual a unique number
   * @min 1
   */
  sequenceNumber?: number;
  /** Denomination is a proper description of a currency amount, usually for coins or banknotes. */
  denomination?: Denomination;
}

/** Servicing Request */
export interface ServicingRequest {
  /**
   * device identifier for external partners
   * @minLength 5
   * @maxLength 40
   */
  countryDeviceId: string;
  /**
   * barcode of sealbag
   * @minLength 1
   * @maxLength 255
   */
  barcode: string;
  /**
   * sealbag identifier for external partners
   * @format uuid
   */
  partnerSealbagId?: string;
}

/** Holds list of users to return */
export interface GetUsersResponse {
  /** List of users */
  users: User[];
}

/** Holds list of users to add */
export interface SaveUsersRequest {
  /** List of users */
  users: User[];
}

/** User of blubeem portal */
export interface User {
  /** user type */
  type: "SUPER_USER" | "STANDARD_USER";
  /**
   * user id in Okta / b2c user id
   * @maxLength 255
   */
  userId?: string | null;
  /**
   * user first name
   * @minLength 1
   * @maxLength 255
   */
  firstName: string;
  /**
   * user last name
   * @minLength 1
   * @maxLength 255
   */
  lastName: string;
  /**
   * user email address
   * @format email
   */
  email: string;
  /**
   * Mobile phone number
   * @pattern ^\+\d{6,15}$
   */
  phone?: string;
  /**
   * Primary phone number
   * @pattern ^\+\d{6,15}$
   */
  primaryPhone?: string;
  /** user status */
  status?:
    | "PROVISIONED"
    | "ACTIVE"
    | "RECOVERY"
    | "SUSPENDED"
    | "LOCKED_OUT"
    | "PASSWORD_EXPIRED"
    | "DEPROVISIONED"
    | "UNKNOWN"
    | "INACTIVE"
    | null;
  /** List of locations that user has access to */
  locations?: string[] | null;
}

/** Holds the userId and password for a given user */
export interface PasswordResetRequest {
  /** The B2C userId required to sent a password Reset Request */
  userId: string;
}

/** Request body for sending Custom Verify OTP e-mail */
export interface SendVerifyOtpEmailRequest {
  /** Application Type of the */
  bacApplicationType: string;
  /**
   * user email address
   * @format email
   */
  email: string;
  /** Mobile phone number */
  otp: string;
}

/** Holds list of orders to return */
export interface GetOrdersResponse {
  /** List of orders */
  orders: Order[];
}

/** Generic term for products and services that a Merchant may request from Brink’s. Can be an order for Cash, Collection and Transport, Consumables, Digital Payment Order, consisting of multiple transactions. */
export interface OrderWithBusinessRules {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * External Salesforce reference of the (change) order
   * @maxLength 36
   */
  externalReferenceSf?: string | null;
  /**
   * The ID of store_location object
   * @format uuid
   */
  storeId: string;
  /**
   * ID of the contract object
   * @format uuid
   */
  contractId?: string | null;
  /**
   * The date and time the order was placed
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  orderDateTime: string;
  /**
   * Order number assigned to this order (not the unique, system-generated ID assigned during creation).
   * @maxLength 40
   */
  orderNumber?: string | null;
  /**
   * The original order that a change order was created from.
   * @maxLength 36
   */
  originalOrderId?: string | null;
  /**
   * Order description.
   * @maxLength 255
   */
  orderName?: string | null;
  status:
    | "NEW"
    | "DELIVERED"
    | "CANCELLED"
    | "PICKED"
    | "SENT_TO_FULFILLMENT"
    | "SHIPPED"
    | "RECEIVED"
    | "IN_PROGRESS"
    | "REJECTED";
  /**
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  deliveryDateTime?: string | null;
  /** Customer can choose between Flexible and Fixed Delivery. */
  isFixedDelivery?: boolean | null;
  /** @maxLength 40 */
  paymentMethod?: string | null;
  /** Only required for Change Order */
  totalAmount?: number | null;
  /** ISO 4217 currency code (3 characters) */
  currency?: CurrencyCodes | null;
  frequency?: "ONCE" | "EVERY_1_WEEK" | "EVERY_2_WEEKS" | "EVERY_4_WEEKS" | null;
  /**
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  endDate?: string | null;
  /** List of order items belonging to the order */
  items?: OrderItem[];
  /**
   * Sequence number will auto increment and will give every order a unique number
   * @min 1
   */
  sequenceNumber?: number;
  /**
   * The ODS ID of the merchant
   * @format uuid
   */
  merchantId: string;
  /**
   * Full name of the user who prepared the order
   * @maxLength 255
   */
  preparedBy?: string | null;
  /**
   * Store location name
   * @maxLength 80
   */
  storeName?: string | null;
  /**
   * Store location code
   * @maxLength 20
   */
  storeLocationCode?: string | null;
  /**
   * customer order reference
   * @maxLength 50
   */
  customerReference?: string | null;
  /** Merchant related to the order */
  merchant?: Merchant | null;
  /** Service related to the order */
  service?: Service | null;
  /** Business rules related to the order */
  businessRules?: BusinessRuleLocationServicesMoneyOrder | null;
}

/** Holds list of orders to return */
export interface GetOrdersResponseWithBusinessRules {
  /** List of orders */
  orders: OrderWithBusinessRules[];
}

/** Holds list of orders to return with merchant information */
export interface GetOrderDetailsResponseWithMerchantAndBusRules {
  /** List of orders with merchant information */
  orders: OrderDetailsWithMerchant[];
}

/** Generic term for products and services that a Merchant may request from Brink’s. Can be an order for Cash, Collection and Transport, Consumables, Digital Payment Order, consisting of multiple transactions. */
export interface OrderDetailsWithMerchantAndBusRules {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * External Salesforce reference of the (change) order
   * @maxLength 36
   */
  externalReferenceSf?: string | null;
  /**
   * The ID of store_location object
   * @format uuid
   */
  storeId: string;
  /**
   * ID of the contract object
   * @format uuid
   */
  contractId?: string | null;
  /**
   * The date and time the order was placed
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  orderDateTime: string;
  /**
   * Order number assigned to this order (not the unique, system-generated ID assigned during creation).
   * @maxLength 40
   */
  orderNumber?: string | null;
  /**
   * The original order that a change order was created from.
   * @maxLength 36
   */
  originalOrderId?: string | null;
  /**
   * Order description.
   * @maxLength 255
   */
  orderName?: string | null;
  status:
    | "NEW"
    | "DELIVERED"
    | "CANCELLED"
    | "PICKED"
    | "SENT_TO_FULFILLMENT"
    | "SHIPPED"
    | "RECEIVED"
    | "IN_PROGRESS"
    | "REJECTED";
  /**
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  deliveryDateTime?: string | null;
  /** Customer can choose between Flexible and Fixed Delivery. */
  isFixedDelivery?: boolean | null;
  /** @maxLength 40 */
  paymentMethod?: string | null;
  /** Only required for Change Order */
  totalAmount?: number | null;
  /**
   * The ID of the service object.
   * @format uuid
   */
  serviceId?: string;
  /** Service configuration related to the order */
  service?: Service | null;
  /** ISO 4217 currency code (3 characters) */
  currency?: CurrencyCodes | null;
  frequency?: "ONCE" | "EVERY_1_WEEK" | "EVERY_2_WEEKS" | "EVERY_4_WEEKS" | null;
  /**
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  endDate?: string | null;
  /** List of order items belonging to the order */
  items?: OrderItemDetails[];
  /**
   * Sequence number will auto increment and will give every order a unique number
   * @min 1
   */
  sequenceNumber?: number;
  /**
   * The ODS ID of the merchant
   * @format uuid
   */
  merchantId: string;
  /**
   * Full name of the user who prepared the order
   * @maxLength 255
   */
  preparedBy?: string | null;
  /**
   * Store location name
   * @maxLength 80
   */
  storeName?: string | null;
  /**
   * Store location code
   * @maxLength 20
   */
  storeLocationCode?: string | null;
  /**
   * customer order reference
   * @maxLength 50
   */
  customerReference?: string | null;
  /** Business rules related to the order */
  businessRules?: BusinessRuleLocationServicesMoneyOrder | null;
  /** Brink’s customer, often the owner of a shop. The term Merchant is preferred over Customer. */
  merchant?: MerchantWithDependentsAndLocations;
}

/** Generic term for products and services that a Merchant may request from Brink’s. Can be an order for Cash, Collection and Transport, Consumables, Digital Payment Order, consisting of multiple transactions. */
export interface Order {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * External Salesforce reference of the (change) order
   * @maxLength 36
   */
  externalReferenceSf?: string | null;
  /**
   * The ID of store_location object
   * @format uuid
   */
  storeId: string;
  /**
   * ID of the contract object
   * @format uuid
   */
  contractId?: string | null;
  /**
   * The date and time the order was placed
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  orderDateTime: string;
  /**
   * Order number assigned to this order (not the unique, system-generated ID assigned during creation).
   * @maxLength 40
   */
  orderNumber?: string | null;
  /**
   * The original order that a change order was created from.
   * @maxLength 36
   */
  originalOrderId?: string | null;
  /**
   * Order description.
   * @maxLength 255
   */
  orderName?: string | null;
  status:
    | "NEW"
    | "DELIVERED"
    | "CANCELLED"
    | "PICKED"
    | "SENT_TO_FULFILLMENT"
    | "SHIPPED"
    | "RECEIVED"
    | "IN_PROGRESS"
    | "REJECTED";
  /**
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  deliveryDateTime?: string | null;
  /** Customer can choose between Flexible and Fixed Delivery. */
  isFixedDelivery?: boolean | null;
  /** @maxLength 40 */
  paymentMethod?: string | null;
  /** Only required for Change Order */
  totalAmount?: number | null;
  /** ISO 4217 currency code (3 characters) */
  currency?: CurrencyCodes | null;
  frequency?: "ONCE" | "EVERY_1_WEEK" | "EVERY_2_WEEKS" | "EVERY_4_WEEKS" | null;
  /**
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  endDate?: string | null;
  /** List of order items belonging to the order */
  items?: OrderItem[];
  /**
   * Sequence number will auto increment and will give every order a unique number
   * @min 1
   */
  sequenceNumber?: number;
  /**
   * The ODS ID of the merchant
   * @format uuid
   */
  merchantId: string;
  /**
   * Full name of the user who prepared the order
   * @maxLength 255
   */
  preparedBy?: string | null;
  /**
   * Store location name
   * @maxLength 80
   */
  storeName?: string | null;
  /**
   * Store location code
   * @maxLength 20
   */
  storeLocationCode?: string | null;
  /**
   * customer order reference
   * @maxLength 50
   */
  customerReference?: string | null;
  /** Merchant related to the order */
  merchant?: Merchant | null;
  /** Service related to the order */
  service?: Service | null;
}

/** Order item */
export interface OrderItem {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * ID of the order  object
   * @format uuid
   */
  orderId?: string;
  /**
   * ID of the sku (stock keeping unit) object
   * @format uuid
   */
  skuId: string;
  /** Describes what the order item represents. */
  type: "DELIVERY_CHARGE" | "ORDER_PRODUCT";
  /**
   * @minLength 1
   * @maxLength 40
   */
  status: string;
  /**
   * Text description of the order line item. Can differ from the SKU description.
   * @minLength 1
   * @maxLength 80
   */
  description: string;
  /**
   * The increment of a SKU that a Store Location can order.
   * @min 1
   * @max 1000
   */
  increments?: number;
  /**
   * Number of the units of this order product
   * @min 0
   */
  quantity: number;
  /**
   * Unit_price * Quantity.
   * @min 0
   */
  totalPrice: number;
}

/** Generic term for products and services that a Merchant may request from Brink’s. Can be an order for Cash, Collection and Transport, Consumables, Digital Payment Order, consisting of multiple transactions. */
export interface OrderStatus {
  status:
    | "NEW"
    | "DELIVERED"
    | "CANCELLED"
    | "PICKED"
    | "SENT_TO_FULFILLMENT"
    | "SHIPPED"
    | "RECEIVED"
    | "IN_PROGRESS"
    | "REJECTED";
}

/** Holds list of orders to return */
export interface GetOrderDetailsResponse {
  /** List of orders */
  orders: OrderDetails[];
}

/** Generic term for products and services that a Merchant may request from Brink’s. Can be an order for Cash, Collection and Transport, Consumables, Digital Payment Order, consisting of multiple transactions. */
export interface OrderDetails {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * External Salesforce reference of the (change) order
   * @maxLength 36
   */
  externalReferenceSf?: string | null;
  /**
   * The ID of store_location object
   * @format uuid
   */
  storeId: string;
  /**
   * ID of the contract object
   * @format uuid
   */
  contractId?: string | null;
  /**
   * The date and time the order was placed
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  orderDateTime: string;
  /**
   * Order number assigned to this order (not the unique, system-generated ID assigned during creation).
   * @maxLength 40
   */
  orderNumber?: string | null;
  /**
   * The original order that a change order was created from.
   * @maxLength 36
   */
  originalOrderId?: string | null;
  /**
   * Order description.
   * @maxLength 255
   */
  orderName?: string | null;
  status:
    | "NEW"
    | "DELIVERED"
    | "CANCELLED"
    | "PICKED"
    | "SENT_TO_FULFILLMENT"
    | "SHIPPED"
    | "RECEIVED"
    | "IN_PROGRESS"
    | "REJECTED";
  /**
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  deliveryDateTime?: string | null;
  /** Customer can choose between Flexible and Fixed Delivery. */
  isFixedDelivery?: boolean | null;
  /** @maxLength 40 */
  paymentMethod?: string | null;
  /** Only required for Change Order */
  totalAmount?: number | null;
  /** ISO 4217 currency code (3 characters) */
  currency?: CurrencyCodes | null;
  frequency?: "ONCE" | "EVERY_1_WEEK" | "EVERY_2_WEEKS" | "EVERY_4_WEEKS" | null;
  /**
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  endDate?: string | null;
  /** List of order items belonging to the order */
  items?: OrderItemDetails[];
  /**
   * Sequence number will auto increment and will give every order a unique number
   * @min 1
   */
  sequenceNumber?: number;
  /**
   * The ODS ID of the merchant
   * @format uuid
   */
  merchantId: string;
  /**
   * Full name of the user who prepared the order
   * @maxLength 255
   */
  preparedBy?: string | null;
  /**
   * Store location name
   * @maxLength 80
   */
  storeName?: string | null;
  /**
   * Store location code
   * @maxLength 20
   */
  storeLocationCode?: string | null;
  /**
   * customer order reference
   * @maxLength 50
   */
  customerReference?: string | null;
}

/** Order item */
export interface OrderItemDetails {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * ID of the order  object
   * @format uuid
   */
  orderId?: string;
  /**
   * ID of the sku (stock keeping unit) object
   * @format uuid
   */
  skuId: string;
  /** Denomination is a proper description of a currency amount, usually for coins or banknotes. */
  denomination?: Denomination;
  /** Describes what the order item represents. */
  type: "DELIVERY_CHARGE" | "ORDER_PRODUCT";
  /**
   * @minLength 1
   * @maxLength 40
   */
  status: string;
  /**
   * Text description of the order line item. Can differ from the SKU description.
   * @minLength 1
   * @maxLength 80
   */
  description: string;
  /**
   * The increment of a SKU that a Store Location can order.
   * @min 1
   * @max 1000
   */
  increments?: number | null;
  /**
   * Number of the units of this order product
   * @min 0
   */
  quantity: number;
  /**
   * Unit_price * Quantity.
   * @min 0
   */
  totalPrice: number;
}

/** Holds list of orders to save */
export interface SaveOrdersRequest {
  /** List of orders */
  orders: OrderRequest[];
}

/** Generic term for products and services that a Merchant may request from Brink’s. Can be an order for Cash, Collection and Transport, Consumables, Digital Payment Order, consisting of multiple transactions. */
export interface OrderRequest {
  /**
   * External Salesforce reference of the (change) order
   * @maxLength 36
   */
  externalReferenceSf?: string | null;
  /**
   * The ID of store_location object
   * @format uuid
   */
  storeId: string;
  /**
   * ID of the contract object
   * @format uuid
   */
  contractId?: string | null;
  /**
   * The date and time the order was placed
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  orderDateTime: string;
  /**
   * Order number assigned to this order (not the unique, system-generated ID assigned during creation).
   * @maxLength 40
   */
  orderNumber?: string | null;
  /**
   * The original order that a change order was created from.
   * @maxLength 36
   */
  originalOrderId?: string | null;
  /**
   * Order description.
   * @maxLength 255
   */
  orderName?: string | null;
  status:
    | "NEW"
    | "DELIVERED"
    | "CANCELLED"
    | "PICKED"
    | "SENT_TO_FULFILLMENT"
    | "SHIPPED"
    | "RECEIVED"
    | "IN_PROGRESS"
    | "REJECTED";
  /**
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  deliveryDateTime?: string | null;
  /** Customer can choose between Flexible and Fixed Delivery. */
  isFixedDelivery?: boolean | null;
  /** @maxLength 40 */
  paymentMethod?: string | null;
  /** Only required for Change Order */
  totalAmount?: number | null;
  /** ISO 4217 currency code (3 characters) */
  currency?: CurrencyCodes | null;
  frequency?: "ONCE" | "EVERY_1_WEEK" | "EVERY_2_WEEKS" | "EVERY_4_WEEKS" | null;
  /**
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  endDate?: string | null;
  /** List of order items belonging to the order */
  items?: OrderItem[];
  /**
   * Full name of the user who prepared the order
   * @maxLength 255
   */
  preparedBy?: string | null;
  /**
   * Store location name
   * @maxLength 80
   */
  storeName?: string | null;
  /**
   * Store location code
   * @maxLength 20
   */
  storeLocationCode?: string | null;
  /**
   * customer order reference
   * @maxLength 50
   */
  customerReference?: string | null;
}

/** Holds list of saved Orders */
export type SaveOrdersResponse = BrinksMultiStatusResponse & {
  /** List of saved Orders */
  success: Order[];
};

/** Holds list of collections to return */
export interface GetCollectionsResponse {
  /** List of collections */
  collections: Collection[];
}

/** The process of picking up money from the Merchant’s Location and transporting it to the Cash Centre */
export interface Collection {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * The ID of the store_location object
   * @format uuid
   */
  storeId: string;
  /**
   * The ID of the order object
   * @format uuid
   */
  orderId?: string | null;
  /**
   * ID of the cash_device object
   * @format uuid
   */
  cashDeviceId?: string | null;
  /**
   * Due Date of the Collection.
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  collectionDate?: string | null;
}

/** The process of picking up money from the Merchant’s Location and transporting it to the Cash Centre */
export interface CollectionOrder {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * The ID of the store_location object
   * @format uuid
   */
  storeId: string;
  /** Generic term for products and services that a Merchant may request from Brink’s. Can be an order for Cash, Collection and Transport, Consumables, Digital Payment Order, consisting of multiple transactions. */
  order: OrderRequest;
  /**
   * ID of the cash_device object
   * @format uuid
   */
  cashDeviceId?: string | null;
  /**
   * Due Date of the Collection.
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  collectionDate?: string | null;
}

/** Holds list of collections to save */
export interface SaveCollectionsRequest {
  /** List of collections */
  collections: CollectionOrder[];
}

/** Holds list of collections to return */
export type SaveCollectionsResponse = SaveResponse & {
  /** List of collections */
  collections: Collection[];
  errors?: {
    /** The process of picking up money from the Merchant’s Location and transporting it to the Cash Centre */
    record?: Collection;
  }[];
};

/** Holds list of orders to return */
export interface GetOrdersResponseForTalend {
  /** List of orders */
  orders: OrderForTalend[];
}

/** Generic term for products and services that a Merchant may request from Brink’s. Can be an order for Cash, Collection and Transport, Consumables, Digital Payment Order, consisting of multiple transactions. */
export interface OrderForTalend {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * External Salesforce reference of the (change) order
   * @maxLength 36
   */
  externalReferenceSf?: string | null;
  /**
   * The ID of store_location object
   * @format uuid
   */
  storeId: string;
  /**
   * ID of the contract object
   * @format uuid
   */
  contractId?: string | null;
  /**
   * The date and time the order was placed
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  orderDateTime: string;
  /**
   * Order number assigned to this order (not the unique, system-generated ID assigned during creation).
   * @maxLength 40
   */
  orderNumber?: string | null;
  /**
   * The original order that a change order was created from.
   * @maxLength 36
   */
  originalOrderId?: string | null;
  /**
   * Order description.
   * @maxLength 255
   */
  orderName?: string | null;
  type: "TRANSPORT" | "CHANGE";
  status:
    | "NEW"
    | "DELIVERED"
    | "CANCELLED"
    | "PICKED"
    | "SENT_TO_FULFILLMENT"
    | "SHIPPED"
    | "RECEIVED"
    | "IN_PROGRESS"
    | "REJECTED";
  /**
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  deliveryDateTime?: string | null;
  /** Customer can choose between Flexible and Fixed Delivery. */
  isFixedDelivery?: boolean | null;
  /** @maxLength 40 */
  paymentMethod?: string | null;
  /** Only required for Change Order */
  totalAmount?: number | null;
  /** ISO 4217 currency code (3 characters) */
  currency?: CurrencyCodes | null;
  frequency?: "ONCE" | "EVERY_1_WEEK" | "EVERY_2_WEEKS" | "EVERY_4_WEEKS" | null;
  /**
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  endDate?: string | null;
  /** List of order items belonging to the order */
  items?: OrderItemForTalend[];
  /**
   * Sequence number will auto increment and will give every order a unique number
   * @min 1
   */
  sequenceNumber?: number;
  /**
   * The ODS ID of the merchant
   * @format uuid
   */
  merchantId: string;
  /**
   * Full name of the user who prepared the order
   * @maxLength 255
   */
  preparedBy?: string | null;
  /**
   * Store location name
   * @maxLength 80
   */
  storeName?: string | null;
  /**
   * Store location code
   * @maxLength 20
   */
  storeLocationCode?: string | null;
  /** Merchant related to the order */
  merchant?: Merchant | null;
  /**
   * customer reference
   * @maxLength 50
   */
  customerReference?: string | null;
}

/** Order item */
export interface OrderItemForTalend {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
  /**
   * ID of the order  object
   * @format uuid
   */
  orderId?: string;
  /**
   * ID of the sku (stock keeping unit) object
   * @format uuid
   */
  skuId: string;
  /** Describes what the order item represents. */
  type: "DELIVERY_CHARGE" | "ORDER_PRODUCT";
  /**
   * @minLength 1
   * @maxLength 40
   */
  status: string;
  /**
   * Text description of the order line item. Can differ from the SKU description.
   * @minLength 1
   * @maxLength 80
   */
  description: string;
  /**
   * Number of the units of this order product
   * @min 0
   */
  quantity: number;
  /**
   * Unit price of the product.
   * @min 0
   */
  unitPrice: number;
  /**
   * Unit_price * Quantity.
   * @min 0
   */
  totalPrice: number;
  /** ISO 4217 currency code (3 characters) */
  currency: CurrencyCodes;
}

/** Holds list of cases to add */
export interface SaveTalendCasesRequest {
  /** List of cases */
  cases: SaveTalendCaseRequest[];
}

/** Case opened for a merchant on Salesforce */
export interface SaveTalendCaseRequest {
  /**
   * System generated case number
   * @minLength 1
   * @maxLength 8
   */
  caseNumber: string;
  /**
   * Name of the merchant location (Location_Name__c field from Sf)
   * @minLength 1
   * @maxLength 255
   */
  locationName: string;
  /**
   * Case subject
   * @minLength 1
   * @maxLength 255
   */
  subject: string;
  /**
   * Case description
   * @minLength 1
   * @maxLength 32000
   */
  description: string;
  /** Case status */
  status:
    | "New"
    | "In progress"
    | "Waiting"
    | "Correction in process"
    | "On Hold"
    | "Escalated"
    | "Closed"
    | "Closed - Merged"
    | "Closed - Rejected"
    | "Closed - Approved"
    | "Closed - After reminders";
  /**
   * Case creation date
   * @minLength 1
   * @maxLength 32
   * @example "2022-12-27T15:56:55.000+0000"
   */
  createdDate: string;
  /**
   * Case last modified date
   * @minLength 1
   * @maxLength 32
   * @example "2022-12-27T15:56:55.000+0000"
   */
  lastModifiedDate: string;
  /**
   * Name of the Salesforce account user who created the case (Created_By_Name__c field from Sf)
   * @minLength 1
   * @maxLength 255
   */
  createdByName: string;
  /**
   * Salesforce Id of the merchant location (Location__c field from Sf)
   * @minLength 1
   * @maxLength 18
   * @example "0015500000WO1ZiAAL"
   */
  locationSalesforceId: string;
  /**
   * Salesforce Id of the case (Id field from Sf)
   * @minLength 1
   * @maxLength 18
   * @example "0015500000WO1ZiAAL"
   */
  caseSalesforceId: string;
  /** ISO 3166 country code (2 characters) */
  countryCode: CountryCodes;
  /** List of case files */
  files?: CaseFile[];
}

/** Holds list of saved Cases */
export type SaveCasesResponse = BrinksMultiStatusResponse & {
  /** List of saved Cases */
  success: Case[];
};

/** Holds list of cases to return */
export interface GetCasesResponse {
  /** List of cases */
  cases: Case[];
}

/** Holds list of cases to return */
export interface GetCasesWithMerchantResponse {
  /** List of cases with merchant information */
  cases: CaseWithMerchant[];
}

/** Case details to add */
export interface SaveCaseRequest {
  case: {
    /**
     * subject of the case
     * @minLength 1
     * @maxLength 255
     */
    subject: string;
    /**
     * description of the case
     * @minLength 1
     * @maxLength 32000
     */
    description: string;
    /**
     * ODS merchant ID
     * @format uuid
     */
    merchantId: string;
    /**
     * ODS location ID
     * @format uuid
     */
    locationId: string;
    /**
     * externalReferenceSf field from the store location
     * @minLength 1
     * @maxLength 18
     * @example "0015500000WO1ZiAAL"
     */
    locationSalesforceId: string;
    /** List of files to attach to a case */
    files: SaveFileRequest[];
  };
  userInfo?: {
    /**
     * User first name
     * @minLength 1
     * @maxLength 255
     */
    firstname: string;
    /**
     * User last name
     * @minLength 1
     * @maxLength 255
     */
    lastname: string;
    /**
     * User mobile phone number
     * @pattern ^\+\d{6,15}$
     */
    phone: string;
    /**
     * User email address
     * @format email
     */
    email: string;
    /**
     * User ID
     * @format uuid
     */
    externalid: string;
  };
}

/** File attached to a case, post or comment */
export interface SaveFileRequest {
  /**
   * File name
   * @minLength 1
   * @maxLength 255
   */
  fileName: string;
  /**
   * Base 64 encoded file data
   * @minLength 1
   */
  fileContent: string;
}

/** Save case response */
export interface SaveCaseResponse {
  /**
   * System generated case number
   * @minLength 1
   * @maxLength 8
   */
  caseNumber: string;
}

/** Case opened for a merchant on Salesforce */
export interface Case {
  /**
   * System generated case number
   * @minLength 1
   * @maxLength 8
   */
  caseNumber: string;
  /**
   * Name of the merchant location (Location_Name__c field from Sf)
   * @minLength 1
   * @maxLength 255
   */
  locationName: string;
  /**
   * Case subject
   * @minLength 1
   * @maxLength 255
   */
  subject: string;
  /**
   * Case description
   * @minLength 1
   * @maxLength 32000
   */
  description: string;
  /** Case status */
  status:
    | "New"
    | "In progress"
    | "Waiting"
    | "Correction in process"
    | "On Hold"
    | "Escalated"
    | "Closed"
    | "Closed - Merged"
    | "Closed - Rejected"
    | "Closed - Approved"
    | "Closed - After reminders";
  /**
   * Case creation date
   * @minLength 1
   * @maxLength 32
   * @example "2022-12-27T15:56:55.000+0000"
   */
  createdDate: string;
  /**
   * Case last modified date
   * @minLength 1
   * @maxLength 32
   * @example "2022-12-27T15:56:55.000+0000"
   */
  lastModifiedDate: string;
  /**
   * Name of the Salesforce account user who created the case (Created_By_Name__c field from Sf)
   * @minLength 1
   * @maxLength 255
   */
  createdByName: string;
  /**
   * Salesforce Id of the merchant location (Location__c field from Sf)
   * @minLength 1
   * @maxLength 18
   * @example "0015500000WO1ZiAAL"
   */
  locationSalesforceId: string;
  /**
   * Salesforce Id of the case (Id field from Sf)
   * @minLength 1
   * @maxLength 18
   * @example "0015500000WO1ZiAAL"
   */
  caseSalesforceId: string;
  /** ISO 3166 country code (2 characters) */
  countryCode: CountryCodes;
  /** List of case files */
  files: CaseFile[];
  /**
   * ODS merchant ID
   * @format uuid
   */
  merchantId: string;
  /** Merchant related to the order */
  merchant?: Merchant | null;
  /**
   * ODS location ID
   * @format uuid
   */
  locationId: string;
  /** Merchant related to the order */
  location?: StoreLocation | null;
  /**
   * First name of the user who created the case
   * @minLength 1
   * @maxLength 255
   */
  firstname?: string;
  /**
   * Last name of the user who created the case
   * @minLength 1
   * @maxLength 255
   */
  lastname?: string;
  /**
   * Phone of the user who created the case
   * @minLength 1
   * @maxLength 255
   */
  phone?: string;
  /**
   * Email of the user who created the case
   * @format email
   * @minLength 1
   * @maxLength 255
   */
  email?: string;
  /**
   * ODS user id of the user who created the case (filled only when the case is created from ODS side)
   * @maxLength 255
   */
  externalid?: string | null;
  /** Flag to indicate if the case was created by ODS and not Salesforce */
  createdByOds?: boolean;
}

/** Case opened for a merchant on Salesforce */
export interface CaseWithMerchant {
  /**
   * System generated case number
   * @minLength 1
   * @maxLength 8
   */
  caseNumber: string;
  /**
   * Name of the merchant location (Location_Name__c field from Sf)
   * @minLength 1
   * @maxLength 255
   */
  locationName: string;
  /**
   * Case subject
   * @minLength 1
   * @maxLength 255
   */
  subject: string;
  /**
   * Case description
   * @minLength 1
   * @maxLength 32000
   */
  description: string;
  /** Case status */
  status:
    | "New"
    | "In progress"
    | "Waiting"
    | "Correction in process"
    | "On Hold"
    | "Escalated"
    | "Closed"
    | "Closed - Merged"
    | "Closed - Rejected"
    | "Closed - Approved"
    | "Closed - After reminders";
  /**
   * Case creation date
   * @minLength 1
   * @maxLength 32
   * @example "2022-12-27T15:56:55.000+0000"
   */
  createdDate: string;
  /**
   * Case last modified date
   * @minLength 1
   * @maxLength 32
   * @example "2022-12-27T15:56:55.000+0000"
   */
  lastModifiedDate: string;
  /**
   * Name of the Salesforce account user who created the case (Created_By_Name__c field from Sf)
   * @minLength 1
   * @maxLength 255
   */
  createdByName: string;
  /**
   * Salesforce Id of the merchant location (Location__c field from Sf)
   * @minLength 1
   * @maxLength 18
   * @example "0015500000WO1ZiAAL"
   */
  locationSalesforceId: string;
  /**
   * Salesforce Id of the case (Id field from Sf)
   * @minLength 1
   * @maxLength 18
   * @example "0015500000WO1ZiAAL"
   */
  caseSalesforceId: string;
  /** ISO 3166 country code (2 characters) */
  countryCode: CountryCodes;
  /** List of case files */
  files: CaseFile[];
  /**
   * ODS merchant ID
   * @format uuid
   */
  merchantId: string;
  /**
   * ODS location ID
   * @format uuid
   */
  locationId: string;
  /**
   * First name of the user who created the case
   * @minLength 1
   * @maxLength 255
   */
  firstname?: string;
  /**
   * Last name of the user who created the case
   * @minLength 1
   * @maxLength 255
   */
  lastname?: string;
  /**
   * Phone of the user who created the case
   * @minLength 1
   * @maxLength 255
   */
  phone?: string;
  /**
   * Email of the user who created the case
   * @format email
   * @minLength 1
   * @maxLength 255
   */
  email?: string;
  /**
   * ODS user id of the user who created the case (filled only when the case is created from ODS side)
   * @maxLength 255
   */
  externalid?: string | null;
  /** Flag to indicate if the case was created by ODS and not Salesforce */
  createdByOds?: boolean;
  /** Brink’s customer, often the owner of a shop. The term Merchant is preferred over Customer. */
  merchant?: MerchantWithDependentsAndLocations;
}

/** File uploaded with a case, post or comment */
export interface CaseFile {
  /**
   * Public url for downloading the file
   * @minLength 1
   * @maxLength 1024
   * @example "https://brinksdomain.com/fileinfo"
   */
  contentDownloadUrl: string;
  /**
   * Name of the uploaded file
   * @minLength 1
   * @maxLength 1024
   */
  name: string;
  /**
   * Salesforce Id of the file
   * @minLength 1
   * @maxLength 18
   * @example "0015500000WO1ZiAAL"
   */
  fileSalesforceId: string;
}

/** Holds list of posts to save */
export interface SavePostsRequest {
  /** List of posts */
  posts: SavePostRequest[];
}

/** Post created for a case */
export interface SavePostRequest {
  /**
   * Salesforce Id of the user that created the post
   * @minLength 1
   * @maxLength 18
   */
  userSalesforceId: string;
  /**
   * Display name of the user that created the post
   * @minLength 1
   * @maxLength 255
   */
  userDisplayName: string;
  /**
   * The post text
   * @minLength 1
   * @maxLength 131072
   */
  text: string;
  /**
   * Post creation date
   * @minLength 1
   * @maxLength 32
   */
  createdDate: string;
  /**
   * Salesforce Id of the post
   * @minLength 1
   * @maxLength 18
   */
  postSalesforceId: string;
  /**
   * Last date the post was modified
   * @minLength 1
   * @maxLength 32
   */
  modifiedDate: string;
  /** List of post files */
  files?: CaseFile[];
}

/** Holds list of saved Posts */
export type SavePostsResponse = BrinksMultiStatusResponse & {
  /** List of saved Posts */
  success: Post[];
};

/** Post created for a case */
export interface SaveExperiencePostRequest {
  /**
   * The post text
   * @minLength 1
   * @maxLength 131072
   */
  text: string;
  /** List of files to attach to a post */
  files: SaveFileRequest[];
}

/** Holds list of posts to return */
export interface GetPostsResponse {
  /** List of posts */
  posts: Post[];
}

/** Post created for a case */
export interface Post {
  /**
   * Salesforce Id of the user that created the post
   * @minLength 1
   * @maxLength 18
   */
  userSalesforceId: string;
  /**
   * Display name of the user that created the post
   * @minLength 1
   * @maxLength 255
   */
  userDisplayName: string;
  /**
   * The post text
   * @minLength 1
   * @maxLength 131072
   */
  text: string;
  /**
   * Post creation date
   * @minLength 1
   * @maxLength 28
   */
  createdDate: string;
  /**
   * Salesforce Id of the post
   * @minLength 1
   * @maxLength 18
   */
  postSalesforceId: string;
  /**
   * Last date the post was modified
   * @minLength 1
   * @maxLength 28
   */
  modifiedDate: string;
  /**
   * Salesforce Id of the case the post belongs to
   * @minLength 1
   * @maxLength 18
   */
  caseSalesforceId: string;
  /** List of post files */
  files: CaseFile[];
  /** Flag to indicate if the post was created by ODS and not Salesforce */
  createdByOds?: boolean;
}

/** Comment created for a post */
export interface SaveExperienceCommentRequest {
  /**
   * The comment text
   * @minLength 1
   * @maxLength 131072
   */
  text: string;
  /** List of files to attach to a comment */
  files: SaveFileRequest[];
}

/** Holds list of comments to save */
export interface SaveCommentsRequest {
  /** List of comments */
  comments: SaveCommentRequest[];
}

/** Comment created for a post */
export interface SaveCommentRequest {
  /**
   * Salesforce Id of the user that created the comment
   * @minLength 1
   * @maxLength 18
   */
  userSalesforceId: string;
  /**
   * Display name of the user that created the comment
   * @minLength 1
   * @maxLength 255
   */
  userDisplayName: string;
  /**
   * The comment text
   * @minLength 1
   * @maxLength 131072
   */
  text: string;
  /**
   * Comment creation date
   * @minLength 1
   * @maxLength 32
   */
  createdDate: string;
  /**
   * Salesforce Id of the comment
   * @minLength 1
   * @maxLength 18
   */
  commentSalesforceId: string;
  /** List of comment files */
  files?: CaseFile[];
}

/** Holds list of saved Comments */
export type SaveCommentsResponse = BrinksMultiStatusResponse & {
  /** List of saved Comments */
  success: Comment[];
};

/** Holds list of comments to return */
export interface GetCommentsResponse {
  /** List of comments */
  comments: Comment[];
}

/** Comment created for a post */
export interface Comment {
  /**
   * Salesforce Id of the user that created the comment
   * @minLength 1
   * @maxLength 18
   */
  userSalesforceId: string;
  /**
   * Display name of the user that created the comment
   * @minLength 1
   * @maxLength 255
   */
  userDisplayName: string;
  /**
   * The comment text
   * @minLength 1
   * @maxLength 255
   */
  text: string;
  /**
   * Comment creation date
   * @minLength 1
   * @maxLength 28
   */
  createdDate: string;
  /**
   * Salesforce Id of the post the comment belongs to
   * @minLength 1
   * @maxLength 18
   */
  postSalesforceId: string;
  /**
   * Salesforce Id of the case the post belongs to
   * @minLength 1
   * @maxLength 18
   */
  caseSalesforceId: string;
  /**
   * Salesforce Id of the comment
   * @minLength 1
   * @maxLength 18
   */
  commentSalesforceId: string;
  /** List of comment files */
  files: CaseFile[];
  /** Flag to indicate if the comment was created by ODS and not Salesforce */
  createdByOds?: boolean;
}

/** Case Template with subject, instruction and description */
export interface CaseTemplate {
  /**
   * ODS case template ID
   * @format uuid
   */
  id?: string;
  /** ISO 3166 country code (2 characters) */
  countryCode: CountryCodes;
  /**
   * Case subject
   * @minLength 1
   * @maxLength 255
   */
  subject: string;
  /**
   * Case instruction
   * @minLength 1
   * @maxLength 1024
   */
  instruction?: string;
  /**
   * Case description
   * @minLength 1
   * @maxLength 32000
   */
  description?: string;
}

/** Holds list of case template to return */
export interface GetCaseTemplatesResponse {
  /** List of case templates */
  caseTemplates: CaseTemplate[];
}

/** Holds list of case templates to add */
export interface SaveTalendCaseTemplatesRequest {
  /** List of case templates to save */
  caseTemplates: CaseTemplate[];
}

/** Holds list of saved Case templates */
export type SaveCaseTemplatesResponse = BrinksMultiStatusResponse & {
  /** List of saved case templates */
  success: CaseTemplate[];
};

/** A method to relate deposits to a seal bag when the smart safe is not aware of the seal bag (id) that it is currently lined with. In this case all deposits are selected based on the service time of collection and the previous service time. */
export interface ClearboxRequest {
  /**
   * device identifier for external partners
   * @minLength 5
   * @maxLength 40
   * @example "NL1232"
   */
  countryDeviceId: string;
  /**
   * Uniqie identifier of transaction
   * @format uuid
   * @example "bee1a305-5a2e-4047-a121-1f0d00d1ec45"
   */
  transactionId?: string;
  /**
   * The date and time of the transaction
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  transactionDateTime: string;
  /**
   * Sealbag identifier for external partners
   * @format uuid
   * @example "bee1a305-5a2e-4047-a121-1f0d00d1ec45"
   */
  partnerSealbagId?: string;
}

/** Holds list of services to return */
export interface BusinessRuleLocationServicesMoneyOrdersResponse {
  /** List of services */
  locationServicesMoneyOrderBusinessRules: BusinessRuleLocationServicesMoneyOrder[];
}

/** Business rules regarding the Money Order Location Service. */
export interface BusinessRuleLocationServicesMoneyOrder {
  /**
   * The unique internal ODS ID of the Business Rule.
   * @format uuid
   */
  id: string;
  /**
   * The unique internal ODS ID of the Store Location.
   * @format uuid
   */
  storeLocationId: string;
  /**
   * The unique internal ODS ID of the Service.
   * @format uuid
   */
  serviceId: string;
  /**
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  validFrom: string;
  /** Is this Store Location allowed to order change money or not. */
  orderAllow: boolean;
  /** It this Store Location allowed to order change money by selected a date manually? */
  orderAllowCustomDates: boolean;
  /**
   * The number of days ahead the Store Location must make the order for change money.
   * @min 0
   * @max 365
   */
  orderLeadDays?: number;
  /**
   * The time when a new Lead Day starts for Money Orders.
   * @format time
   * @pattern ^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$
   */
  orderCutOffTime?: string;
  /**
   * The number of notes this Store Location order in one order.
   * @min 1
   */
  orderMaximumNotes?: number;
  /**
   * The number of coin roles this Store Location order in one order.
   * @min 1
   */
  orderMaximumCoinRoles?: number;
  /**
   * The maximum amount this Store Location car order in one order within the currency.
   * @min 1
   */
  orderMaximumAmount?: number;
  /** Is this Store Location allowed to cancel a change money order or not. */
  cancelAllow: boolean;
  /**
   * The amount of days ahead the Store Location must cancel the order.
   * @min 0
   * @max 365
   */
  cancelLeadDays?: number;
  /**
   * The time when a new Lead Day starts for Money Order cancellations.
   * @format time
   * @pattern ^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$
   */
  cancelCutOffTime?: string;
  /**
   * The unique internal ODS ID of the Location Service.
   * @format uuid
   */
  locationServiceId?: string;
}

/** Holds list of services to return */
export interface BusinessRuleLocationServicesTransportOrdersResponse {
  /** List of services */
  locationServicesTransportOrderBusinessRules: BusinessRuleLocationServicesTransportOrder[];
}

/** Business rules regarding the Transport Order Location Service. */
export interface BusinessRuleLocationServicesTransportOrder {
  /**
   * The unique internal ODS ID of the Business Rule.
   * @format uuid
   */
  id: string;
  /**
   * The unique internal ODS ID of the Store Location.
   * @format uuid
   */
  storeLocationId: string;
  /**
   * The unique internal ODS ID of the Service.
   * @format uuid
   */
  serviceId: string;
  /**
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  validFrom: string;
  /** Is this Store Location allowed to order transport or not. */
  transportAllow: boolean;
  /** It this Store Location allowed to order change money by selecting a date manually? */
  transportAllowCustomDates: boolean;
  /**
   * The number of days ahead the Store Location must make the order for transport.
   * @min 0
   * @max 365
   */
  transportLeadDays?: number;
  /**
   * The time when a new Lead Day starts for Transport Orders.
   * @format time
   * @pattern ^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$
   */
  transportCutOffTime?: string;
  /** Is this Store Location allowed to cancel a transport order order or not. */
  cancelAllow: boolean;
  /**
   * The amount of days ahead the Store Location must cancel the order.
   * @min 0
   * @max 365
   */
  cancelLeadDays?: number;
  /**
   * The time when a new Lead Day starts for Transport Order cancellations.
   * @format time
   * @pattern ^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$
   */
  cancelCutOffTime?: string;
  /**
   * The unique internal ODS ID of the Location Service.
   * @format uuid
   */
  locationServiceId?: string;
}

/** Holds list of services to return */
export interface BusinessRuleLocationServicesMoneyOrderSkusResponse {
  /** List of services */
  locationServicesMoneyOrderSkuBusinessRules: BusinessRuleLocationServicesMoneyOrderSku[];
}

/** Business rules regarding the Money Order SKUs for Location Service. */
export interface BusRuleLocationServicesMoneyOrderSkuResponse {
  /**
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  validFrom: string;
  /**
   * The unique ID of the storeLocation provided by Salesforce.
   * @minLength 1
   * @maxLength 36
   * @example "CabzfOAWxzRYCNQ"
   */
  storeLocationSfId: string | null;
  /** Service type. */
  service: "CASH_ORDER" | "TRANSPORT_ORDER";
  /**
   * The unique ID of the Sku provided by Salesforce.
   * @minLength 1
   * @maxLength 36
   * @example "CabzfOAWxzRYCNQ"
   */
  externalReferenceSf: string | null;
  /**
   * The increment of a SKU that a Store Location can order.
   * @min 1
   * @max 1000
   */
  increments: number;
}

/** Basic data of a Business Rules Location Services Money Order. */
export interface SaveBusinessRuleLocationServicesMoneyOrder {
  /**
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  validFrom: string;
  /**
   * The unique ID of the storeLocation provided by Salesforce.
   * @minLength 1
   * @maxLength 36
   * @example "CabzfOAWxzRYCNQ"
   */
  storeLocationSfId: string | null;
  /** Service type. */
  service: "CASH_ORDER" | "TRANSPORT_ORDER";
  /** Is this Store Location allowed to order change money or not. */
  orderAllow: boolean;
  /** It this Store Location allowed to order change money by selected a date manually? */
  orderAllowCustomDates: boolean;
  /**
   * The number of days ahead the Store Location must make the order for change money.
   * @min 0
   * @max 365
   */
  orderLeadDays?: number;
  /**
   * The time when a new Lead Day starts for Money Orders.
   * @format time
   * @pattern ^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$
   */
  orderCutOffTime?: string;
  /**
   * The number of notes this Store Location order in one order.
   * @min 1
   */
  orderMaximumNotes?: number;
  /**
   * The number of coin roles this Store Location order in one order.
   * @min 1
   */
  orderMaximumCoinRoles?: number;
  /**
   * The maximum amount this Store Location car order in one order within the currency.
   * @min 1
   */
  orderMaximumAmount?: number;
  /** Is this Store Location allowed to cancel a change money order or not. */
  cancelAllow: boolean;
  /**
   * The amount of days ahead the Store Location must cancel the order.
   * @min 0
   * @max 365
   */
  cancelLeadDays?: number;
  /**
   * The time when a new Lead Day starts for Money Order cancellations.
   * @format time
   * @pattern ^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$
   */
  cancelCutOffTime?: string;
}

/** Get request business rules regarding the Money Order SKUs for Location Service. */
export interface BusinessRuleLocationServicesMoneyOrderSku {
  /**
   * The unique internal ODS ID of the Business Rule.
   * @format uuid
   */
  businessRuleId: string;
  /**
   * The unique internal ODS ID of the Stock Keeping Unit (SKU).
   * @format uuid
   */
  skuId: string;
  /**
   * The increment of a SKU that a Store Location can order.
   * @min 1
   * @max 1000
   */
  increments?: number;
}

/** Basic data of a Business Rules Location Services Money Order. */
export interface UpdateBusinessRuleLocationServicesMoneyOrder {
  /**
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  validFrom?: string;
  /** Is this Store Location allowed to order change money or not. */
  orderAllow?: boolean;
  /** It this Store Location allowed to order change money by selected a date manually? */
  orderAllowCustomDates?: boolean;
  /**
   * The number of days ahead the Store Location must make the order for change money.
   * @min 0
   * @max 365
   */
  orderLeadDays?: number;
  /**
   * The time when a new Lead Day starts for Money Orders.
   * @format time
   * @pattern ^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$
   */
  orderCutOffTime?: string;
  /**
   * The number of notes this Store Location order in one order.
   * @min 1
   */
  orderMaximumNotes?: number;
  /**
   * The number of coin roles this Store Location order in one order.
   * @min 1
   */
  orderMaximumCoinRoles?: number;
  /**
   * The maximum amount this Store Location car order in one order within the currency.
   * @min 1
   */
  orderMaximumAmount?: number;
  /** Is this Store Location allowed to cancel a change money order or not. */
  cancelAllow?: boolean;
  /**
   * The amount of days ahead the Store Location must cancel the order.
   * @min 0
   * @max 365
   */
  cancelLeadDays?: number;
  /**
   * The time when a new Lead Day starts for Money Order cancellations.
   * @format time
   * @pattern ^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$
   */
  cancelCutOffTime?: string;
}

/** Update Business rules regarding the Money Order SKUs for Location Service. */
export interface UpdateBusRuleLocationServicesMoneyOrderSku {
  /**
   * The increment of a SKU that a Store Location can order.
   * @min 1
   * @max 1000
   */
  increments?: number;
}

/** Basic data of a Business Rules Location Services Money Order. */
export interface GetBusinessRuleLocationServicesMoneyOrder {
  /**
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  validFrom: string;
  /**
   * The unique ID of the storeLocation provided by Salesforce.
   * @minLength 1
   * @maxLength 36
   * @example "CabzfOAWxzRYCNQ"
   */
  storeLocationSfId: string | null;
  /**
   * The unique internal ODS ID of the Service.
   * @format uuid
   */
  service: string;
  /** Is this Store Location allowed to order change money or not. */
  orderAllow: boolean;
  /** It this Store Location allowed to order change money by selected a date manually? */
  orderAllowCustomDates: boolean;
  /**
   * The number of days ahead the Store Location must make the order for change money.
   * @min 0
   * @max 365
   */
  orderLeadDays?: number;
  /**
   * The time when a new Lead Day starts for Money Orders.
   * @format time
   * @pattern ^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$
   */
  orderCutOffTime?: string;
  /**
   * The number of notes this Store Location order in one order.
   * @min 1
   */
  orderMaximumNotes?: number;
  /**
   * The number of coin roles this Store Location order in one order.
   * @min 1
   */
  orderMaximumCoinRoles?: number;
  /**
   * The maximum amount this Store Location car order in one order within the currency.
   * @min 1
   */
  orderMaximumAmount?: number;
  /** Is this Store Location allowed to cancel a change money order or not. */
  cancelAllow: boolean;
  /**
   * The amount of days ahead the Store Location must cancel the order.
   * @min 0
   * @max 365
   */
  cancelLeadDays?: number;
  /**
   * The time when a new Lead Day starts for Money Order cancellations.
   * @format time
   * @pattern ^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$
   */
  cancelCutOffTime?: string;
  /**
   * The unique internal ODS ID of the Location Service.
   * @format uuid
   */
  locationServiceId?: string;
}

/** Get request business rules regarding the Money Order SKUs for Location Service. */
export interface GetBusinessRuleLocationServicesMoneyOrderSku {
  /**
   * The unique ID of the Stock Keeping Unit (SKU).
   * @format uuid
   */
  skuSfId: string;
  /**
   * The increment of a SKU that a Store Location can order.
   * @min 1
   * @max 1000
   */
  increments?: number;
}

/** Holds list of payment profiles to add */
export interface SavePaymentProfilesRequest {
  /** List of payment profiles */
  paymentProfiles: PaymentProfileToSave[];
}

/** Holds list of payment profiles to return */
export interface GetPaymentProfilesResponse {
  /** List of payment profiles */
  paymentProfiles: PaymentProfile[];
}

/** Basic customer data including bank account (IBAN and BIC) data and direct debit information related to the merchant store. */
export interface PaymentProfile {
  /**
   * System generated ID For Payment Profile
   * @format uuid
   */
  id?: string;
  /**
   * Payment profile External reference (relates to the bank account object in Salesforce)
   * @format uuid
   */
  externalReferenceSf?: string | null;
  /**
   * The name of the person or business that owns the bank account
   * @maxLength 70
   */
  accountHolderName: string;
  /**
   * Customer bank account number
   * @maxLength 34
   */
  accountNumber: string;
  routingNumber?: number | null;
  /** ISO 4217 currency code (3 characters) */
  currency: CurrencyCodes;
  /** Distinguish between bank accounts, the account type explains where it is used for */
  accountType: AccountTypes;
  /**
   * BIC (Business Identifier Code) is a unique identification code for financial institutions.
   * @maxLength 11
   */
  bic?: string | null;
  /**
   * A mandate an agreement between Merchant and Brink's to perform a direct debit for the provided bank account
   * @maxLength 35
   */
  mandateReference?: string | null;
  /** SEPA Direct Debit type */
  directDebitType?: DirectDebitTypes;
  /** Payment type */
  paymentType?: PaymentTypes;
  /** EU-Specific field that indicates how payment costs are charged between payer and payee */
  bankCostType?: BankCostTypes;
  /**
   * The actual date when the SEPA direct debit mandate has been signed by the accountholder
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  mandateDateOfSignatory?: string | null;
  /** Reference of the Merchant */
  merchantReference?: string | null;
  /** Payment type */
  recordStatus: RecordStatus;
  /**
   * Record creation date
   * @format date-time
   * @example "2020-06-17T00:00:00Z"
   */
  createdAt?: string;
  /**
   * Registration update date
   * @format date-time
   * @example "2020-06-17T00:00:00Z"
   */
  updatedAt?: string;
  /** List of payment profiles store locations */
  storeLocations?: PaymentProfileStoreLocation[] | null;
}

/** Basic customer data including bank account (IBAN and BIC) data and direct debit information related to the merchant store. */
export interface PaymentProfileToSave {
  /**
   * System generated ID For Payment Profile
   * @format uuid
   */
  id?: string;
  /** Payment profile External reference (relates to the bank account object in Salesforce) */
  externalReferenceSf?: string | null;
  /**
   * The name of the person or business that owns the bank account
   * @maxLength 70
   */
  accountHolderName: string;
  /**
   * Customer bank account number
   * @maxLength 34
   */
  accountNumber: string;
  /**
   * @min 0
   * @exclusiveMin true
   * @max 999999999
   */
  routingNumber?: number | null;
  /** ISO 4217 currency code (3 characters) */
  currency: CurrencyCodes;
  /** Distinguish between bank accounts, the account type explains where it is used for */
  accountType: AccountTypes;
  /**
   * BIC (Business Identifier Code) is a unique identification code for financial institutions.
   * @maxLength 11
   */
  bic?: string | null;
  /**
   * A mandate an agreement between Merchant and Brink's to perform a direct debit for the provided bank account
   * @maxLength 35
   */
  mandateReference?: string | null;
  /** SEPA Direct Debit type */
  directDebitType?: DirectDebitTypes;
  /** Payment type */
  paymentType?: PaymentTypes;
  /** EU-Specific field that indicates how payment costs are charged between payer and payee */
  bankCostType?: BankCostTypes;
  /**
   * The actual date when the SEPA direct debit mandate has been signed by the accountholder
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  mandateDateOfSignatory?: string | null;
  /** Reference of the Merchant */
  merchantReference?: string | null;
  /** Payment type */
  recordStatus: RecordStatus;
}

/** Basic customer data including bank account (IBAN and BIC) data and direct debit information related to the merchant store. */
export interface PaymentProfileToUpdate {
  /**
   * System generated ID For Payment Profile
   * @format uuid
   */
  id?: string;
  /** Payment profile External reference (relates to the bank account object in Salesforce) */
  externalReferenceSf?: string | null;
  /**
   * The name of the person or business that owns the bank account
   * @maxLength 70
   */
  accountHolderName?: string;
  /**
   * Customer bank account number
   * @maxLength 34
   */
  accountNumber?: string;
  routingNumber?: number | null;
  /** ISO 4217 currency code (3 characters) */
  currency?: CurrencyCodes;
  /** Distinguish between bank accounts, the account type explains where it is used for */
  accountType?: AccountTypes;
  /**
   * BIC (Business Identifier Code) is a unique identification code for financial institutions.
   * @maxLength 11
   */
  bic?: string | null;
  /**
   * A mandate an agreement between Merchant and Brink's to perform a direct debit for the provided bank account
   * @maxLength 35
   */
  mandateReference?: string | null;
  /** SEPA Direct Debit type */
  directDebitType?: DirectDebitTypes;
  /** Payment type */
  paymentType?: PaymentTypes;
  /** EU-Specific field that indicates how payment costs are charged between payer and payee */
  bankCostType?: BankCostTypes;
  /**
   * The actual date when the SEPA direct debit mandate has been signed by the accountholder
   * @format date-time
   * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
   * @example "2020-06-17T00:00:00Z"
   */
  mandateDateOfSignatory?: string | null;
  /** Reference of the Merchant */
  merchantReference?: string | null;
  /** Payment type */
  recordStatus?: RecordStatus;
}

/** Store location of a Merchant. A store location has a physical address (physical location). A web-shop will be managed as a store location even it might not have a physical address. Multiple Merchants can operate at the same physical address, e.g. in case of a shop-in-shop. */
export interface PaymentProfileStoreLocation {
  /**
   * System generated ID
   * @format uuid
   */
  id?: string | null;
}

/** Holds list of saved Payment Profiles */
export type SavePaymentProfilesResponse = BrinksMultiStatusResponse & {
  /** List of saved Payment Profiles */
  success: PaymentProfile[];
};

/** Basic Payment Profile and Store Location Request Object */
export interface PaymentProfileStoreLocationRequest {
  /**
   * System generated ID For Payment Profile
   * @format uuid
   */
  paymentProfileId: string;
  /**
   * System generated ID For Store Location
   * @format uuid
   */
  storeLocationId: string;
}

/** Basic Payment Profile and Store Location Request Object */
export interface PaymentProfileStoreLocationResponse {
  /**
   * System generated ID For Payment Profile
   * @format uuid
   */
  paymentProfileId: string;
  /**
   * System generated ID For Store Location
   * @format uuid
   */
  storeLocationId: string;
}

/** Holds list of payment profiles id and store location ids to link */
export interface SavePaymentProfileStoreLocationRequest {
  /** List of payment profiles id and store location id that was linked */
  paymentProfileLinks: PaymentProfileStoreLocationRequest[];
}

/** Holds list of payment profiles id and store location ids to link */
export interface SavePaymentProfileStoreLocationResponse {
  /** List of payment profiles id and store location id that was linked */
  paymentProfileLinks: PaymentProfileStoreLocationResponse[];
}

/** Holds list of saved Payment Profiles and Store Location Link */
export type SavePaymentProfileStoreLocationPartialResponse = BrinksMultiStatusResponse & {
  /** List of payment profiles id and store location id that was linked */
  success: PaymentProfileStoreLocationResponse[];
};

/** Basic Payment Profile and Store Location Request Object By Salesforce ID */
export interface PaymentProfileStoreLocationBySfIdRequest {
  /** System generated ID For Store Location */
  storeLocationExternalReferenceSf: string;
  /** Payment profile External reference (relates to the bank account object in Salesforce) */
  paymentProfileExternalReferenceSf: string | null;
}

/** Basic Payment Profile and Store Location Request Object */
export interface PaymentProfileStoreLocationBySfIdResponse {
  /** System generated ID For Store Location */
  storeLocationExternalReferenceSf: string;
  /** Payment profile External reference (relates to the bank account object in Salesforce) */
  paymentProfileExternalReferenceSf: string | null;
}

/** Holds list of saved Payment Profiles and Store Location Link by Salesforce ID */
export type SavePaymentProfileStoreLocationBySfIdPartialResponse = BrinksMultiStatusResponse & {
  /** List of payment profiles id and store location id that was linked by Salesforce ID */
  success: PaymentProfileStoreLocationBySfIdResponse[];
};

/** Holds list of payment profiles id and store location ids to link */
export interface SavePaymentProfileStoreLocationBySfIdRequest {
  /** List of payment profiles id and store location id that was linked */
  paymentProfileLinks: PaymentProfileStoreLocationBySfIdRequest[];
}

/** Holds list of payment profiles id and store location ids to link by Salesforce Id */
export interface SavePaymentProfileStoreLocationBySfIdResponse {
  /** List of payment profiles id and store location id that was linked by salesforce ID */
  paymentProfileLinks: PaymentProfileStoreLocationBySfIdResponse[];
}

/** List of Partners added */
export interface GetPartnersResponse {
  /** List of partners */
  partners: PartnerIdResponse[];
}

/** Basic Partner information. */
export interface Partner {
  /**
   * The unique ID of the Partner provided by Salesforce.
   * @minLength 1
   * @maxLength 36
   * @example "CabzfOAWxzRYCNQ"
   */
  externalReferenceSf: string | null;
  /**
   * The Trade Name of the Partner.
   * @minLength 1
   * @maxLength 255
   */
  tradeName?: string | null;
  /**
   * The name of the Partner.
   * @minLength 1
   * @maxLength 255
   * @example "CLS"
   */
  name: string;
  /**
   * The address of the Partner.
   * @minLength 1
   * @maxLength 80
   * @example "Hazepootjelaan"
   */
  address?: string | null;
  /**
   * The postal code belonging to the contact address of the Partner.
   * @minLength 1
   * @maxLength 20
   * @example "1786 XB"
   */
  postalCode?: string | null;
  /**
   * The name of the city belonging to the contact address of the Partner.
   * @minLength 1
   * @maxLength 40
   * @example "Den Helder"
   */
  city?: string | null;
  /**
   * The state or province belonging to the contact address of the Parner.
   * @minLength 1
   * @maxLength 80
   * @example null
   */
  state?: string | null;
  /** ISO 3166 country code (2 characters) */
  countryCode: CountryCodes;
  /**
   * The phone number of the Partner.
   * @minLength 1
   * @maxLength 40
   * @example "+31881234567890"
   */
  phoneNumber?: string | null;
  /**
   * The email address of the Partner.
   * @minLength 1
   * @maxLength 40
   * @example "partner@example.com"
   */
  emailAddress?: string | null;
}

/** Partner information Id . */
export interface PartnerIdResponse {
  /**
   * Unique ODS ID of the Partner.
   * @format uuid
   */
  id: string;
}

/** Basic Partner information. */
export interface PartnerToUpdate {
  /**
   * The unique ID of the Partner provided by Salesforce.
   * @minLength 1
   * @maxLength 36
   * @example "CabzfOAWxzRYCNQ"
   */
  externalReferenceSf?: string | null;
  /**
   * The Trade Name of the Partner.
   * @minLength 1
   * @maxLength 255
   */
  tradeName?: string | null;
  /**
   * The name of the Partner.
   * @minLength 1
   * @maxLength 255
   * @example "CLS"
   */
  name?: string;
  /**
   * The address of the Partner.
   * @minLength 1
   * @maxLength 80
   * @example "Hazepootjelaan"
   */
  address?: string | null;
  /**
   * The postal code belonging to the contact address of the Partner.
   * @minLength 1
   * @maxLength 20
   * @example "1786 XB"
   */
  postalCode?: string | null;
  /**
   * The name of the city belonging to the contact address of the Partner.
   * @minLength 1
   * @maxLength 40
   * @example "Den Helder"
   */
  city?: string | null;
  /**
   * The state or province belonging to the contact address of the Parner.
   * @minLength 1
   * @maxLength 80
   * @example null
   */
  state?: string | null;
  /** ISO 3166 country code (2 characters) */
  countryCode?: CountryCodes;
  /**
   * The phone number of the Partner.
   * @minLength 1
   * @maxLength 40
   * @example "+31881234567890"
   */
  phoneNumber?: string | null;
  /**
   * The email address of the Partner.
   * @minLength 1
   * @maxLength 40
   * @example "partner@example.com"
   */
  emailAddress?: string | null;
}

/** Holds list of Partner to be added */
export interface SavePartnerRequest {
  /** List of partners */
  partners: Partner[];
}

/**
 * ISO 3166 country code (2 characters)
 * @example "NL"
 */
export enum CountryCodes {
  AF = "AF",
  AX = "AX",
  AL = "AL",
  DZ = "DZ",
  AS = "AS",
  AD = "AD",
  AO = "AO",
  AI = "AI",
  AQ = "AQ",
  AG = "AG",
  AR = "AR",
  AM = "AM",
  AW = "AW",
  AU = "AU",
  AT = "AT",
  AZ = "AZ",
  BS = "BS",
  BH = "BH",
  BD = "BD",
  BB = "BB",
  BY = "BY",
  BE = "BE",
  BZ = "BZ",
  BJ = "BJ",
  BM = "BM",
  BT = "BT",
  BO = "BO",
  BQ = "BQ",
  BA = "BA",
  BW = "BW",
  BV = "BV",
  BR = "BR",
  IO = "IO",
  BN = "BN",
  BG = "BG",
  BF = "BF",
  BI = "BI",
  CV = "CV",
  KH = "KH",
  CM = "CM",
  CA = "CA",
  KY = "KY",
  CF = "CF",
  TD = "TD",
  CL = "CL",
  CN = "CN",
  CX = "CX",
  CC = "CC",
  CO = "CO",
  KM = "KM",
  CD = "CD",
  CG = "CG",
  CK = "CK",
  CR = "CR",
  CI = "CI",
  HR = "HR",
  CU = "CU",
  CW = "CW",
  CY = "CY",
  CZ = "CZ",
  DK = "DK",
  DJ = "DJ",
  DM = "DM",
  DO = "DO",
  EC = "EC",
  EG = "EG",
  SV = "SV",
  GQ = "GQ",
  ER = "ER",
  EE = "EE",
  SZ = "SZ",
  ET = "ET",
  FK = "FK",
  FO = "FO",
  FJ = "FJ",
  FI = "FI",
  FR = "FR",
  GF = "GF",
  PF = "PF",
  TF = "TF",
  GA = "GA",
  GM = "GM",
  GE = "GE",
  DE = "DE",
  GH = "GH",
  GI = "GI",
  GR = "GR",
  GL = "GL",
  GD = "GD",
  GP = "GP",
  GU = "GU",
  GT = "GT",
  GG = "GG",
  GN = "GN",
  GW = "GW",
  GY = "GY",
  HT = "HT",
  HM = "HM",
  VA = "VA",
  HN = "HN",
  HK = "HK",
  HU = "HU",
  IS = "IS",
  IN = "IN",
  ID = "ID",
  IR = "IR",
  IQ = "IQ",
  IE = "IE",
  IM = "IM",
  IL = "IL",
  IT = "IT",
  JM = "JM",
  JP = "JP",
  JE = "JE",
  JO = "JO",
  KZ = "KZ",
  KE = "KE",
  KI = "KI",
  KP = "KP",
  KR = "KR",
  KW = "KW",
  KG = "KG",
  LA = "LA",
  LV = "LV",
  LB = "LB",
  LS = "LS",
  LR = "LR",
  LY = "LY",
  LI = "LI",
  LT = "LT",
  LU = "LU",
  MO = "MO",
  MK = "MK",
  MG = "MG",
  MW = "MW",
  MY = "MY",
  MV = "MV",
  ML = "ML",
  MT = "MT",
  MH = "MH",
  MQ = "MQ",
  MR = "MR",
  MU = "MU",
  YT = "YT",
  MX = "MX",
  FM = "FM",
  MD = "MD",
  MC = "MC",
  MN = "MN",
  ME = "ME",
  MS = "MS",
  MA = "MA",
  MZ = "MZ",
  MM = "MM",
  NA = "NA",
  NR = "NR",
  NP = "NP",
  NL = "NL",
  NC = "NC",
  NZ = "NZ",
  NI = "NI",
  NE = "NE",
  NG = "NG",
  NU = "NU",
  NF = "NF",
  MP = "MP",
  NO = "NO",
  OM = "OM",
  PK = "PK",
  PW = "PW",
  PS = "PS",
  PA = "PA",
  PG = "PG",
  PY = "PY",
  PE = "PE",
  PH = "PH",
  PN = "PN",
  PL = "PL",
  PT = "PT",
  PR = "PR",
  QA = "QA",
  RE = "RE",
  RO = "RO",
  RU = "RU",
  RW = "RW",
  BL = "BL",
  SH = "SH",
  KN = "KN",
  LC = "LC",
  MF = "MF",
  PM = "PM",
  VC = "VC",
  WS = "WS",
  SM = "SM",
  ST = "ST",
  SA = "SA",
  SN = "SN",
  RS = "RS",
  SC = "SC",
  SL = "SL",
  SG = "SG",
  SX = "SX",
  SK = "SK",
  SI = "SI",
  SB = "SB",
  SO = "SO",
  ZA = "ZA",
  GS = "GS",
  SS = "SS",
  ES = "ES",
  LK = "LK",
  SD = "SD",
  SR = "SR",
  SJ = "SJ",
  SE = "SE",
  CH = "CH",
  SY = "SY",
  TW = "TW",
  TJ = "TJ",
  TZ = "TZ",
  TH = "TH",
  TL = "TL",
  TG = "TG",
  TK = "TK",
  TO = "TO",
  TT = "TT",
  TN = "TN",
  TR = "TR",
  TM = "TM",
  TC = "TC",
  TV = "TV",
  UG = "UG",
  UA = "UA",
  AE = "AE",
  GB = "GB",
  UM = "UM",
  US = "US",
  UY = "UY",
  UZ = "UZ",
  VU = "VU",
  VE = "VE",
  VN = "VN",
  VG = "VG",
  VI = "VI",
  WF = "WF",
  EH = "EH",
  YE = "YE",
  ZM = "ZM",
  ZW = "ZW",
  ZZ = "ZZ",
  XX = "XX",
}

/**
 * ISO 4217 currency code (3 characters)
 * @example "EUR"
 */
export enum CurrencyCodes {
  AED = "AED",
  AFN = "AFN",
  ALL = "ALL",
  AMD = "AMD",
  ANG = "ANG",
  AOA = "AOA",
  ARS = "ARS",
  AUD = "AUD",
  AWG = "AWG",
  AZN = "AZN",
  BAM = "BAM",
  BBD = "BBD",
  BDT = "BDT",
  BGN = "BGN",
  BHD = "BHD",
  BIF = "BIF",
  BMD = "BMD",
  BND = "BND",
  BOB = "BOB",
  BOV = "BOV",
  BRL = "BRL",
  BSD = "BSD",
  BTN = "BTN",
  BWP = "BWP",
  BYN = "BYN",
  BZD = "BZD",
  CAD = "CAD",
  CDF = "CDF",
  CHE = "CHE",
  CHF = "CHF",
  CHW = "CHW",
  CLF = "CLF",
  CLP = "CLP",
  COP = "COP",
  COU = "COU",
  CRC = "CRC",
  CUC = "CUC",
  CUP = "CUP",
  CVE = "CVE",
  CZK = "CZK",
  DJF = "DJF",
  DKK = "DKK",
  DOP = "DOP",
  DZD = "DZD",
  EGP = "EGP",
  ERN = "ERN",
  ETB = "ETB",
  EUR = "EUR",
  FJD = "FJD",
  FKP = "FKP",
  GBP = "GBP",
  GEL = "GEL",
  GHS = "GHS",
  GIP = "GIP",
  GMD = "GMD",
  GNF = "GNF",
  GTQ = "GTQ",
  GYD = "GYD",
  HKD = "HKD",
  HNL = "HNL",
  HTG = "HTG",
  HUF = "HUF",
  IDR = "IDR",
  ILS = "ILS",
  INR = "INR",
  IQD = "IQD",
  IRR = "IRR",
  ISK = "ISK",
  JMD = "JMD",
  JOD = "JOD",
  JPY = "JPY",
  KES = "KES",
  KGS = "KGS",
  KHR = "KHR",
  KMF = "KMF",
  KPW = "KPW",
  KRW = "KRW",
  KWD = "KWD",
  KYD = "KYD",
  KZT = "KZT",
  LAK = "LAK",
  LBP = "LBP",
  LKR = "LKR",
  LRD = "LRD",
  LSL = "LSL",
  LYD = "LYD",
  MAD = "MAD",
  MDL = "MDL",
  MGA = "MGA",
  MKD = "MKD",
  MMK = "MMK",
  MNT = "MNT",
  MOP = "MOP",
  MRU = "MRU",
  MUR = "MUR",
  MVR = "MVR",
  MWK = "MWK",
  MXN = "MXN",
  MXV = "MXV",
  MYR = "MYR",
  MZN = "MZN",
  NAD = "NAD",
  NGN = "NGN",
  NIO = "NIO",
  NOK = "NOK",
  NPR = "NPR",
  NZD = "NZD",
  OMR = "OMR",
  PAB = "PAB",
  PEN = "PEN",
  PGK = "PGK",
  PHP = "PHP",
  PKR = "PKR",
  PLN = "PLN",
  PYG = "PYG",
  QAR = "QAR",
  RON = "RON",
  RSD = "RSD",
  CNY = "CNY",
  RUB = "RUB",
  RWF = "RWF",
  SAR = "SAR",
  SBD = "SBD",
  SCR = "SCR",
  SDG = "SDG",
  SEK = "SEK",
  SGD = "SGD",
  SHP = "SHP",
  SLE = "SLE",
  SLL = "SLL",
  SOS = "SOS",
  SRD = "SRD",
  SSP = "SSP",
  STN = "STN",
  SVC = "SVC",
  SYP = "SYP",
  SZL = "SZL",
  THB = "THB",
  TJS = "TJS",
  TMT = "TMT",
  TND = "TND",
  TOP = "TOP",
  TRY = "TRY",
  TTD = "TTD",
  TWD = "TWD",
  TZS = "TZS",
  UAH = "UAH",
  UGX = "UGX",
  USD = "USD",
  USN = "USN",
  UYI = "UYI",
  UYU = "UYU",
  UYW = "UYW",
  UZS = "UZS",
  VED = "VED",
  VES = "VES",
  VND = "VND",
  VUV = "VUV",
  WST = "WST",
  XAF = "XAF",
  XAG = "XAG",
  XAU = "XAU",
  XBA = "XBA",
  XBB = "XBB",
  XBC = "XBC",
  XBD = "XBD",
  XCD = "XCD",
  XDR = "XDR",
  XOF = "XOF",
  XPD = "XPD",
  XPF = "XPF",
  XPT = "XPT",
  XSU = "XSU",
  XTS = "XTS",
  XUA = "XUA",
  XXX = "XXX",
  YER = "YER",
  ZAR = "ZAR",
  ZMW = "ZMW",
  ZWL = "ZWL",
}

/**
 * Distinguish between bank accounts, the account type explains where it is used for
 * @example "Direct Debit"
 */
export enum AccountTypes {
  Default = "Default",
  DirectDebit = "Direct Debit",
  PreCredit = "Pre-Credit",
  Invoicing = "Invoicing",
}

/**
 * EU-Specific field that indicates how payment costs are charged between payer and payee
 * @example "CRED"
 */
export enum BankCostTypes {
  CRED = "CRED",
  DEBT = "DEBT",
  SHAR = "SHAR",
  SLEV = "SLEV",
}

/**
 * SEPA Direct Debit type
 * @example "CORE"
 */
export enum DirectDebitTypes {
  B2B = "B2B",
  CORE = "CORE",
  CORE1 = "CORE1",
}

/**
 * Payment type
 * @example "SEPA"
 */
export enum PaymentTypes {
  SEPA = "SEPA",
  NonSEPA = "Non-SEPA",
  International = "International",
}

/**
 * Payment type
 * @example "ACTIVE"
 */
export enum RecordStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

/**
 * Sealbag Status
 * @example "CREATED"
 */
export enum SealbagStatus {
  DRAFT = "DRAFT",
  CREATED = "CREATED",
  RECEIVED = "RECEIVED",
  COLLECTED = "COLLECTED",
  VERIFIED = "VERIFIED",
  WAITING_SETTLEMENT = "WAITING_SETTLEMENT",
}

/**
 * Device Extract Type
 * @example "DEFAULT"
 */
export enum DeviceExtractType {
  DEPOSIT = "DEPOSIT",
  DEPOSIT_BRIDGE = "DEPOSIT_BRIDGE",
  CLEARBOX = "CLEARBOX",
  DEVICE_STATUS = "DEVICE_STATUS",
}

// ----------------------
import { generateNewId, getAccessToken } from "../utils";
// ----------------------

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, ResponseType } from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || "https://api.uat.blubeemportal.com",
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.instance.defaults.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      formData.append(
        key,
        property instanceof Blob
          ? property
          : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
      );
      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    // -------------
    const accessToken = await getAccessToken();
    const traceId = generateNewId();

    params = { ...params, headers: { ...params.headers, Authorization: `Bearer ${accessToken}`, "trace-id": traceId } };
    // -------------
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = (format && this.format) || void 0;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      requestParams.headers.common = { Accept: "*/*" };
      requestParams.headers.post = {};
      requestParams.headers.put = {};

      body = this.createFormData(body as Record<string, unknown>);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Brinks ODS System API
 * @version 1.2.2
 * @baseUrl https://api.uat.blubeemportal.com
 *
 * Public ODS API
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  locations = {
    /**
     * @description GET all store locations
     *
     * @tags locations
     * @name GetStoreLocations
     * @summary Return a list of all store locations
     * @request GET:/locations
     * @secure
     */
    getStoreLocations: (
      query?: {
        /** Used to filter the store locations by cash devices. True will return stores with cash devices. False will return stores with no cash devices. */
        hasCashDevices?: boolean;
        /** User type */
        userType?: "standardUser" | "superUser";
      },
      params: RequestParams = {},
    ) =>
      this.request<GetLocationsResponse, void>({
        path: `/locations`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description GET store location with a specified Id
     *
     * @tags locations
     * @name GetLocationDetails
     * @summary Get store location
     * @request GET:/locations/{locationId}
     * @secure
     */
    getLocationDetails: (locationId: string, params: RequestParams = {}) =>
      this.request<GetLocationWithContactsResponse, void>({
        path: `/locations/${locationId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description GET business-rules for a store location
     *
     * @tags locations
     * @name GetLocationBusinessRules
     * @summary Return a list of business-rules
     * @request GET:/locations/salesforce/{salesforceId}/business-rules
     * @secure
     */
    getLocationBusinessRules: (salesforceId: string, params: RequestParams = {}) =>
      this.request<GetBusinessRulesResponse, void>({
        path: `/locations/salesforce/${salesforceId}/business-rules`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  locationsWithMerchant = {
    /**
     * @description GET store location with a specified Id and Merchant Information
     *
     * @tags locations
     * @name GetLocationDetailsWithMerchant
     * @summary Get store location
     * @request GET:/locations-with-merchant/{locationId}
     * @secure
     */
    getLocationDetailsWithMerchant: (locationId: string, params: RequestParams = {}) =>
      this.request<GetLocationWithContactsAndMerchantResponse, void>({
        path: `/locations-with-merchant/${locationId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description GET all store locations with Merchant Information
     *
     * @tags locations
     * @name GetLocationsWithMerchant
     * @summary Return a list of all store locations with Merchant Information
     * @request GET:/locations-with-merchant
     * @secure
     */
    getLocationsWithMerchant: (
      query?: {
        /** Used to filter the store locations by cash devices. True will return stores with cash devices. False will return stores with no cash devices. */
        hasCashDevices?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetStoreLocationsWithMerchantResponse, void>({
        path: `/locations-with-merchant`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  merchants = {
    /**
     * @description GET all merchants and its locations
     *
     * @tags merchants
     * @name GetAllMerchants
     * @summary Return a list of all merchants with its locations
     * @request GET:/merchants
     */
    getAllMerchants: (
      query?: {
        /** @format uuid */
        merchantId?: string;
        /** User type */
        userType?: "standardUser" | "superUser";
      },
      params: RequestParams = {},
    ) =>
      this.request<GetMerchantsAndLocationsResponse, void>({
        path: `/merchants`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  devices = {
    /**
     * @description GET a list of cash devices (related to a store location)
     *
     * @tags devices
     * @name GetCashDevices
     * @summary Return a list of cash devices (related to a store location)
     * @request GET:/devices/cash
     */
    getCashDevices: (
      query?: {
        /**
         * Store location id
         * @format uuid
         */
        storeId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetCashDevicesResponse, BrinksOds001 | void | BrinksOds004 | BrinksOds007>({
        path: `/devices/cash`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  transactions = {
    /**
     * @description GET a list of transactions
     *
     * @tags transactions
     * @name GetTransactions
     * @summary Return a list of transactions
     * @request GET:/transactions
     * @secure
     */
    getTransactions: (
      query?: {
        /** Transaction type */
        transactionType?: "cash" | "cards" | "clicks";
        /**
         * Starting date of the query period
         * @format date-time
         * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
         * @example "2020-06-17T00:00:00Z"
         */
        startDate?: string;
        /**
         * End date of the query period
         * @format date-time
         * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
         * @example "2020-06-17T00:00:00Z"
         */
        endDate?: string;
        /**
         * End date of the query period
         * @format date-time
         * @pattern \d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}(\.\d+)? [+-]\d{4}
         * @example "2020-06-17 00:00:00.000Z"
         */
        createdAt?: string;
        /** Credit/Debit indicator */
        creditDebitIndicator?: "CREDIT" | "DEBIT";
        /**
         * Minimum transaction amount
         * @min 0
         * @max 999999999.999
         */
        minAmount?: number;
        /**
         * Maximum transaction amount
         * @min 0
         * @max 999999999.999
         */
        maxAmount?: number;
        /**
         * Store location id
         * @format uuid
         */
        locationId?: string;
        /** Payment method */
        paymentMethod?:
          | "VISA"
          | "MASTERCARD"
          | "AMERICAN_EXPRESS"
          | "VPAY"
          | "MAESTRO"
          | "IDEAL"
          | "BANCONTACT"
          | "GOOGLE_PAY"
          | "APPLE_PAY"
          | "PAYPAL";
        /** User type */
        userType?: "standardUser" | "superUser";
        /**
         * External (cm.com) id of the resource
         * @maxLength 36
         */
        externalReferenceCm?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetTransactionsResponse, void>({
        path: `/transactions`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description GET transaction of a concrete type with a specified Id
     *
     * @tags transactions
     * @name GetTransactionDetails
     * @summary Get transaction details
     * @request GET:/transactions/{transactionType}/{transactionId}
     * @secure
     */
    getTransactionDetails: (
      transactionType: "cash" | "cards" | "clicks",
      transactionId: string,
      query?: {
        /** User type */
        userType?: "standardUser" | "superUser";
      },
      params: RequestParams = {},
    ) =>
      this.request<GetTransactionDetailsResponse, void>({
        path: `/transactions/${transactionType}/${transactionId}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description export a csv file with transactionDetails
     *
     * @tags transactions
     * @name ExportTransactionDetails
     * @summary export a csv file with transactionDetails
     * @request GET:/transactions/export
     * @secure
     */
    exportTransactionDetails: (
      query?: {
        /** Transaction type */
        transactionType?: "cash" | "cards" | "clicks";
        /**
         * Starting date of the query period
         * @format date-time
         * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
         * @example "2020-06-17T00:00:00Z"
         */
        startDate?: string;
        /**
         * End date of the query period
         * @format date-time
         * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
         * @example "2020-06-17T00:00:00Z"
         */
        endDate?: string;
        /** Credit/Debit indicator */
        creditDebitIndicator?: "CREDIT" | "DEBIT";
        /**
         * Minimum transaction amount
         * @min 0
         * @max 999999999.999
         */
        minAmount?: number;
        /**
         * Maximum transaction amount
         * @min 0
         * @max 999999999.999
         */
        maxAmount?: number;
        /**
         * Store location id
         * @format uuid
         */
        locationId?: string;
        /** Payment method */
        paymentMethod?:
          | "VISA"
          | "MASTERCARD"
          | "AMERICAN_EXPRESS"
          | "VPAY"
          | "MAESTRO"
          | "IDEAL"
          | "BANCONTACT"
          | "GOOGLE_PAY"
          | "APPLE_PAY"
          | "PAYPAL";
        /** User type */
        userType?: "standardUser" | "superUser";
      },
      params: RequestParams = {},
    ) =>
      this.request<string, void>({
        path: `/transactions/export`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
  };
  denominations = {
    /**
     * @description GET a list of all denominations
     *
     * @tags denominations
     * @name GetDenominations
     * @summary Return a list of denominations
     * @request GET:/denominations
     * @secure
     */
    getDenominations: (
      query?: {
        /**
         * 3 letter ISO currency code
         * @minLength 3
         * @maxLength 3
         */
        currency?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetDenominationsResponse, void>({
        path: `/denominations`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description GET a list of all denominations
     *
     * @tags denominations
     * @name GetDenominationById
     * @summary Return a list of denominations
     * @request GET:/denominations/{denominationId}
     * @secure
     */
    getDenominationById: (denominationId: string, params: RequestParams = {}) =>
      this.request<GetDenominationsResponse, void>({
        path: `/denominations/${denominationId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  deposits = {
    /**
     * @description POST deposits
     *
     * @tags deposits
     * @name SaveDeposits
     * @summary Add one (or more) deposits
     * @request POST:/deposits
     * @secure
     */
    saveDeposits: (data: SaveDepositsRequest, params: RequestParams = {}) =>
      this.request<GetDepositsResponse, BrinksOds001 | void | BrinksOds007>({
        path: `/deposits`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  preAnnouncements = {
    /**
     * @description POST pre-announcements
     *
     * @tags deposits
     * @name SavePreAnnouncements
     * @summary Add one (or more) pre-announcements
     * @request POST:/pre-announcements
     * @secure
     */
    savePreAnnouncements: (data: SavePreAnnouncementsRequest, params: RequestParams = {}) =>
      this.request<SavePreAnnouncementsResponse, void>({
        path: `/pre-announcements`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  users = {
    /**
     * @description GET a list of users of a merchant
     *
     * @tags users
     * @name GetUsers
     * @summary Return a list of users of a merchant
     * @request GET:/users
     * @secure
     */
    getUsers: (params: RequestParams = {}) =>
      this.request<GetUsersResponse, void>({
        path: `/users`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description POST users
     *
     * @tags users
     * @name SaveUsers
     * @summary Add one (or more) users for a merchant
     * @request POST:/users
     */
    saveUsers: (data: SaveUsersRequest, params: RequestParams = {}) =>
      this.request<GetUsersResponse, void>({
        path: `/users`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description PUT user
     *
     * @tags users
     * @name UpdateUser
     * @summary Update user
     * @request PUT:/users/{userId}
     * @secure
     */
    updateUser: (userId: string, data: User, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/users/${userId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  b2CUsers = {
    /**
     * @description GET a list of users of a merchant
     *
     * @tags b2c-users
     * @name GetAllusers
     * @summary Return a list of users of a merchant
     * @request GET:/b2c-users
     * @secure
     */
    getAllusers: (params: RequestParams = {}) =>
      this.request<GetUsersResponse, void>({
        path: `/b2c-users`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description POST users
     *
     * @tags b2c-users
     * @name CreateUsers
     * @summary Add one (or more) users for a merchant
     * @request POST:/b2c-users
     */
    createUsers: (data: SaveUsersRequest, params: RequestParams = {}) =>
      this.request<GetUsersResponse, void>({
        path: `/b2c-users`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description patch user
     *
     * @tags b2c-users
     * @name EditUsers
     * @summary Edit user details
     * @request PATCH:/b2c-users/{userId}
     * @secure
     */
    editUsers: (userId: string, data: User, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/b2c-users/${userId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description get user
     *
     * @tags b2c-users
     * @name GetuserInfo
     * @summary get user details
     * @request GET:/b2c-users/{userId}
     * @secure
     */
    getuserInfo: (userId: string, params: RequestParams = {}) =>
      this.request<GetUsersResponse, void>({
        path: `/b2c-users/${userId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description delete user
     *
     * @tags b2c-users
     * @name DeleteUser
     * @summary Delete user details
     * @request DELETE:/b2c-users/{userId}
     * @secure
     */
    deleteUser: (userId: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/b2c-users/${userId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * @description Send a welcome email
     *
     * @tags b2c-users
     * @name SendWelcomeEmail
     * @summary Send a welcome email
     * @request POST:/b2c-users/account/{userId}
     */
    sendWelcomeEmail: (userId: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/b2c-users/account/${userId}`,
        method: "POST",
        ...params,
      }),

    /**
     * @description Acctivate user account
     *
     * @tags b2c-users
     * @name ActivateAccount
     * @summary Acctivate user account
     * @request PATCH:/b2c-users/account/{userId}
     * @secure
     */
    activateAccount: (userId: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/b2c-users/account/${userId}`,
        method: "PATCH",
        secure: true,
        ...params,
      }),

    /**
     * @description Send a OTP to user
     *
     * @tags b2c-users
     * @name SendOtp
     * @summary Send a OTP
     * @request POST:/b2c-users/otp/{userId}
     * @secure
     */
    sendOtp: (userId: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/b2c-users/otp/${userId}`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * @description Verify OTP of the user
     *
     * @tags b2c-users
     * @name VerifyOtp
     * @summary Verify a OTP
     * @request POST:/b2c-users/verifyOtp/{userId}
     * @secure
     */
    verifyOtp: (userId: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/b2c-users/verifyOtp/${userId}`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * @description Send Welcome Mail to all the inactive users for a given appType
     *
     * @tags b2c-users
     * @name SendWelcomeMailToInactiveUsers
     * @summary Send Activation mail to inactive users
     * @request POST:/b2c-users/sendWelcomeMailToInactiveUsers
     * @secure
     */
    sendWelcomeMailToInactiveUsers: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/b2c-users/sendWelcomeMailToInactiveUsers`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * @description Send Verify OTP Mail to a given User Email
     *
     * @tags b2c-users
     * @name SendVerifyOtpEmail
     * @summary Send Verify OTP e-mail to user
     * @request POST:/b2c-users/sendVerifyOtpEmail
     */
    sendVerifyOtpEmail: (data: SendVerifyOtpEmailRequest, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/b2c-users/sendVerifyOtpEmail`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  sendEmail = {
    /**
     * @description POST password reset request
     *
     * @tags b2c-users
     * @name PasswordResetRequest
     * @summary send email for password reset request
     * @request POST:/sendEmail/{userId}
     * @secure
     */
    passwordResetRequest: (userId: string, data: PasswordResetRequest, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/sendEmail/${userId}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  orders = {
    /**
     * @description GET all orders and order items
     *
     * @tags orders
     * @name GetOrders
     * @summary Return a list of all orders and order items
     * @request GET:/orders
     * @secure
     */
    getOrders: (
      query?: {
        /**
         * Store location id
         * @format uuid
         */
        storeId?: string;
        /** User type */
        userType?: "standardUser" | "superUser";
        /**
         * End date of the query period
         * @format date-time
         * @pattern \d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}(\.\d+)? [+-]\d{4}
         * @example "2020-06-17 00:00:00.000Z"
         */
        createdAt?: string;
        /** The current page where the register is. */
        page?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetOrdersResponseWithBusinessRules, BrinksOds001 | void | BrinksOds007>({
        path: `/orders`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description POST orders and order items
     *
     * @tags orders
     * @name SaveOrders
     * @summary Add one (or more) orders and order items
     * @request POST:/orders
     * @secure
     */
    saveOrders: (data: SaveOrdersRequest, params: RequestParams = {}) =>
      this.request<GetOrdersResponse, BrinksOds001 | void | BrinksOds007>({
        path: `/orders`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description GET order with a specified Id
     *
     * @tags orders
     * @name GetOrder
     * @summary Get Order
     * @request GET:/orders/{orderId}
     * @secure
     */
    getOrder: (orderId: string, params: RequestParams = {}) =>
      this.request<GetOrderDetailsResponse, void | BrinksOds007>({
        path: `/orders/${orderId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Replaces the status of a order based on the Order Id.
     *
     * @tags orders
     * @name UpdateOrder
     * @summary Replaces the status of a order based on the Order Id.
     * @request PATCH:/orders/{orderId}
     */
    updateOrder: (orderId: string, data: OrderStatus, params: RequestParams = {}) =>
      this.request<void, BrinksOds001 | void | BrinksOds007>({
        path: `/orders/${orderId}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  ordersWithMerchant = {
    /**
     * @description GET all orders and order items with merchant information
     *
     * @tags orders
     * @name GetOrdersWithMerchant
     * @summary Return a list of all orders and order items with merchant
     * @request GET:/orders-with-merchant
     * @secure
     */
    getOrdersWithMerchant: (
      query?: {
        /**
         * Store location id
         * @format uuid
         */
        storeId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** List of orders */
          orders: {
            /**
             * System generated ID
             * @format uuid
             */
            id?: string | null;
            /**
             * External Salesforce reference of the (change) order
             * @maxLength 36
             */
            externalReferenceSf?: string | null;
            /**
             * The ID of store_location object
             * @format uuid
             */
            storeId: string;
            /**
             * ID of the contract object
             * @format uuid
             */
            contractId?: string | null;
            /**
             * The date and time the order was placed
             * @format date-time
             * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
             * @example "2020-06-17T00:00:00Z"
             */
            orderDateTime: string;
            /**
             * Order number assigned to this order (not the unique, system-generated ID assigned during creation).
             * @maxLength 40
             */
            orderNumber?: string | null;
            /**
             * The original order that a change order was created from.
             * @maxLength 36
             */
            originalOrderId?: string | null;
            /**
             * Order description.
             * @maxLength 255
             */
            orderName?: string | null;
            status:
              | "NEW"
              | "PICKED"
              | "SHIPPED"
              | "RECEIVED"
              | "DELIVERED"
              | "CANCELLED"
              | "IN_PROGRESS"
              | "SENT_TO_FULFILLMENT"
              | "REJECTED";
            /**
             * @format date-time
             * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
             * @example "2020-06-17T00:00:00Z"
             */
            deliveryDateTime?: string | null;
            /** Customer can choose between Flexible and Fixed Delivery. */
            isFixedDelivery?: boolean | null;
            /** @maxLength 40 */
            paymentMethod?: string | null;
            /** Only required for Change Order */
            totalAmount?: number | null;
            /** ISO 4217 currency code (3 characters) */
            currency?: CurrencyCodes | null;
            frequency?: "ONCE" | "EVERY_1_WEEK" | "EVERY_2_WEEKS" | "EVERY_4_WEEKS" | null;
            /**
             * @format date-time
             * @pattern ^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$
             * @example "2020-06-17T00:00:00Z"
             */
            endDate?: string | null;
            /** List of order items belonging to the order */
            items?: OrderItem[];
            /**
             * Sequence number will auto increment and will give every order a unique number
             * @min 1
             */
            sequenceNumber?: number;
            /**
             * The ODS ID of the merchant
             * @format uuid
             */
            merchantId: string;
            /**
             * Full name of the user who prepared the order
             * @maxLength 255
             */
            preparedBy?: string | null;
            /**
             * Store location name
             * @maxLength 80
             */
            storeName?: string | null;
            /**
             * Store location code
             * @maxLength 20
             */
            storeLocationCode?: string | null;
            /**
             * customer order reference
             * @maxLength 50
             */
            customerReference?: string | null;
            /** Brink’s customer, often the owner of a shop. The term Merchant is preferred over Customer. */
            merchant?: MerchantWithDependentsAndLocations;
            /**
             * The ID of the service object.
             * @format uuid
             */
            serviceId?: string;
            /** Basic customer data including bank account (IBAN and BIC) data and direct debit information related to the merchant store */
            service?: Service;
            /** Business rules related to the order */
            businessRules?: BusinessRuleLocationServicesMoneyOrder | null;
          }[];
        },
        BrinksOds001 | void | BrinksOds007
      >({
        path: `/orders-with-merchant`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description GET order with a specified Id and with merchant information
     *
     * @tags orders
     * @name GetOrderWithMerchant
     * @summary Get Order With Merchant Information
     * @request GET:/orders-with-merchant/{orderId}
     * @secure
     */
    getOrderWithMerchant: (orderId: string, params: RequestParams = {}) =>
      this.request<GetOrderDetailsResponseWithMerchantAndBusRules, void | BrinksOds007>({
        path: `/orders-with-merchant/${orderId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  collections = {
    /**
     * @description POST collections
     *
     * @tags orders
     * @name SaveCollections
     * @summary Add one (or more) collections
     * @request POST:/collections
     * @secure
     */
    saveCollections: (data: SaveCollectionsRequest, params: RequestParams = {}) =>
      this.request<SaveCollectionsResponse, void>({
        path: `/collections`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  cases = {
    /**
     * @description POST case
     *
     * @tags case-management
     * @name SaveCase
     * @summary Add one case
     * @request POST:/cases
     * @secure
     */
    saveCase: (data: SaveCaseRequest, params: RequestParams = {}) =>
      this.request<SaveCaseResponse, BrinksOds001 | void | BrinksOds007>({
        path: `/cases`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description GET a list of cases
     *
     * @tags case-management
     * @name GetCases
     * @summary Return a list of cases
     * @request GET:/cases
     * @secure
     */
    getCases: (params: RequestParams = {}) =>
      this.request<GetCasesResponse, void | BrinksOds007>({
        path: `/cases`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description GET a list of case templates
     *
     * @tags case-management
     * @name GetTemplates
     * @summary Return a list of case templates
     * @request GET:/cases/templates
     * @secure
     */
    getTemplates: (
      query: {
        /**
         * ISO 3166 country codes (2 characters)
         * @minLength 2
         * @maxLength 2
         */
        countryCode: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetCaseTemplatesResponse, void | BrinksOds007>({
        path: `/cases/templates`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description POST new post
     *
     * @tags case-management
     * @name SavePost
     * @summary Add a post for a given case
     * @request POST:/cases/{caseSalesforceId}/posts
     * @secure
     */
    savePost: (caseSalesforceId: string, data: SaveExperiencePostRequest, params: RequestParams = {}) =>
      this.request<GetPostsResponse, BrinksOds001 | void | BrinksOds007>({
        path: `/cases/${caseSalesforceId}/posts`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description GET a list of posts
     *
     * @tags case-management
     * @name GetPosts
     * @summary Return a list of posts
     * @request GET:/cases/{caseSalesforceId}/posts
     * @secure
     */
    getPosts: (caseSalesforceId: string, params: RequestParams = {}) =>
      this.request<GetPostsResponse, BrinksOds001 | void | BrinksOds007>({
        path: `/cases/${caseSalesforceId}/posts`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description POST comment
     *
     * @tags case-management
     * @name SaveComment
     * @summary Add one comment for a post
     * @request POST:/cases/{caseSalesforceId}/posts/{postSalesforceId}/comments
     * @secure
     */
    saveComment: (
      caseSalesforceId: string,
      postSalesforceId: string,
      data: SaveExperienceCommentRequest,
      params: RequestParams = {},
    ) =>
      this.request<GetCommentsResponse, BrinksOds001 | void | BrinksOds007>({
        path: `/cases/${caseSalesforceId}/posts/${postSalesforceId}/comments`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description GET a list of comments for a post
     *
     * @tags case-management
     * @name GetComments
     * @summary Return a list of comments for a post
     * @request GET:/cases/{caseSalesforceId}/posts/{postSalesforceId}/comments
     * @secure
     */
    getComments: (caseSalesforceId: string, postSalesforceId: string, params: RequestParams = {}) =>
      this.request<GetCommentsResponse, BrinksOds001 | void | BrinksOds007>({
        path: `/cases/${caseSalesforceId}/posts/${postSalesforceId}/comments`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  casesWithMerchant = {
    /**
     * @description GET a list of cases
     *
     * @tags case-management
     * @name GetCasesWithMerchant
     * @summary Return a list of cases
     * @request GET:/cases-with-merchant
     * @secure
     */
    getCasesWithMerchant: (params: RequestParams = {}) =>
      this.request<GetCasesWithMerchantResponse, void | BrinksOds007>({
        path: `/cases-with-merchant`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  businessRules = {
    /**
     * @description Retrieves and stores business rules regarding the Money Order Location Service.
     *
     * @tags business-rules
     * @name GetRulesMoneyOrders
     * @summary Retrieves and stores business rules regarding the Money Order Location Service.
     * @request GET:/business-rules/location-services/money-orders
     */
    getRulesMoneyOrders: (
      query?: {
        /**
         * Select the Valid From Data and every record after this date
         * @format date
         */
        validFromDateAndLater?: string;
        /**
         * Store location id
         * @format uuid
         */
        locationId?: string;
        /**
         * External (Service) id of the resource
         * @maxLength 36
         */
        serviceId?: string;
        /**
         * The internal ODS ID of a Business Rule.
         * @format uuid
         */
        businessRuleId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessRuleLocationServicesMoneyOrdersResponse, BrinksOds001 | void>({
        path: `/business-rules/location-services/money-orders`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Stores one or mulitple business rules regarding the Money Order Location Service.
     *
     * @tags business-rules
     * @name SaveRulesMoneyOrders
     * @summary Creates a Business Rule for a Store Location.
     * @request POST:/business-rules/location-services/money-orders
     */
    saveRulesMoneyOrders: (data: BusinessRuleLocationServicesMoneyOrder, params: RequestParams = {}) =>
      this.request<void, BrinksOds001 | void | BrinksOds007>({
        path: `/business-rules/location-services/money-orders`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Replaces the data of a Money Order Business Rule based on the internal ODS IDs. Data that is not given in the body will be removed.
     *
     * @tags business-rules
     * @name ReplaceRulesMoneyOrders
     * @summary Replaces the data of a Money Order Business Rule based on the internal ODS IDs. Data that is not given in the body will be removed.
     * @request PUT:/business-rules/location-services/money-orders
     */
    replaceRulesMoneyOrders: (
      data: BusinessRuleLocationServicesMoneyOrder,
      query?: {
        /**
         * The internal ODS ID of a Business Rule.
         * @format uuid
         */
        businessRuleId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, BrinksOds001 | void | BrinksOds004 | BrinksOds007>({
        path: `/business-rules/location-services/money-orders`,
        method: "PUT",
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Replaces the data of a Money Order Business Rule based on the internal ODS IDs. Data that is not given in the body will not be updated or removed.
     *
     * @tags business-rules
     * @name UpdateRulesMoneyOrders
     * @summary Replaces the data of a Money Order Business Rule based on the internal ODS IDs. Data that is not given in the body will not be updated or removed.
     * @request PATCH:/business-rules/location-services/money-orders
     */
    updateRulesMoneyOrders: (
      data: BusinessRuleLocationServicesMoneyOrder,
      query?: {
        /**
         * The internal ODS ID of a Business Rule.
         * @format uuid
         */
        businessRuleId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, BrinksOds001 | void | BrinksOds004 | BrinksOds007>({
        path: `/business-rules/location-services/money-orders`,
        method: "PATCH",
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Removes a Money Order Business Rule from the ODS database. The data will not be physically deleted, but a deletion timestamp will be added.
     *
     * @tags business-rules
     * @name DeleteRulesMoneyOrders
     * @summary Removes a Money Order Business Rule from the ODS database. The data will not be physically deleted, but a deletion timestamp will be added.
     * @request DELETE:/business-rules/location-services/money-orders
     */
    deleteRulesMoneyOrders: (
      query?: {
        /**
         * The internal ODS ID of a Business Rule.
         * @format uuid
         */
        businessRuleId?: string;
        /**
         * Store location id
         * @format uuid
         */
        locationId?: string;
        /**
         * External (Service) id of the resource
         * @maxLength 36
         */
        serviceId?: string;
        /**
         * Select the Valid From Data and every record after this date
         * @format date
         */
        validFromDateAndLater?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, BrinksOds001 | void | BrinksOds004 | BrinksOds007>({
        path: `/business-rules/location-services/money-orders`,
        method: "DELETE",
        query: query,
        ...params,
      }),

    /**
     * @description Retrieves and stores business rules regarding the Transport Order Location Service.
     *
     * @tags business-rules
     * @name GetRulesTransportOrders
     * @summary Retrieves and stores business rules regarding the Transport Order Location Service.
     * @request GET:/business-rules/location-services/transport-orders
     */
    getRulesTransportOrders: (
      query?: {
        /**
         * Select the Valid From Data and every record after this date
         * @format date
         */
        validFromDateAndLater?: string;
        /**
         * Store location id
         * @format uuid
         */
        locationId?: string;
        /**
         * External (Service) id of the resource
         * @maxLength 36
         */
        serviceId?: string;
        /**
         * The internal ODS ID of a Business Rule.
         * @format uuid
         */
        businessRuleId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessRuleLocationServicesTransportOrdersResponse, any>({
        path: `/business-rules/location-services/transport-orders`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Stores one or mulitple business rules regarding the Transport Order Location Service.
     *
     * @tags business-rules
     * @name SaveRulesTransportOrders
     * @summary Stores one or mulitple business rules regarding the Transport Order Location Service.
     * @request POST:/business-rules/location-services/transport-orders
     */
    saveRulesTransportOrders: (data: BusinessRuleLocationServicesTransportOrder, params: RequestParams = {}) =>
      this.request<void, BrinksOds001 | void | BrinksOds007>({
        path: `/business-rules/location-services/transport-orders`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Replaces the data of a Transport Order Business Rule based on the internal ODS IDs. Data that is not given in the body will be removed.
     *
     * @tags business-rules
     * @name ReplaceRulesTransportOrders
     * @summary Replaces the data of a Transport Order Business Rule based on the internal ODS IDs. Data that is not given in the body will be removed.
     * @request PUT:/business-rules/location-services/transport-orders
     */
    replaceRulesTransportOrders: (
      data: BusinessRuleLocationServicesTransportOrder,
      query?: {
        /**
         * The internal ODS ID of a Business Rule.
         * @format uuid
         */
        businessRuleId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, BrinksOds001 | void | BrinksOds004 | BrinksOds007>({
        path: `/business-rules/location-services/transport-orders`,
        method: "PUT",
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Replaces the data of a Transport Order Business Rule based on the internal ODS IDs. Data that is not given in the body will not be updated or removed.
     *
     * @tags business-rules
     * @name UpdateRulesTransportOrders
     * @summary Replaces the data of a Transport Order Business Rule based on the internal ODS IDs. Data that is not given in the body will not be updated or removed.
     * @request PATCH:/business-rules/location-services/transport-orders
     */
    updateRulesTransportOrders: (
      data: BusinessRuleLocationServicesTransportOrder,
      query?: {
        /**
         * The internal ODS ID of a Business Rule.
         * @format uuid
         */
        businessRuleId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, BrinksOds001 | void | BrinksOds004 | BrinksOds007>({
        path: `/business-rules/location-services/transport-orders`,
        method: "PATCH",
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Removes a Transport Order Business Rule from the ODS database. The data will not be physically deleted, but a deletion timestamp will be added.
     *
     * @tags business-rules
     * @name DeleteRulesTransportOrders
     * @summary Removes a Transport Order Business Rule from the ODS database. The data will not be physically deleted, but a deletion timestamp will be added.
     * @request DELETE:/business-rules/location-services/transport-orders
     */
    deleteRulesTransportOrders: (
      query?: {
        /**
         * The internal ODS ID of a Business Rule.
         * @format uuid
         */
        businessRuleId?: string;
        /**
         * Store location id
         * @format uuid
         */
        locationId?: string;
        /**
         * External (Service) id of the resource
         * @maxLength 36
         */
        serviceId?: string;
        /**
         * Select the Valid From Data and every record after this date
         * @format date
         */
        validFromDateAndLater?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, BrinksOds001 | void | BrinksOds004 | BrinksOds007>({
        path: `/business-rules/location-services/transport-orders`,
        method: "DELETE",
        query: query,
        ...params,
      }),

    /**
     * @description Retrieves and stores business rules regarding the Money Order Stock Keeping Unit Location Service.
     *
     * @tags business-rules
     * @name GetRulesMoneyOrderSkus
     * @summary Retrieves and stores business rules regarding the Money Order Stock Keeping Unit Location Service.
     * @request GET:/business-rules/location-services/money-orders/skus
     */
    getRulesMoneyOrderSkus: (
      query?: {
        /**
         * The internal ODS ID of a Business Rule.
         * @format uuid
         */
        businessRuleId?: string;
        /**
         * UUID of the SKU
         * @format uuid
         */
        skuId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessRuleLocationServicesMoneyOrderSkusResponse, any>({
        path: `/business-rules/location-services/money-orders/skus`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Stores one or mulitple business rules regarding the Money Stock Keeping Unit Order Location Service.
     *
     * @tags business-rules
     * @name SaveRulesMoneyOrderSkus
     * @summary Stores one or mulitple business rules regarding the Money Order Stock Keeping Unit Location Service.
     * @request POST:/business-rules/location-services/money-orders/skus
     */
    saveRulesMoneyOrderSkus: (data: BusinessRuleLocationServicesMoneyOrderSku, params: RequestParams = {}) =>
      this.request<void, BrinksOds001 | void | BrinksOds007>({
        path: `/business-rules/location-services/money-orders/skus`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Replaces the data of a Money Order Stock Keeping Unit Business Rule based on the internal ODS IDs. Data that is not given in the body will be removed.
     *
     * @tags business-rules
     * @name ReplaceRulesMoneyOrderSkus
     * @summary Replaces the data of a Money Order Stock Keeping Unit Business Rule based on the internal ODS IDs. Data that is not given in the body will be removed.
     * @request PUT:/business-rules/location-services/money-orders/skus
     */
    replaceRulesMoneyOrderSkus: (
      data: BusinessRuleLocationServicesMoneyOrderSku,
      query?: {
        /**
         * The internal ODS ID of a Business Rule.
         * @format uuid
         */
        businessRuleId?: string;
        /**
         * UUID of the SKU
         * @format uuid
         */
        skuId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, BrinksOds001 | void | BrinksOds004 | BrinksOds007>({
        path: `/business-rules/location-services/money-orders/skus`,
        method: "PUT",
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Replaces the data of a Money Order Stock Keeping Unit Business Rule based on the internal ODS IDs. Data that is not given in the body will not be updated or removed.
     *
     * @tags business-rules
     * @name UpdateRulesMoneyOrderSkus
     * @summary Replaces the data of a Money Order Stock Keeping Unit Business Rule based on the internal ODS IDs. Data that is not given in the body will not be updated or removed.
     * @request PATCH:/business-rules/location-services/money-orders/skus
     */
    updateRulesMoneyOrderSkus: (
      data: BusinessRuleLocationServicesMoneyOrderSku,
      query?: {
        /**
         * The internal ODS ID of a Business Rule.
         * @format uuid
         */
        businessRuleId?: string;
        /**
         * UUID of the SKU
         * @format uuid
         */
        skuId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, BrinksOds001 | void | BrinksOds004 | BrinksOds007>({
        path: `/business-rules/location-services/money-orders/skus`,
        method: "PATCH",
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Removes a Money Order Stock Keeping Unit Business Rule from the ODS database. The data will not be physically deleted, but a deletion timestamp will be added.
     *
     * @tags business-rules
     * @name DeleteRulesMoneyOrderSkus
     * @summary Removes a Money Order Stock Keeping Unit Business Rule from the ODS database. The data will not be physically deleted, but a deletion timestamp will be added.
     * @request DELETE:/business-rules/location-services/money-orders/skus
     */
    deleteRulesMoneyOrderSkus: (
      query?: {
        /**
         * The internal ODS ID of a Business Rule.
         * @format uuid
         */
        businessRuleId?: string;
        /**
         * UUID of the SKU
         * @format uuid
         */
        skuId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, BrinksOds001 | void | BrinksOds004 | BrinksOds007>({
        path: `/business-rules/location-services/money-orders/skus`,
        method: "DELETE",
        query: query,
        ...params,
      }),
  };
  skus = {
    /**
     * @description GET sku with a specified Id
     *
     * @tags skus
     * @name GetAllSkus
     * @summary Get Skus
     * @request GET:/skus
     * @secure
     */
    getAllSkus: (params: RequestParams = {}) =>
      this.request<GetSkusResponse, void | BrinksOds007>({
        path: `/skus`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  sku = {
    /**
     * @description GET sku with a specified Id
     *
     * @tags skus
     * @name GetSkusById
     * @summary Get Skus
     * @request GET:/sku/{skuid}
     * @secure
     */
    getSkusById: (
      skuid: string,
      query?: {
        /**
         * UUID of the SKU
         * @format uuid
         */
        skuId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetSkusResponse, void | BrinksOds007>({
        path: `/sku/${skuid}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  locationServices = {
    /**
     * @description GET a location services
     *
     * @tags location-services
     * @name GetLocationServices
     * @summary Return a  location services using partner ID
     * @request GET:/location-services
     */
    getLocationServices: (
      query?: {
        /**
         * External (Service) id of the resource
         * @maxLength 36
         */
        serviceId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetLocationServicesResponse, void>({
        path: `/location-services`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
}
